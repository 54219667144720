export enum OVERTIME_TYPE_VALUE {
    ENUM_TYPE_OT_VACATION = '有給休暇',
    ENUM_TYPE_OT_HOLIDAY = "休日出勤",
    ENUM_TYPE_OT_HALFDAY = "半日有給",
    ENUM_TYPE_OT_NIGHTSHIFT = "夜勤",
    ENUM_TYPE_OT_OVERTIME = "残業",
    ENUM_TYPE_OT_DAYTONIGHT = "日勤からの夜勤",
    ENUM_TYPE_OT_NIGHTTODAY = "夜勤からの日勤",
    ENUM_TYPE_OT_SPECIAL_LEAVE = "出張届",
    ENUM_TYPE_OT_BEREAVEMENT = "特別休暇",
    ENUM_TYPE_OT_ABSENSE = "欠勤",
    ENUM_TYPE_OT_TARDINESS = "遅刻",
    ENUM_TYPE_OT_EARLY_LEAVE = "早退"
}