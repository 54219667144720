/* eslint-disable no-console */
import { timesheetApi, workScheduleApi } from "@api";
import { selectAuth, useAppSelector } from "@redux";
import { linkImage } from "@utils";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { WorkTypeResponse } from "../widget/form-sheet-night-shift/site-constructions-form.widget";

export interface TimeSheetFormContextProps {
    children: React.ReactNode;
}

interface ITimeSheetContextValue {
    liveletEmployees: any[];
    employees: any[];
    workType: WorkTypeResponse;
    optionContractors: any[];
    optionSiteConstructions: any[];
    optionVehicles: any[];
    optionWeather: any[];
    loading: boolean;
    alcoholChecker: any[];
    setLoading: (loading: boolean) => void;
}

const TimeSheetContext = createContext<ITimeSheetContextValue | null>(null);

export function useTimeSheetContext() {
    const context = useContext(TimeSheetContext);
    if (!context) {
        throw new Error("Context must be inside provider");
    }
    return context;
}

export function 
TimeSheetFormContextProvider({ children }: TimeSheetFormContextProps) {
    const [employees, setEmployees] = useState<any[]>([]);
    const [optionVehicles, SetOptionVehicles] = useState<any[]>([]);
    const [optionSiteConstructions, setOptionSiteConstructions] = useState<any[]>([]);
    const [optionWeather, setOptionWeather] = useState<any[]>([]);
    const [optionContractors, setOptionContractors] = useState<any[]>([]);
    const [workType, setWorkType] = useState({} as WorkTypeResponse);
    const [liveletEmployees, setLiveletEmployees] = useState<any[]>([]);
    const [alcoholChecker, setAlcoholChecker] = useState<any[]>([]);

    const [loading, setLoading] = useState(false);

    const setLoadingMemo = useCallback(
        (loading: boolean) => {
            setLoading(loading);
        },
        [setLoading]
    );

    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;

    const getListEmployees = (data) => {
        const employees = data.data.data.map((employee) => {
            return {
                label: `${employee.last_name} ${employee.first_name}`,
                value: employee.id,
                avatar: linkImage(employee?.avatar?.path),
            };
        });
        setEmployees(employees);
    };

    const getListAlcoholChecker = (data) => {
        const alcoholChecker = data.data.data.map((alcoholChecker) => {
            return {
                label: `${alcoholChecker.last_name} ${alcoholChecker.first_name}`,
                value: alcoholChecker.id,
                avatar: linkImage(alcoholChecker?.avatar?.path),
            };
        });
        setAlcoholChecker(alcoholChecker);
    };

    const getListVehicles = (data) => {
        const vehicles = data.data.data.map((vehicle) => {
            return {
                label: vehicle.name,
                value: vehicle.id.toString(),
            };
        });
        SetOptionVehicles(vehicles);
        // eslint-disable-next-line no-console
    };

    const getListSiteConstructions = (data) => {
        const siteConstructions = data.data.data.constructions.map((siteConstruction) => {
            return {
                label: siteConstruction.name,
                value: siteConstruction.id,
            };
        });
        setOptionSiteConstructions(siteConstructions);
    };

    const getListContractors = (data) => {
        const contractors = data.data.data.contractors.map((contractor) => ({
            label: contractor.name,
            value: contractor.id,
        }));

        setOptionContractors(contractors);
    };

    const getListWeather = (data) => {
        const weathers = data.data.data.map((weather) => ({
            label: weather.name,
            value: weather.id,
        }));
        setOptionWeather(weathers);
    };

    const getListWorkType = (data) => {
        setWorkType(data.data.data);
    };
    const getListLiveletEmployee = (data) => {
        const listLiveletEmployees = data.data.map((employee) => {
            return {
                label: `${employee.name}`,
                value: employee.id === 0 ? employee.name : employee.id,
            };
        });
        setLiveletEmployees(listLiveletEmployees);
    };

    useEffect(() => {
        if (!userInfo || !userInfo.id) return;
        (async () => {
            setLoading(true);
            try {
                const [
                    employees,
                    optionVehicles,
                    optionSiteConstructions,
                    optionContractors,
                    optionWeatcher,
                    workType,
                    liveletEmployees,
                    alcoholChecker,
                ] = await Promise.all([
                    timesheetApi.listEmployees(),
                    timesheetApi.listVehicles(),
                    timesheetApi.getListConstructions(),
                    timesheetApi.getListContractors(),
                    timesheetApi.getListWeathers(),
                    workScheduleApi.getWorkTypeByType(),
                    timesheetApi.getListLiveletEmployee(),
                    timesheetApi.listAlcoholChecker(),
                ]);

                getListEmployees(employees);
                getListVehicles(optionVehicles);
                getListSiteConstructions(optionSiteConstructions);
                getListContractors(optionContractors);
                getListWeather(optionWeatcher);
                getListWorkType(workType);
                getListLiveletEmployee(liveletEmployees);
                getListAlcoholChecker(alcoholChecker);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [userInfo]);

    const value = {
        liveletEmployees,
        employees,
        workType,
        optionContractors,
        optionSiteConstructions,
        optionVehicles,
        optionWeather,
        loading,
        alcoholChecker,
        setLoading: setLoadingMemo,
    };

    return <TimeSheetContext.Provider value={value}>{children}</TimeSheetContext.Provider>;
}
