import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Avatar, Divider, Popover } from "@mui/material";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { linkImage, useWindowDimensions } from "@utils";
import { WIDTH_DRAWER_MOBILE, WIDTH_DRAWER_PC } from "@configs";
import { StyledTimeSheetContentModal, StyledPopoverList } from ".";
import { AppButton, CircularLoader } from "..";
import arrow_toggle from "@assets/Icons/arrow/arrow_toggle.svg";
import { appRouteEnums } from "@enums";
import { useAppSelector, selectAuth } from "@redux";
import { timesheetApi } from "@api";
import { OT_STATUS_OK, OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import checked_status_icon from "@assets/Icons/OT/checked_status_icon.svg";
import unchecked_status_icon from "@assets/Icons/OT/unchecked_status_icon.svg";
import { TimeSheetData } from "@interfaces";
// import { filter } from "lodash";

type Anchor = "top" | "left" | "bottom" | "right";

interface IProps {
    openToogle?: boolean;
    anchor?: any;
    handleCloseToggle?: () => void;
    timeSheetDateData: any;
    date?: any;
    id: any;
    selectedGroup?: any;
}
interface IUserItem {
    timesheet?: TimeSheetData;
}
const UserItem = (props: IUserItem) => {
    const { timesheet } = props;
    // redux store
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    const anchorRefElement = React.useRef<HTMLDivElement>(null);
    const history = useHistory();

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <div className="item" ref={anchorRefElement} onClick={onOpenPopover}> */}
            <div className="item" ref={anchorRefElement}>
                <Avatar src={linkImage(timesheet?.users?.avatar?.path)} />
                <span className="item__title">
                    {timesheet?.users?.last_name}
                    {timesheet?.users?.first_name}
                </span>
            </div>
            <StyledPopoverList>
                {/* {timesheet?.users?.map((item, index) => ( */}
                <div
                    className="wrapItem"
                    onClick={() =>
                        timesheet?.users?.id === userInfo?.id
                            ? history.push(
                                  `${appRouteEnums.PATH_TIMESHEET}/edit/${timesheet?.type}/${timesheet?.id}`
                              )
                            : history.push(
                                  `${appRouteEnums.PATH_TIMESHEET}/detail/${timesheet?.type}/${timesheet?.id}`
                              )
                    }
                >
                    <div className="item">{timesheet?.title}</div>
                    <div className="item__status">
                        {timesheet?.status === OT_STATUS_OK && (
                            <div className="item__status-checked">
                                <span className="text">OK</span>
                                <img className="icon" src={checked_status_icon} alt="icon" />
                            </div>
                        )}
                        {timesheet?.status === OT_STATUS_REJECT && (
                            <div className="item__status-unChecked">
                                <span className="text">NG</span>
                                <img className="icon" src={unchecked_status_icon} alt="icon" />
                            </div>
                        )}
                        {timesheet?.status === OT_STATUS_PENDING && (
                            <div className="item__status-pending">
                                <span className="text">未決</span>
                            </div>
                        )}
                    </div>
                </div>
                {/* ))} */}
            </StyledPopoverList>
        </div>
    );
};
export const TimeSheetToggleModal = (props: IProps) => {
    const { openToogle, anchor, handleCloseToggle, timeSheetDateData, id, selectedGroup } = props;
    //  HOOKS
    const width = useWindowDimensions().width;
    const history = useHistory();
    // events
    const handleOpenNewApplication = () => {
        history.push(
            `${appRouteEnums.PATH_TIMESHEET_NEW}?${moment(timeSheetDateData?.date).format(
                "YYYY-MM-DD"
            )}`
        );
    };
    const List = (anchor: Anchor) => {
        // redux states
        const auth = useAppSelector(selectAuth);
        const { userInfo } = auth;
        const [showListTimeSheet, setShowListTimeSheet] = React.useState(false);
        const anchorRefElement = React.useRef<HTMLDivElement>(null);
        const [loading, setLoading] = React.useState<boolean>(false);
        const [data, setData] = React.useState<any>();
        const onClosePopover = React.useCallback(() => {
            setShowListTimeSheet(false);
        }, []);
        const onOpenPopover = React.useCallback(() => {
            setShowListTimeSheet(true);
        }, []);
        React.useEffect(() => {
            const fetchApiViewTimesheetByDay = async () => {
                const date = timeSheetDateData.date
                    ? moment(timeSheetDateData?.date).format("YYYY-MM-DD")
                    : [];
                // const pickUserId = id?.id;
                setLoading(true);
                try {
                    const response = await timesheetApi.viewTimesheetByDay({
                        date: date,
                        // userId: pickUserId,
                        arrayGroupId: selectedGroup,
                    });
                    setData(response.data.data);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                }
            };
            fetchApiViewTimesheetByDay();
        }, [timeSheetDateData?.date, id?.id]);
        // fetch api view timesheet by date

        return (
            <Box
                sx={{
                    width:
                        anchor === "top" || anchor === "bottom"
                            ? "auto"
                            : width > 768
                            ? WIDTH_DRAWER_PC
                            : WIDTH_DRAWER_MOBILE,
                }}
                role="presentation"
            >
                <StyledTimeSheetContentModal>
                    <div className="toggle__top">
                        <p className="toggle__top-collapse" onClick={handleCloseToggle}>
                            <img className="toggle__top-icon" src={arrow_toggle} />
                        </p>
                        <p className="toggle__top-date">
                            {timeSheetDateData?.date &&
                                moment(timeSheetDateData?.date).format("YYYY-MM-DD")}
                        </p>
                    </div>
                    <Divider />
                    <div className="toggle__user">
                        <div
                            className="toggle__user-item"
                            onClick={onOpenPopover}
                            ref={anchorRefElement}
                        >
                            <Avatar src={linkImage(userInfo?.avatar?.path)} />
                            <span className="toggle__user-name">
                                {userInfo?.last_name}
                                {userInfo?.first_name}
                            </span>
                        </div>
                        <div className="toggle__user-btn">
                            <AppButton onClick={handleOpenNewApplication} text="新規作成" />
                        </div>
                        <Popover
                            open={showListTimeSheet}
                            anchorEl={anchorRefElement.current}
                            onClose={onClosePopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            {/* <StyledPopoverList>
                                {data?.length
                                    ? filterUserTimesheets(data, userInfo?.id).map((item: any) => {
                                          return (
                                              <div className="wrapItem">
                                                  <span
                                                      key={item.id}
                                                      className="item"
                                                      onClick={() =>
                                                          history.push(
                                                              `${appRouteEnums.PATH_TIMESHEET}/edit/${item.type}/${item.id}`
                                                          )
                                                      }
                                                  >
                                                      {item.title}
                                                  </span>
                                                  <div className="item__status">
                                                      {item?.status === OT_STATUS_OK && (
                                                          <div className="item__status-checked">
                                                              <span className="text">OK</span>
                                                              <img
                                                                  className="icon"
                                                                  src={checked_status_icon}
                                                                  alt="icon"
                                                              />
                                                          </div>
                                                      )}
                                                      {item?.status === OT_STATUS_REJECT && (
                                                          <div className="item__status-unChecked">
                                                              <span className="text">NG</span>
                                                              <img
                                                                  className="icon"
                                                                  src={unchecked_status_icon}
                                                                  alt="icon"
                                                              />
                                                          </div>
                                                      )}
                                                      {item?.status === OT_STATUS_PENDING && (
                                                          <div className="item__status-pending">
                                                              <span className="text">未決</span>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          );
                                      })
                                    : ""}
                            </StyledPopoverList> */}
                        </Popover>
                    </div>
                    <Divider />
                    {loading ? (
                        <CircularLoader loading={loading} type="fullContent" />
                    ) : (
                        <div className="wrapperList">
                            {data?.length
                                ? data?.map((item, index) => {
                                      return (
                                          <div className="toggle__groupUser" key={index}>
                                              <div className="toggle__groupUser-title">
                                                  {item?.name}
                                              </div>
                                              <div className="toggle__groupUser-list">
                                                  {item?.timesheets?.map((timesheet, i) =>
                                                      item?.timesheets.length ? (
                                                          <div key={i}>
                                                              <UserItem timesheet={timesheet} />
                                                              <Divider />
                                                          </div>
                                                      ) : (
                                                          ""
                                                      )
                                                  )}
                                              </div>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    )}
                </StyledTimeSheetContentModal>
            </Box>
        );
    };

    return (
        <div>
            <React.Fragment key={anchor}>
                <Drawer
                    anchor={anchor}
                    open={openToogle}
                    onClose={width > 768 ? handleCloseToggle : () => ""}
                >
                    {List(anchor)}
                </Drawer>
            </React.Fragment>
        </div>
    );
};
