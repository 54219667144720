import React from "react";
import { StyledTableWrapper } from "./styles";
import { Box, Button, Divider } from "@mui/material";
import { editWorkScheduleForm, getDaysWeeksArrayMoment } from "@utils";
import clsx from "clsx";
import moment from "moment";
import { AppButton, AppInput, AppModal, CircularLoader } from "../Common";
import { Formik } from "formik";
import edit_icon from "@assets/Icons/WorkSchedule/edit_icon.svg";
import red_delete_icon from "@assets/Icons/WorkSchedule/red_delete_icon.svg";
import { workScheduleApi } from "@api";
import { useDispatch } from "react-redux";
import { updateSnackBar } from "@redux";

interface IProps {
    handleItemOnClick?: (values: any) => void;
    handleOpenViewDetail?: (id: any) => void;
    fetchDetailApi?: () => void;
    isViewDetail?: boolean;
    data?: any;
    date?: any;
    selectSubContractor?: any;
}
export const ViewGraphCalendar = (props: IProps) => {
    const { isViewDetail, date, fetchDetailApi, data, selectSubContractor } = props;
    const dispatch = useDispatch();

    // state
    // const [openEditModal, setOpenEditModal] = React.useState<boolean>(false);
    const [currentEditedSchedule, setCurrentEditedSchedule] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [detailData, setDetailData] = React.useState<any>(null);
    const [currentDay, setCurrentDay] = React.useState<any>(null);
    const [currentWorkScheduleId, setCurrentWorkScheduleId] = React.useState<any>(null);
    const [currentWorkTypeWorkScheduleId, setCurrentWorkTypeWorkScheduleId] = React.useState<any>(null);
    const [reloadPage, setReloadPage] = React.useState<boolean>(false);

    const [workTypeId, setWorkTypeId] = React.useState<any>();

    React.useEffect(() => {
        if (fetchDetailApi) {
            fetchDetailApi();
        }
    }, [reloadPage]);

    const formatDay = (day: number) => {
        return day < 10 ? `0${day}` : `${day}`;
    };

    const combineDate = (currentDay: number, date: string) => {
        const formattedDay = formatDay(currentDay);
        return `${date}-${formattedDay}`;
    };

    const fetchGetWorkDetailApi = async (workScheduleId, scheduleId) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.getWorkDetail({
                workScheduleId,
            });
            setDetailData(response?.data?.data);
            setWorkTypeId(response?.data?.data?.work_schedules?.find((schedule) => schedule?.id === workScheduleId)?.work_schedule_work_type?.find((workType) => workType?.id === scheduleId)?.work_type?.type);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response?.data?.message,
                })
            );
        }
    };
    const fetchUpdateWorkScheduleApi = async (values: any, resetForm: any) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.updateWorkSchedule({
                workScheduleWorkTypeId: values.workScheduleWorkTypeId,
                date: values.date,
                numberOfPeople: values.numberOfPeople,
            });
            setLoading(false);
            // handleCloseUpdate();
            setCurrentEditedSchedule(null);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: response.data.data,
                })
            );
            // window.location.reload();
            setReloadPage(!reloadPage);
            setCurrentEditedSchedule(null);
            resetForm();
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };

    const initialValuesPackage = {
        workScheduleId: "",
        workTypeId: "",
        date: "",
        numberOfPeople: "",
    };

    const handleOpenEditModal = (workScheduleId, schedule, day) => {
        fetchGetWorkDetailApi(workScheduleId, schedule?.id);
        setCurrentWorkScheduleId(workScheduleId);
        setCurrentEditedSchedule(schedule);
        setCurrentWorkTypeWorkScheduleId(schedule?.id);
        setCurrentDay(day);
    };

    const organizeWorkTypes = (workTypes) => {
        const type1 = workTypes.filter((wt) => wt.work_type.type === 1);
        const type2 = workTypes.filter((wt) => wt.work_type.type === 2);
        const type3 = workTypes.filter((wt) => wt.work_type.type === 3);

        const organized = type1.map((t1) => {
            const childrenType2 = type2
                .filter((t2) => t2.parent_id === t1.id)
                .map((t2) => {
                    const childrenType3 = type3.filter((t3) => t3.parent_id === t2.id);
                    return { ...t2, children: childrenType3 };
                });
            return { ...t1, children: childrenType2 };
        });

        return organized;
    };

    const handleOnDeleteWorkSchedule = async (values: any) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.deleteDaySchedule({
                workScheduleWorkTypeId: values.workScheduleWorkTypeId,
                date: values.date,
                workType: values.currentWorkTypeWorkScheduleId,
            });
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: response.data.data,
                })
            );
            // window.location.reload();
            setCurrentEditedSchedule(null);
            setReloadPage(!reloadPage);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };

    return (
        <StyledTableWrapper>
            <table className="table">
                <tr className="table__header">
                    {getDaysWeeksArrayMoment(date).map((day, index) => (
                        <td key={index} className={clsx(day.dayInWeek === "sun" || day.dayInWeek === "sat" ? "dayOff" : "")}>
                            {day.dayNumber}
                        </td>
                    ))}
                </tr>

                {!isViewDetail &&
                    !!data &&
                    data?.work_schedules?.map((schedule) => {
                        return organizeWorkTypes(schedule?.work_schedule_work_type).map((type1, type1Index) => {
                            // const color = type1?.work_type?.color;
                            return (
                                <>
                                    <tr className="table__body" key={`Type${type1Index}`}>
                                        {(() => {
                                            const cols = [] as any;
                                            for (let i = 1; i <= getDaysWeeksArrayMoment(date).length; i++) {
                                                let isOpenForm = true;

                                                // const currentDate = i < 10 ? "0" + i : "" + i;
                                                const currentFullDate = moment(date).date(i).format("YYYY-MM-DD");
                                                const workType1Date = type1?.work_type_date;

                                                let totalNumberOfPeopleType1 = 0;
                                                type1.children.forEach((type2) => {
                                                    const workType2Date = type2?.work_type_date;
                                                    const listEvent2 = workType2Date.filter((event) => {
                                                        return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                    });

                                                    let newestEntry2;
                                                    newestEntry2 = newestEntry2 || {};
                                                    if (listEvent2?.length > 0) {
                                                        newestEntry2 = listEvent2.reduce((prev, current) => {
                                                            return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                        });
                                                    }

                                                    if (newestEntry2?.number_of_people > 0) {
                                                        isOpenForm = false;
                                                    }

                                                    if (type2.children?.length === 0) {
                                                        totalNumberOfPeopleType1 += newestEntry2?.number_of_people || 0;
                                                    } else {
                                                        let numType2 = 0;
                                                        type2.children?.forEach((type3) => {
                                                            const workType3Date = type3?.work_type_date;
                                                            const listEvent3 = workType3Date.filter((event) => {
                                                                return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                            });

                                                            let newestEntry3;
                                                            if (listEvent3?.length > 0) {
                                                                newestEntry3 = listEvent3.reduce((prev, current) => {
                                                                    return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                                });
                                                            }
                                                            if (newestEntry3?.number_of_people !== 0) {
                                                                numType2 += newestEntry3?.number_of_people || 0;
                                                            }
                                                        });
                                                        totalNumberOfPeopleType1 += numType2 || 0;
                                                    }
                                                });

                                                const listEvent = workType1Date?.filter((event) => {
                                                    return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                });
                                                let newestEntry;
                                                if (listEvent.length > 0) {
                                                    newestEntry = listEvent.reduce((prev, current) => {
                                                        return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                    });
                                                }

                                                if (totalNumberOfPeopleType1 > 0 || (listEvent.length > 0 && newestEntry && newestEntry?.number_of_people > 0)) {
                                                    const displayNumberOfPeople = totalNumberOfPeopleType1 > 0 ? totalNumberOfPeopleType1 : newestEntry?.number_of_people;
                                                    const formattedDisplayNumberOfPeople =
                                                        Number(displayNumberOfPeople) === displayNumberOfPeople && displayNumberOfPeople.toString().includes(".") && displayNumberOfPeople.toString().split(".")[1].length > 2
                                                            ? displayNumberOfPeople.toFixed(2)
                                                            : displayNumberOfPeople;
                                                    cols.push(
                                                        <td
                                                            key={i}
                                                            className="table__event"
                                                            onClick={() => {
                                                                if (isOpenForm) {
                                                                    handleOpenEditModal(schedule?.id, type1, i);
                                                                }
                                                            }}
                                                        >
                                                            <div
                                                                className={`${!isOpenForm ? "event" : "event event_pointer"}`}
                                                                style={{
                                                                    backgroundColor: selectSubContractor !== null ? "#008bd2" : "#000",
                                                                    color: "#fff",
                                                                    height: "30px",
                                                                }}
                                                            >
                                                                <p className="event__name" style={{ textAlign: "center" }}>
                                                                    {formattedDisplayNumberOfPeople}
                                                                </p>
                                                            </div>
                                                        </td>
                                                    );
                                                } else {
                                                    cols.push(<td style={{ height: "30px" }}></td>);
                                                }
                                            }
                                            return cols;
                                        })()}
                                    </tr>

                                    {(() => {
                                        const trs: any = [];
                                        type1.children.forEach((type2) => {
                                            const newTr = (
                                                <tr className="table__body" key={`Classification${type1Index}`}>
                                                    {(() => {
                                                        const cols = [] as any;
                                                        for (let i = 1; i <= getDaysWeeksArrayMoment(date).length; i++) {
                                                            {
                                                                let isOpenType2Form = true;
                                                                // const currentDate = i < 10 ? "0" + i : "" + i;
                                                                const currentFullDate = moment(date).date(i).format("YYYY-MM-DD");
                                                                const workType2Date = type2?.work_type_date;
                                                                const listEvent = workType2Date.filter((event) => {
                                                                    return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                                });
                                                                let totalNumberOfPeopleType2 = 0;

                                                                let newestEntry2;
                                                                if (listEvent.length > 0) {
                                                                    newestEntry2 = listEvent.reduce((prev, current) => {
                                                                        return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                                    });
                                                                }

                                                                if (type2.children?.length === 0) {
                                                                    totalNumberOfPeopleType2 += newestEntry2?.number_of_people || 0;
                                                                } else {
                                                                    type2.children?.forEach((type3) => {
                                                                        const workType3Date = type3?.work_type_date;
                                                                        const listEvent3 = workType3Date.filter((event) => {
                                                                            return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                                        });

                                                                        let newestEntry3;
                                                                        if (listEvent3?.length > 0) {
                                                                            newestEntry3 = listEvent3.reduce((prev, current) => {
                                                                                return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                                            });
                                                                        }

                                                                        if (newestEntry3?.number_of_people > 0) {
                                                                            isOpenType2Form = false;
                                                                        }
                                                                        totalNumberOfPeopleType2 = totalNumberOfPeopleType2 + (newestEntry3?.number_of_people || 0);
                                                                    });
                                                                }

                                                                if (totalNumberOfPeopleType2 > 0 || (listEvent.length > 0 && newestEntry2 && newestEntry2?.number_of_people > 0)) {
                                                                    const displayNumberOfPeople = totalNumberOfPeopleType2 > 0 ? totalNumberOfPeopleType2 : newestEntry2?.number_of_people;
                                                                    const formattedDisplayNumberOfPeople =
                                                                        Number(displayNumberOfPeople) === displayNumberOfPeople && displayNumberOfPeople.toString().includes(".") && displayNumberOfPeople.toString().split(".")[1].length > 2
                                                                            ? displayNumberOfPeople.toFixed(2)
                                                                            : displayNumberOfPeople;
                                                                    cols.push(
                                                                        <td
                                                                            className="table__event"
                                                                            onClick={() => {
                                                                                if (isOpenType2Form) {
                                                                                    handleOpenEditModal(schedule?.id, type2, i);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div
                                                                                className={`${!isOpenType2Form ? "event" : "event event_pointer"}`}
                                                                                style={{
                                                                                    backgroundColor: selectSubContractor !== null ? "#008bd2" : "#000000",
                                                                                    height: "30px",
                                                                                    color: "#ffffff",
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    className="event__name"
                                                                                    style={{
                                                                                        textAlign: "center",
                                                                                    }}
                                                                                >
                                                                                    {formattedDisplayNumberOfPeople}
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                    );
                                                                } else {
                                                                    cols.push(<td style={{ height: "30px" }}></td>);
                                                                }
                                                            }
                                                        }
                                                        return cols;
                                                    })()}
                                                </tr>
                                            );
                                            trs.push(newTr);

                                            {
                                                type2?.children?.forEach((type3) => {
                                                    const newTr = (
                                                        <tr className="table__body" key={`Details${type1Index}`}>
                                                            {(() => {
                                                                const cols = [] as any;
                                                                for (let i = 1; i <= getDaysWeeksArrayMoment(date).length; i++) {
                                                                    {
                                                                        // const currentDate = i < 10 ? "0" + i : "" + i;
                                                                        const currentFullDate = moment(date).date(i).format("YYYY-MM-DD");

                                                                        const workType3Date = type3?.work_type_date;
                                                                        const listEvent = workType3Date.filter((event) => {
                                                                            return moment(currentFullDate).isBetween(event.start_date, event.end_date, null, "[]");
                                                                        });
                                                                        let newestEntry;
                                                                        if (listEvent.length > 0) {
                                                                            newestEntry = listEvent.reduce((prev, current) => {
                                                                                return new Date(current.created_at) > new Date(prev.created_at) ? current : prev;
                                                                            });
                                                                        }
                                                                        if (listEvent.length > 0 && newestEntry && newestEntry?.number_of_people > 0) {
                                                                            cols.push(
                                                                                <td
                                                                                    className="table__event"
                                                                                    onClick={() => {
                                                                                        handleOpenEditModal(schedule?.id, type3, i);
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="event event_pointer"
                                                                                        style={{
                                                                                            backgroundColor: selectSubContractor !== null ? "#008bd2" : "#000000",
                                                                                            color: "#ffffff",
                                                                                            height: "30px",
                                                                                        }}
                                                                                    >
                                                                                        <p
                                                                                            className="event__name"
                                                                                            style={{
                                                                                                textAlign: "center",
                                                                                            }}
                                                                                        >
                                                                                            {newestEntry?.number_of_people}
                                                                                        </p>
                                                                                    </div>
                                                                                </td>
                                                                            );
                                                                        } else {
                                                                            cols.push(<td style={{ height: "30px" }}></td>);
                                                                        }
                                                                    }
                                                                }
                                                                return cols;
                                                            })()}
                                                        </tr>
                                                    );
                                                    trs.push(newTr);
                                                });
                                            }
                                        });
                                        return trs.map((trItem) => trItem);
                                    })()}
                                </>
                            );
                        });
                    })}
            </table>
            <AppModal open={!!currentEditedSchedule} handleClose={() => setCurrentEditedSchedule(null)} className="edit-modal">
                <div className="edit-modal__container">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Formik
                            initialValues={initialValuesPackage}
                            onSubmit={(values, { resetForm }) => {
                                fetchUpdateWorkScheduleApi(
                                    {
                                        workScheduleWorkTypeId: currentWorkTypeWorkScheduleId,
                                        date: combineDate(currentDay, moment(date).format("YYYY-MM")),
                                        numberOfPeople: values.numberOfPeople,
                                    },
                                    resetForm
                                );
                            }}
                            validationSchema={editWorkScheduleForm}
                        >
                            {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => {
                                return (
                                    <div className="form__container" style={{ textAlign: "left", width: "100%" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p style={{ fontSize: "1.5rem" }}>工事名(略名)</p>
                                            <p style={{ fontSize: "1.5rem" }}>{detailData?.name}</p>
                                        </div>
                                        <Divider style={{ margin: "2rem 0" }} />
                                        <p style={{ marginBottom: "8px", fontSize: "1.5rem" }}>作業会社 </p>
                                        <div style={{ display: "flex" }}>
                                            {detailData?.work_schedules
                                                ?.find((schedule) => schedule?.id === currentWorkScheduleId)
                                                ?.work_schedule_work_type?.find((workType) => workType?.id === currentWorkTypeWorkScheduleId)
                                                ?.contractors?.map((contractor) => (
                                                    <div style={{ backgroundColor: "#e6e6e6", borderRadius: "2px", margin: "2px", padding: "6px", fontSize: "14px" }}>{contractor?.name}</div>
                                                ))}
                                        </div>
                                        <Divider style={{ margin: "2rem 0" }} />

                                        <p style={{ marginBottom: "8px", fontSize: "1.5rem" }}>人数</p>
                                        <AppInput
                                            value={values.numberOfPeople}
                                            name="numberOfPeople"
                                            handleChange={handleChange("numberOfPeople")}
                                            placeholder="人数"
                                            error={errors.numberOfPeople}
                                            touched={touched.numberOfPeople}
                                            handleBlur={handleBlur("numberOfPeople")}
                                        />
                                        <Divider style={{ margin: "2rem 0" }} />

                                        <div
                                            className="workScheduleNewForm__btn update"
                                            style={{
                                                marginTop: "3rem",
                                            }}
                                        >
                                            <AppButton onClick={handleSubmit} text="アップデート" startIcon={<img src={edit_icon} />} />
                                        </div>

                                        <div
                                            className="workScheduleNewForm__btn delete"
                                            style={{
                                                marginTop: "3rem",
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleOnDeleteWorkSchedule({
                                                        workScheduleWorkTypeId: currentWorkTypeWorkScheduleId,
                                                        date: combineDate(currentDay, moment(date).format("YYYY-MM")),
                                                        workType: workTypeId,
                                                    })
                                                }
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid rgb(250, 92, 92",
                                                    padding: "10px",
                                                    borderRadius: "8px",
                                                    fontSize: "16px",
                                                }}
                                            >
                                                <span className="icon">
                                                    <img src={red_delete_icon} />
                                                </span>
                                                <span className="text">消去</span>
                                            </Button>
                                        </div>

                                        {/* <div
                                            className="workScheduleNewForm__btn delete"
                                            style={{
                                                marginTop: "3rem",
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    handleOnDeleteWorkSchedule({
                                                        id: currentWorkScheduleId,
                                                    })
                                                }
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid rgb(250, 92, 92",
                                                    padding: "10px",
                                                    borderRadius: "8px",
                                                }}
                                            >
                                                <span className="icon">
                                                    <img src={red_delete_icon} />
                                                </span>
                                                <span className="text">消去</span>
                                            </Button>
                                        </div> */}
                                    </div>
                                );
                            }}
                        </Formik>
                    </Box>
                </div>
            </AppModal>
            <CircularLoader loading={loading} />
        </StyledTableWrapper>
    );
};
