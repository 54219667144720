import styled from "styled-components";

interface IStyledModalMenu {
    screen?: string;
}
export const StyledModalMenu = styled.div<IStyledModalMenu>`
    position: relative;
    padding: 4.3rem 3.2rem;
    background-color: white;
    border-radius: 2.4rem;
    margin: 0 2rem;
    z-index: 1;
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        margin: 0 4rem;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        padding: 5rem 8rem;
    }
    .menu {
        &__bg {
            position: absolute;
            top: 4.3rem;
            left: 32%;
            z-index: -1;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display: none;
            }
        }
        &__wrapperImage {
            height: 7rem;
            width: 7rem;
            width: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                height: 14rem;
                width: 14rem
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                height: 14rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakSuperBig}) {
                height: 20rem;
                width: 20rem;
            }
        }
        &__item {
            cursor: pointer;
        }
        &__image {
            width: 100%;
            height: 100%;
        }
        &__title {
            text-align: center;
            padding-top: 0.8rem;
            font-size: 14px;
            color: #292d32;
            word-break: break-all;
            display: block;
            display: -webkit-box;
            max-width: 5rem;
            height: 40px;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                font-size: 2.4rem;
                height: 70px;
                max-width: 100%;
            }
        }
    }
`;
export const StyledModalMenuComponent = styled.div`
    position: relative;
    z-index: 1;
    .menu {
        &__bg {
            position: absolute;
            left: 19.5rem;
            top: -2rem;
            display: none;
            z-index: -1;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: block;
            }
        }
        &__wrapper {
            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-column-gap: 1rem;
            grid-row-gap: 2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                grid-template-columns: auto auto auto auto auto;
                grid-column-gap: 2rem;
                grid-row-gap: 2rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                grid-column-gap: 4rem;
                grid-row-gap: 2rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakSuperBig}) {
                grid-template-columns: auto auto auto auto auto auto;
            }
        }
        &__wrapperImage {
            height: 5rem;
            width: 5rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                height: 8rem;
                width: 8rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                height: 15rem;
                width: 15rem;
                /* padding: 0 6rem; */
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                height: 15rem;
                width: 15rem;
                padding: 0;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakSuperBig}) {
                width: 15rem;
                height: 15rem;
            }
        }
        &__item {
            width: 100%;
            cursor: pointer;
        }
        &__image {
            width: 100%;
            height: 100%;
        }
        &__title {
            text-align: center;
            padding-top: 0.8rem;
            font-size: 14px;
            color: #292d32;
            display: block;
            display: -webkit-box;
            max-width: 5rem;
            height: 42px;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
              @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                 max-width:100%;
            }
        }
    }
`;
