import { appRouteEnums, menuTitleEnums } from "@enums";
import { Box, Grid } from "@mui/material";
import { selectAuth, selectEmployeesData, selectGroupsData, useAppSelector } from "@redux";
//import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    AppCustomeSelect,
    AppExportCsv,
    AppIconButton,
    AppTopBar,
    CircularLoader,
} from "../Common";
import { TitleMenuComponent } from "../TitleMenuComponent";
import { StyledCalendar } from "./styles";
//import { ViewGraphCalendar } from "./ViewGraphCalendar";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { checkExistUserSelected, linkImage } from "@utils";
import tick_icon from "@assets/Icons/tick_icon.svg";
import clsx from "clsx";
import styled from "styled-components";
import { selectWeeklyScheduleLoading, selectWeeklySchedule } from "src/Redux/Slices/weeklySchedule";
import { WeeklyScheduleCalendar } from "./WeeklyScheduleCalendar";
import moment from "moment";

const defaultGroup = {
    id: 0,
    name: "全て",
};

export const WeeklyScheduleScreen = () => {
    const auth = useAppSelector(selectAuth);
    const id = auth?.userInfo?.id;
    const history = useHistory();

    // Redux selectors for groups, timesheets, and employees
//    const groupsLoading = useAppSelector(selectGroupsLoading);
    const groups = useAppSelector(selectGroupsData);

    const weeklyScheduleLoading = useAppSelector(selectWeeklyScheduleLoading);
    const weeklySchedule = useAppSelector(selectWeeklySchedule);
    const employeesData = useAppSelector(selectEmployeesData);

    // There's a bug where the component doesn't always re-render when the employeesData or weeklySchedule changes, so I need to force a re-render by changing the dataHash
    const [dataHash, setDataHash] = useState<string>("");
    useEffect(() => {
        async function updateHash() {
            const hash = await generateHash(JSON.stringify(employeesData) + JSON.stringify(weeklySchedule));
            setDataHash(hash);
        }
        updateHash();
    }, [employeesData, weeklySchedule]);

    // Store date as a string in "YYYY-MM" format
    const [month, setMonth] = useState<string>(new Date().toISOString().slice(0, 7));

    const [selectedGroup, setSelectedGroup] = useState<any>([]);
    const [inputValue, setInputValue] = useState<any>();

    const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = event.target.value; // "YYYY-MM" format
        setMonth(selectedDate);
    };

    const goToOverTime = () => {
        history.push(`${appRouteEnums.PATH_OVERTIME}/new`);
    };

    const goToTimeSheet = () => {
        history.push(`${appRouteEnums.PATH_TIMESHEET}`);
    };
    
    const handleSelectGroup = (id) => {
        if (id === 0) {
            setSelectedGroup([]);
            return;
        }
        setSelectedGroup(
            checkExistUserSelected(id, selectedGroup)
                ? selectedGroup.filter((e) => e !== id)
                : [...selectedGroup, id]
        );
    };

    const handleOnChange = (value) => {
        setInputValue(value);
        setSelectedGroup([]);
    };

    const filteredUsers = weeklySchedule.users.filter((user) => {
        // If a user is selected, only show that user
        if (inputValue && inputValue.value !== user.id.toString()) { return false; }

        // If no group is selected, show all users
        if (selectedGroup.length === 0) { return true; }

        // If a group is selected, show only users in that group
        const userObject = employeesData.find((employee) => employee.id === user.id);
        if (!userObject) { return false; }

        return userObject.groups.some((group) => selectedGroup.includes(group));
    });

    const dateFilteredUsers = useMemo(() => filteredUsers.map(user => ({
        ...user,
        schedule: user.schedule.filter(event => {
            const eventMonth = moment(event.date).format("YYYY-MM");
            return eventMonth === month;
        })
    })), [filteredUsers, month]);

    return (
        <StyledCalendar style={{ maxHeight: "500px", position: "relative" }}>
            <AppTopBar />
            <CircularLoader loading={weeklyScheduleLoading} />
            <div className="workSchedule">
                <div className="timesheet__title">
                    <TitleMenuComponent text={menuTitleEnums.TITLE_WEEKLY_SCHEDULE} />
                </div>
                <span className="wrapper-nav">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p
                            className="newOT__top-icon"
                            onClick={() => history.push(appRouteEnums.PATH_HOME)}
                        >
                            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                        </p>
                        <div className="newOT__btn" onClick={goToTimeSheet}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                {menuTitleEnums.TITLE_TIMESHEET}
                            </Box>
                        </div>
                        <div className="newOT__btn" onClick={goToOverTime}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                {menuTitleEnums.TITLE_OVERTIME}
                            </Box>
                        </div>
                    </div>
                    <Grid item xs={12} md={6}>
                        <div className="wrapperInput">
                            <div className="datePicker">
                                <div className="label" >日付</div>
                                <div className="wrapperDatePicker">
                                    <input
                                        type="month"
                                        value={month}
                                        onChange={handleMonthChange}
                                        style={{ 
                                            padding: "1rem",
                                            borderRadius: "1rem",
                                            border: "1px solid rgb(136, 136, 136)",
                                            fontSize: "1.5rem",
                                            fontFamily: "NotoSansJP-500",
                                        }}            
                                    />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </span>
                <div className="wrapper__filter">
                    <div className="timesheet__filter">
                        <div
                            onClick={() => handleSelectGroup(defaultGroup.id)}
                            className={clsx(
                                !selectedGroup.length ? "active" : "",
                                "timesheet__filter-item"
                            )}
                        >
                            <span className="title">{defaultGroup.name}</span>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                {!selectedGroup.length && <img src={tick_icon} alt="icon" />}
                            </Box>
                        </div>
                        {groups &&
                            groups.map((item) => (
                                <div
                                    key={item.id}
                                    onClick={() => handleSelectGroup(item.id)}
                                    className={clsx(
                                        checkExistUserSelected(item.id, selectedGroup)
                                            ? "active"
                                            : "",
                                        "timesheet__filter-item"
                                    )}
                                >
                                    <span className="title">{item.name}</span>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {checkExistUserSelected(item.id, selectedGroup) && (
                                            <img src={tick_icon} alt="icon" />
                                        )}
                                    </Box>
                                </div>
                            ))}
                    </div>
                </div>
                <StyledWrapSearch className="wrapper__search">
                    <div className="wrapperSelect">
                        <AppCustomeSelect
                            placeholder="人を選択する"
                            onChange={handleOnChange}
                            groupUserOptions={
                                employeesData?.length &&
                                [...employeesData].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                    return {
                                        label: `${user.last_name}${user.first_name}`,
                                        value: user?.id?.toString(),
                                        avatar: linkImage(user?.avatar?.path),
                                    };
                                })
                            }
                            options={
                                employeesData?.length &&
                                [...employeesData].sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                    return {
                                        label: `${user.last_name}${user.first_name}`,
                                        value: user?.id?.toString(),
                                        avatar: linkImage(user?.avatar?.path),
                                    };
                                })
                            }
                            value={inputValue}
                            isShowAvatar
                            isClearable
                        />
                    </div>
                </StyledWrapSearch>
                <div style={{ marginBottom: "20px" }}>
                    <AppExportCsv type={"weekly-schedule"} />
                </div>
                <WeeklyScheduleCalendar
                    key={dataHash}
                    month={month}
                    data={dateFilteredUsers}
                />
{
//                <div className="desktop">
//                    <ViewGraphCalendar
//                        data={dateFilteredUsers}
//                        date={date}
//                        listHolidayDate={weeklySchedule.date_holiday}
//                        worksInConstructionParams={worksInConstructionParams}
//                    />
//                </div>
}
{
//                <Button
//                    className={`view-more-btn ${disabledViewMore && "disabled"}`}
//                    variant="outlined"
//                    disabled={disabledViewMore}
//                    onClick={handleViewMore}
//                    style={{display: "none"}}
//                >
//                    もっと見る
//                </Button>
}
            </div>
        </StyledCalendar>
    );
};

export default WeeklyScheduleScreen;

const StyledWrapSearch = styled.div`
    max-width: 300px;
    margin-bottom: 20px;
`;

async function generateHash(value: string): Promise<string> {
    const msgBuffer = new TextEncoder().encode(value);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}
