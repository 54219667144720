import React from "react";

interface IProps {
    data?: any;
}
const OTDetailHoliday = (props: IProps) => {
    const { data } = props;
    return (
        <div>
            {data?.overtimeData?.constructionId?.name && (
                <div className="des">
                    <p>工事名</p>
                    <p className="des__content">{data?.overtimeData?.constructionId?.name}</p>
                </div>
            )}
            {data?.overtimeData?.contractor.length > 0 && (
                <div className="des">
                    <p className="">業者名</p>
                    {data?.overtimeData?.contractor?.map((item) => {
                        return <p className="des__content">{item?.name}</p>;
                    })}
                </div>
            )}
            {data?.overtimeData?.break_time && (
                <div className="des">
                    <p className="">休憩 (hour)</p>
                    <p className="des__content">{data?.overtimeData?.break_time}</p>
                </div>
            )}
            {data?.overtimeData?.work_time && (
                <div className="des">
                    <p className="">稼働時間 (hour)</p>
                    <p className="des__content">{data?.overtimeData?.work_time}</p>
                </div>
            )}
            {data?.overtimeData?.work_detail && (
                <div className="des">
                    <p className="">業務内容</p>
                    <p className="des__content">{data?.overtimeData?.work_detail}</p>
                </div>
            )}
            {data?.overtimeData?.start_at && data?.overtimeData?.stop_at && (
                <div className="date des">
                    <div className="date__from date__left">
                        <p className="title">開始時間</p>
                        <p className="content">{data?.overtimeData?.start_at}</p>
                    </div>
                    <div className="date__from date__right">
                        <p className="title">終了時間</p>
                        <p className="content">{data?.overtimeData?.stop_at}</p>
                    </div>
                </div>
            )}
            {data?.overtimeData?.memo && (
                <div className="des">
                    <p className="">備考</p>
                    <p className="des__content">{data?.overtimeData?.memo}</p>
                </div>
            )}
        </div>
    );
};

export default OTDetailHoliday;
