import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import React from "react";
import { useDispatch } from "react-redux";

import { ArrowLeftIcon } from "@assets";
import { StyledChangePassword } from ".";
import { Formik } from "formik";
import { AppButton, AppInput } from "@components";
import { changePasswordSchema } from "@utils";
import { appRouteEnums } from "@enums";
import yellow_lines_icon from "@assets/Images/yellow_lines.png";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
import { AppAlert, AppModal, CircularLoader } from "..";
// import { changePasswordSchema } from "@pages";
import { changePassword, useAppSelector, selectAuth, selectApp, resetModal } from "@redux";

export const ChangePasswordView = () => {
    // redux store
    const auth = useAppSelector(selectAuth);
    const app = useAppSelector(selectApp);
    const { isLoading } = auth;
    const { modal } = app;
    // hooks
    const dispatch = useDispatch();
    const history = useHistory();
    // component state
    const handleCloseModal = () => {
        dispatch(resetModal());
        history.push(appRouteEnums.PATH_HOME);
    };
    // init value of form
    const initialValuesPackage = {
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
    };
    return (
        <StyledChangePassword>
            <CircularLoader loading={isLoading} />
            <AppModal open={modal.show} handleClose={handleCloseModal}>
                <AppAlert
                    icon={<img src={hot_yellow_frame} alt="icon" />}
                    text={modal.message}
                    handleClose={handleCloseModal}
                />
            </AppModal>
            <div className="forgotPassword__wrapper">
                <div className="backToPrevious">
                    <span className="backToPrevious__btn" onClick={() => history.goBack()}>
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <ArrowLeftIcon />
                        </IconButton>
                    </span>
                    <span className="backToPrevious__text">プロフィルを表示</span>
                </div>
                <div className="fillForm">
                    <div className="fillForm__title changePassword__text">
                        <span className="text">パスワードを変更する</span>
                        <div className="line">
                            <img className="line__icon" src={yellow_lines_icon} />
                        </div>
                    </div>
                    <div className="fillForm__content">
                        <Formik
                            initialValues={initialValuesPackage}
                            onSubmit={(values) => {
                                dispatch(
                                    changePassword({
                                        dispatch,
                                        values,
                                    })
                                );
                            }}
                            validationSchema={changePasswordSchema}
                        >
                            {({
                                handleSubmit,
                                values,
                                handleChange,
                                errors,
                                touched,
                                handleBlur,
                            }) => {
                                return (
                                    <div className="fillForm__wrapper">
                                        <div className="fillForm__input">
                                            <AppInput
                                                value={values.old_password}
                                                name="old_password"
                                                handleChange={handleChange("old_password")}
                                                label="以前のパスワード"
                                                isPassword
                                                error={errors.old_password}
                                                touched={touched.old_password}
                                                handleBlur={handleBlur("old_password")}
                                            />
                                        </div>
                                        <div className="fillForm__input">
                                            <AppInput
                                                value={values.new_password}
                                                name="new_password"
                                                handleChange={handleChange("new_password")}
                                                label="新しいパスワード"
                                                isPassword
                                                error={errors.new_password}
                                                touched={touched.new_password}
                                                handleBlur={handleBlur("new_password")}
                                            />
                                        </div>
                                        <div className="fillForm__input">
                                            <AppInput
                                                value={values.new_password_confirmation}
                                                name="new_password_confirmation"
                                                handleChange={handleChange(
                                                    "new_password_confirmation"
                                                )}
                                                label="新しいパスワードを確認"
                                                isPassword
                                                error={errors.new_password_confirmation}
                                                touched={touched.new_password_confirmation}
                                                handleBlur={handleBlur("new_password_confirmation")}
                                            />
                                        </div>
                                        <div className="fillForm__btn">
                                            <AppButton text="変える" onClick={handleSubmit} />
                                        </div>
                                    </div>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </StyledChangePassword>
    );
};
