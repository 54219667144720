import axiosClient from "./axiosClient";
import { IListProductsParams } from "./categories";

interface IVehicleDetailParams {
    id: number;
}
export const vehicleApi = {
    getVehiclesListProducts: (params: IListProductsParams) => {
        const url = `api/search-vehicle/?categoryId=${params.category}&name=${params.name}`;
        return axiosClient.get(url);
    },
    viewVehicleListItem: (params: any) => {
        const url = `/api/vehicle-item/${params.id}/?category=${params.category}`;
        return axiosClient.get(url);
    },
    viewVehicleDetail: (params: IVehicleDetailParams) => {
        const url = `/api/vehicleItem-detail/${params.id}`;
        return axiosClient.get(url);
    },
    vehicleItemCalendar: (params:any)=>{
        const url = `/api/vehicleItem-history/${params.id}`;
        return axiosClient.get(url);
    },
    vehicleBorrowItem: (params:any,id:any) =>{
        const url = `/api/borrowing-vehicleItem/${id}`;
        return axiosClient.post(url,params);
    },
    vehicleDetailBorrowing: (params: any) => {
        const url = `/api/vehicleItem-history/${params.id}/${params.itemId}`;
        return axiosClient.get(url);
    }
};
