import { CreateTypeForm, StyledTableViewWorkSchedule } from ".";

import { RiArrowRightSLine } from "react-icons/ri";
import { BsDot } from "react-icons/bs";
import { useState } from "react";
import { AppModal, CircularLoader } from "../Common";
import { CreateClassificationForm } from "./create/CreateClassificationForm";
import { workScheduleApi } from "@api";
import { useDispatch } from "react-redux";
import { updateSnackBar } from "@redux";

interface IProps {
    handleOpenDrawserToggle?: (id: any) => void;
    handleCloseDrawserToggle?: () => void;
    hasCollapse?: boolean;
    isCollapse?: boolean;
    data?: any;
    setReloadPage?: (e: any) => void;
    reloadPage?: any;
}

export const TableViewWorkSchedule = (props: IProps) => {
    const { data, setReloadPage, reloadPage } = props;
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [isOpenCreateClassificationForm, setIsOpenCreateClassificationForm] = useState(false);
    const [isOpenTypeForm, setIsOpenTypeForm] = useState(false);

    const [type1Data, setType1Data] = useState();
    const [type2Data, setType2Data] = useState();

    // const [workType1, setWorkType1] = useState([])
    const handleOpenClassificationForm = () => {
        setIsOpenCreateClassificationForm(true);
    };
    const handleCloseClassificationForm = (resetForm: any) => {
        resetForm();
        setIsOpenCreateClassificationForm(false);
    };

    const handleOpenTypeForm = () => {
        setIsOpenTypeForm(true);
    };

    const handleCloseTypeForm = (resetForm: any) => {
        setIsOpenTypeForm(false);
        resetForm();
    };
    const handleOnCreateClassificationSchedule = (values: any, resetForm: any) => {
        fetchCreateClassificationWorkScheduleApi(values, resetForm);
    };

    const handleOnCreateTypeSchedule = (values: any, resetForm: any) => {
        fetchCreateTypeWorkScheduleApi(values, resetForm);
    };

    const fetchCreateClassificationWorkScheduleApi = async (values: any, resetForm: any) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.storeClassificationWorkSchedule({
                ...values,
            });
            setLoading(false);
            // handleCloseNewForm(resetForm);
            handleCloseClassificationForm(resetForm);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: response.data.message,
                })
            );
            setReloadPage?.(!reloadPage);
            resetForm();
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };

    const fetchCreateTypeWorkScheduleApi = async (values: any, resetForm: any) => {
        setLoading(true);
        try {
            const response = await workScheduleApi.storeTypeWorkSchedule({
                ...values,
            });
            setLoading(false);
            handleCloseTypeForm(resetForm);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: response.data.message,
                })
            );
            setReloadPage?.(!reloadPage);
            resetForm();
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };

    const organizeWorkTypes = (workTypes) => {
        const type1 = workTypes.filter((wt) => wt.work_type.type === 1);
        const type2 = workTypes.filter((wt) => wt.work_type.type === 2);
        const type3 = workTypes.filter((wt) => wt.work_type.type === 3);

        const organized = type1.map((t1) => {
            const childrenType2 = type2
                .filter((t2) => t2.parent_id === t1.id)
                .map((t2) => {
                    const childrenType3 = type3.filter((t3) => t3.parent_id === t2.id);
                    return { ...t2, children: childrenType3 };
                });
            return { ...t1, children: childrenType2 };
        });

        return organized;
    };

    return (
        <StyledTableViewWorkSchedule>
            <table className="table">
                <tr className="table__header">
                    {/* <td style={{ width: "10vw" }}>
                        <p>担当</p>
                    </td> */}
                    <td style={{ width: "20vw" }}>
                        {/* <p>工事名(略名)</p> */}
                        {/* <p> Type of work </p> */}
                    </td>
                    {/* {hasCollapse ? (
                        <Collapse in={isCollapse} orientation={"horizontal"}>
                            <td
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "2rem",
                                }}
                            >
                                <p>工期</p>
                            </td>
                        </Collapse>
                    ) : (
                        <td>
                            <p>工期</p>
                        </td>
                    )} */}
                </tr>
                {/* {!!data?.length &&
                    data?.map((item) => (
                        <tr className="table__body" key={item.id}>
                            <td>
                                <p>
                                    {item.employee?.last_name}
                                    {item.employee?.first_name}
                                </p>
                            </td>
                            <td className="table__colName">
                                <p>{item.name}</p>
                                {LeftColMockData.map((data, index) => (
                                    <>
                                        <div className="flex-start type-container">
                                            <RiArrowRightSLine />
                                            <p>{data.type}</p>
                                        </div>

                                        <div>
                                            {data.classifications.map((singleClass, index) => (
                                                <>
                                                    <div
                                                        className="flex-start type-container"
                                                        style={{ paddingLeft: "0.5em" }}
                                                    >
                                                        <BsDot />
                                                        <p>{singleClass.classification}</p>
                                                    </div>

                                                    <div
                                                        className="flex-start type-container"
                                                        style={{ paddingLeft: "1em" }}
                                                    >
                                                        <p>/ {singleClass.detail}</p>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </>
                                ))}
                            </td>

                            {hasCollapse ? (
                                <Collapse in={isCollapse} orientation={"horizontal"}>
                                    <td>
                                        <div>
                                            <p className="date">{item?.start_date}</p>
                                            <p className="date">{item?.end_date}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <AppIconButton
                                            onClick={() =>
                                                handleOpenDrawserToggle &&
                                                handleOpenDrawserToggle(item.id)
                                            }
                                            icon={<img src={file_icon} />}
                                        />
                                    </td>
                                </Collapse>
                            ) : (
                                <>
                                    {" "}
                                    <td>
                                        <div>
                                            <p className="date">{item?.start_date}</p>
                                            <p className="date">{item?.end_date}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <AppIconButton
                                            onClick={() =>
                                                handleOpenDrawserToggle &&
                                                handleOpenDrawserToggle(item.id)
                                            }
                                            icon={<img src={file_icon} />}
                                        />
                                    </td>
                                </>
                            )}
                        </tr>
                    ))} */}

                {data &&
                    data?.work_schedules?.map((schedule, scheduleIndex) => (
                        <div key={scheduleIndex} style={{ gap: "2px" }}>
                            {organizeWorkTypes(schedule?.work_schedule_work_type).map((type1) => {
                                return (
                                    <>
                                        <div
                                            className="flex-start type-container opacity-80"
                                            onClick={() => {
                                                handleOpenClassificationForm();
                                                setType1Data(type1);
                                            }}
                                        >
                                            <RiArrowRightSLine />
                                            <p>{type1?.work_type?.name}</p>
                                        </div>
                                        <div style={{ marginTop: "2px" }}>
                                            {type1?.children?.map((type2) => (
                                                <>
                                                    <div
                                                        className="flex-start type-container opacity-80"
                                                        style={{ paddingLeft: "0.5em", marginTop: "1px" }}
                                                        onClick={() => {
                                                            handleOpenTypeForm();
                                                            setType2Data(type2);
                                                        }}
                                                    >
                                                        <BsDot />
                                                        <p>{type2.work_type?.name}</p>
                                                    </div>

                                                    <div>
                                                        {type2.children?.map((type3) => (
                                                            <div className="flex-start type-container" style={{ paddingLeft: "1em" }}>
                                                                <p>/ {type3?.work_type?.name}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    ))}
            </table>

            {isOpenCreateClassificationForm && (
                <AppModal open={isOpenCreateClassificationForm} handleClose={() => setIsOpenCreateClassificationForm(false)}>
                    <CreateClassificationForm data={type1Data} handleCloseNew={handleCloseClassificationForm} handleOnCreateSchedule={handleOnCreateClassificationSchedule} />
                </AppModal>
            )}

            {isOpenTypeForm && (
                <AppModal open={isOpenTypeForm} handleClose={() => setIsOpenTypeForm(false)}>
                    <CreateTypeForm data={type2Data} handleCloseNew={handleCloseTypeForm} handleOnCreateSchedule={handleOnCreateTypeSchedule} />
                </AppModal>
            )}
            <CircularLoader loading={loading} />
        </StyledTableViewWorkSchedule>
    );
};
