/* eslint-disable no-unsafe-optional-chaining */
import { updateSnackBar } from "@redux";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";

export function useShowErrorFromApi() {
    const dispatch = useDispatch();

    return function (error: AxiosError<any, any>) {
        const statusCode = error.status || error?.response?.data?.status;

        let msg = "";

        if (
            error?.response &&
            "message" in error?.response?.data &&
            typeof error?.response.data?.message === "string"
        ) {
            msg = error.response?.data.message;
        }

        if (
            error.response &&
            "message" in error?.response?.data &&
            typeof error?.response?.data?.message === "object"
        ) {
            msg = Object.values(error.response.data?.message).flat()?.[0] as string;
        }

        if (statusCode >= 500) {
            msg = "Something went wrong!";
        }
        dispatch(
            updateSnackBar({
                messageType: "error",
                message: msg,
            })
        );
    };
}
