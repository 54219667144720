export const VehicleIcon = () => {
    return (
        <>
            <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.611 2.96301V15.0001C18.611 16.3241 17.5277 17.4075 16.2036 17.4075H2.96289V7.77783C2.96289 5.11764 5.11752 2.96301 7.77771 2.96301H18.611Z"
                    stroke="#D07000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.037 17.4076V21.0187C27.037 23.0168 25.424 24.6298 23.4259 24.6298H22.2221C22.2221 23.3057 21.1388 22.2224 19.8147 22.2224C18.4907 22.2224 17.4073 23.3057 17.4073 24.6298H12.5925C12.5925 23.3057 11.5092 22.2224 10.1851 22.2224C8.86104 22.2224 7.77771 23.3057 7.77771 24.6298H6.574C4.57585 24.6298 2.96289 23.0168 2.96289 21.0187V17.4076H16.2036C17.5277 17.4076 18.611 16.3242 18.611 15.0001V6.57422H20.8258C21.6925 6.57422 22.4869 7.04367 22.9203 7.78997L24.9786 11.389H23.4259C22.7638 11.389 22.2221 11.9307 22.2221 12.5927V16.2038C22.2221 16.8659 22.7638 17.4076 23.4259 17.4076H27.037Z"
                    stroke="#D07000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.1848 27.0371C11.5143 27.0371 12.5922 25.9593 12.5922 24.6297C12.5922 23.3001 11.5143 22.2223 10.1848 22.2223C8.85518 22.2223 7.77734 23.3001 7.77734 24.6297C7.77734 25.9593 8.85518 27.0371 10.1848 27.0371Z"
                    stroke="#D07000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.8137 27.0371C21.1432 27.0371 22.2211 25.9593 22.2211 24.6297C22.2211 23.3001 21.1432 22.2223 19.8137 22.2223C18.4841 22.2223 17.4062 23.3001 17.4062 24.6297C17.4062 25.9593 18.4841 27.0371 19.8137 27.0371Z"
                    stroke="#D07000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.0355 15.0001V17.4076H23.4244C22.7624 17.4076 22.2207 16.8659 22.2207 16.2039V12.5927C22.2207 11.9307 22.7624 11.389 23.4244 11.389H24.9772L27.0355 15.0001Z"
                    stroke="#D07000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
