import { combineReducers } from "redux";
import { AnyAction, Reducer } from "@reduxjs/toolkit";

import appReducer from "./app";
import menuReducer from "./menu";
import authReducer from "./auth";
import inventoryReducer from "./inventory";
import vehicleReducer from "./vehicles";
import instrumentReducer from "./instruments";
import notificationReducer from "./notification";
import technicalDocumentsReducer from "./technicalDocuments";
import workScheduleReducer from "./workSchedule";
import timesheetsReducer from "./timesheets";
import overtimeReducer from "./overtime";
import weeklyScheduleReducer from "./weeklySchedule";
import preloadReducer from "./preload";


export * from "./app";
export * from "./menu";
export * from "./auth";
export * from "./inventory";
export * from "./vehicles";
export * from "./instruments";
export * from "./notification";
export * from "./technicalDocuments";
export * from "./workSchedule";
export * from "./timesheets";
export * from "./overtime";
export * from "./weeklySchedule";
export * from "./preload";

const productReducer = combineReducers({
    app: appReducer,
    menu: menuReducer,
    auth: authReducer,
    inventory: inventoryReducer,
    vehicle: vehicleReducer,
    instrument: instrumentReducer,
    notification: notificationReducer,
    technicalDocuments: technicalDocumentsReducer,
    workSchedule: workScheduleReducer,
    timesheets: timesheetsReducer,
    overtime: overtimeReducer,
    weeklySchedule: weeklyScheduleReducer,
    preload: preloadReducer,
});

export type RootState = ReturnType<typeof productReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === "RESET") {
        // reset state
        state = {} as RootState;
        // reset local storage
        localStorage.clear();
    }
    return productReducer(state, action);
};
export default rootReducer;
