import React from "react";
import { Grid } from "@mui/material";

import { StyledVehicleListItemComponent, VehicleItemComponent } from ".";
import { NotFoundItemView, CircularLoader } from "..";
import { selectVehicle, useAppSelector } from "@redux";

interface IProps {
    loading: boolean;
    products: any;
    categoryId?: any;
}
export const VehiclesListItemComponent = (props: IProps) => {
    // props
    const { loading, products } = props;

    const vehicle = useAppSelector(selectVehicle);
    const { dataResult } = vehicle;
    return (
        <StyledVehicleListItemComponent>
            {dataResult?.category && (
                <div className="VehicleItems__title">
                    {dataResult?.category} ・ {dataResult?.subCategory}{" "}
                </div>
            )}
            {loading ? (
                <div className="wrapperLoading">
                    <CircularLoader loading={loading} type="fullContent" />
                </div>
            ) : (
                <>
                    {products?.length ? (
                        <div className="VehicleItems__content">
                            <Grid container spacing={3}>
                                {products?.map((item) => (
                                    <Grid key={item.id} item xs={12} sm={12} md={6}>
                                        <VehicleItemComponent item={item} />
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    ) : (
                        <div className="error">
                            <NotFoundItemView />
                        </div>
                    )}
                </>
            )}
        </StyledVehicleListItemComponent>
    );
};
