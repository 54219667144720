export const responsiveText = (text: string) => {
    const temp = text.trim().split("");
    const arrayLength = (temp.length / 2).toFixed();
    return temp.length > 10
        ? `<>
            ${temp.slice(0, parseInt(arrayLength)).join("")}
            <br />
        </>`
        : `<>{temp.toString()}</>`;
};
// WHAT: if text length more than 4 characters, display 4 charaters and three dots
export const ellipsisText = (text: any, showNumber: number) => {
    return text && text.length > showNumber ? `${text.substring(0, showNumber)}...` : text;
};
export const linkImage = (text: any) => {
    return text;
};
export const linkProductImage = (text: any) => {
    return `${process.env.REACT_APP_S3_BUCKET_URL}${text}`;
};
// config link pdf to sever storage
export const linkDocument = (text: any) => {
    return text;
    // return text;
};

export const isLinkExel = (urlString: any)=>{
    const splitString =  urlString.split(".");
    return splitString[splitString.length-1]==="xlsx"?true:false;
};

// convert url
export const converUrlToGetId = (urlString: any) =>{
    const splitString = urlString.split("/");
    if (splitString.includes('edit')) {
        return splitString[splitString.length - 2];
    }
    return splitString[splitString.length-1].split("?")[0];
};
// get date params on url 
// timesheet/new?2022-09-15 = > 2022-09-15 
export const getDateParamOnUrl = (url: string) => {
    const tempArr = url.split("?");
    return tempArr.length>1 ? tempArr[tempArr.length - 1] : null;
};