import axiosClient from "./axiosClient";

export const returnSchedule = {
    returnListItemBorrowed: () => {
        const url = '/api/listBorrowing';
        return axiosClient.get(url);
    },
    returnItem: (params: any) => {
        const url = `/api/returned-item/${params.id}`;
        return axiosClient.post(url, { quantity: params.quantity, construction_id: params.construction_id });
    },
};
