import styled from "styled-components";

export const StyleAppTimeRangePicker = styled.div`
    .MuiOutlinedInput-root {
        border-radius: 1.6rem;
    }
    .MuiOutlinedInput-input {
        font-size: 1.4rem;
        padding: 1.2rem;
    }
    .MuiSvgIcon-root {
        width: 1.5em;
        height: 1.5em;
    }
    .error{
        color: red;
        font-size: 12px;
    }
`;
