import { useHistory } from "react-router-dom";

import { appRouteEnums } from "@enums";
import { StyledTableViewWorkSchedule } from ".";

interface IProps {
    handleOpenDrawserToggle?: (id: any) => void;
    handleCloseDrawserToggle?: () => void;
    hasCollapse?: boolean;
    isCollapse?: boolean;
    data?: any;
}

export const TableWrapViewWorkSchedule = (props: IProps) => {
    const { data } = props;

    const history = useHistory();

    return (
        <StyledTableViewWorkSchedule>
            <table className="table">
                <tr className="table__header">
                    <td style={{ width: "5vw" }}>
                        <span className="text-center">担当 </span>
                    </td>
                    <td style={{ width: "10vw" }}>
                        {" "}
                        <span className="text-center">工事名（略名）</span>
                    </td>
                    <td style={{ width: "10vw" }}>
                        <span className="text-center">工期</span>
                    </td>
                </tr>

                {data?.map((item: any) => (
                    <tr key={item.id}>
                        <td>
                            <div className="truncate ">
                                {item?.employee?.last_name} {item?.employee?.first_name}
                            </div>
                        </td>
                        <td>
                            <div onClick={() => history.push(`${appRouteEnums.PATH_WORK_SCHEDULE}/${item?.id}`, { itemDetail: item })} className="truncate opacity-80">
                                {item?.name}
                            </div>
                        </td>
                        <td>
                            {item?.start_date}
                            <br /> {item?.end_date}
                        </td>
                    </tr>
                ))}
            </table>
        </StyledTableViewWorkSchedule>
    );
};
