import React from "react";
import { useHistory } from "react-router-dom";

import { PAGE_NOT_FOUND, PAGE_NOT_FOUND_404 } from "@configs";
import { appRouteEnums } from "@enums";
import { AppButton } from "..";
import { StyledErrorContentView } from "./styles";
import screamboto from "@assets/Images/screamboto.svg";

export const ErrorContentView = () => {
    // hooks
    const history = useHistory();
    return (
        <StyledErrorContentView>
            <div className="error__content">
                <div className="error__imageWrapper">
                    <img src={screamboto} alt="404 image" />
                </div>
                <h4 className="error__content-404">{PAGE_NOT_FOUND_404}</h4>
                <p className="error__content-text">{PAGE_NOT_FOUND}</p>
                <div className="error__wrappButton">
                    <div className="btn">
                        <AppButton
                            onClick={() => history.push(appRouteEnums.PATH_HOME)}
                            text="ホームページに戻る"
                        />
                    </div>
                </div>
            </div>
        </StyledErrorContentView>
    );
};
