import styled from "styled-components";

import small_bg_dot from "@assets/Images/small_bg_dot.svg";

export const StyledNewTimeSheet = styled.div`
    padding-bottom: 4rem;
    .detail {
        min-height: 100vh;
        background-image: url(${small_bg_dot});
        background-repeat: no-repeat;
        background-color: #f6f6f6;
        text-align: left;
        padding: 1rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        &__top {
            display: flex;
            flex-direction: column;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                flex-direction: row;
            }
            &-title {
                font-size: 3.2rem;
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            }
            &-subTitle {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 2rem;
                padding: 2rem 0;
            }
            &-icon {
                width: 5rem;
                margin-right: 4rem;
                div {
                    width: 100%;
                    button {
                        width: 100%;
                        display: flex;
                        justify-content: end;
                    }
                }
                .MuiIconButton-root {
                    padding-left: 0;
                }
            }
        }
        .content {
            .subTitle {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 2rem;
                padding: 2rem 0;
            }
            .label {
                display: block;
                padding-bottom: 1rem;
            }
            .divider {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                position: relative;
                z-index: 1;
                padding-top: 4rem;
                padding-bottom: 2rem;
                &__text {
                    position: absolute;
                    z-index: 2;
                    padding: 0.5rem;
                    background-color: #f6f6f6;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                        width: 2rem;
                    }
                }
            }
            .divider::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                height: 1px;
                z-index: 0;
                background-color: gray;
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    width: 1px;
                    height: 100%;
                }
            }
            .btn {
                margin-top: 2rem;
            }
            .radio {
                .radioForm {
                    padding: 1.5rem;
                    border-radius: 1rem;
                    border: 2px solid #d4d4d4;
                    margin: 1rem 0.5rem;

                    .MuiSvgIcon-root {
                        width: 2em;
                        height: 2em;
                    }
                    .Mui-checked {
                        color: #43cdec;
                    }
                    .date {
                        color: #656565;
                    }
                    .text {
                        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                        color: #656565;
                    }
                    .dot {
                        padding: 0 1rem;
                        padding-bottom: 1rem;
                        /* display: flex;
                        align-items: center; */
                    }
                }
                .active {
                    border: 2px solid #43cdec !important;
                }
            }
        }
    }
`;
export const StyledNewTimeSheetForm = styled.div`
    padding-bottom: 4rem;
    .detail {
        min-height: 100vh;
        background-image: url(${small_bg_dot});
        background-repeat: no-repeat;
        background-color: #f6f6f6;
        text-align: left;
        padding: 1rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        .errorText {
            color: ${(p) => p.theme.colors.error};
        }
        .select {
            &__contractor {
                display: flex;
                column-gap: 2rem;
                width: 100%;
            }
            &__number {
                display: flex;
                flex-direction: column;
                font-size: 1.4rem;
                min-width: 6rem;
            }
        }
        &__top {
            display: flex;
            flex-direction: column;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                flex-direction: row;
            }
            &-title {
                font-size: 3.2rem;
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            }
            &-icon {
                width: 5rem;
                margin-right: 4rem;
                div {
                    width: 100%;
                    button {
                        width: 100%;
                        display: flex;
                        justify-content: end;
                    }
                }
                .MuiIconButton-root {
                    padding-left: 0;
                }
            }
        }
        &__content {
            width: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 60rem;
            }
            .boxWrapper {
                padding: 2rem 1.5rem;
                border-radius: 1.6rem;
                background-color: ${(p) => p.theme.colors.white};
                margin: 1.5rem 0;
            }
            .radio {
                .MuiFormGroup-root {
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-column-gap: 2rem;
                    .MuiTypography-root {
                        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    }
                    .Mui-checked {
                        color: #43cdec;
                    }
                    .MuiSvgIcon-root {
                        width: 1.5em;
                        height: 1.5em;
                    }
                }
            }
            .err {
                color: ${(p) => p.theme.colors.error};
            }
            .time {
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    width: 40% !important;
                }
            }
            .select {
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    width: 70%;
                }
            }
            .input {
                margin: 1.5rem 0;
                margin-top: 0;
            }
            .wrap-checkbox{
                display: flex;
                align-items:center;
                justify-content: space-between;
                .sc-kDvujY {
                    display: flex;
                }
            }
            .label {
                display: block;
                padding: 1rem 0;
            }
            .main{
                font-size:1.8rem;
            }
            .date {
                display: grid;
                grid-column-gap: 1rem;
                grid-template-columns: auto auto;
            }
            .delete {
                margin: 2rem 0 !important;
                .MuiButton-root {
                    background-color: ${(p) => p.theme.colors.secondary};
                }
            }
        }
    }
`;
export const StyledTimeSheetUserInfo = styled.div`
    width: 100%;
    .row {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 0;
        .title {
            color: #656565;
        }
        .main {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            color: #000000;
            font-size: 1.9rem;
            font-weight: 700;
        }
    }
    .row_first{
        display: flex;
        justify-content: center;
        padding: 1.5rem 0;
        .title {
            color: #656565;
            margin-right:15px;
        }
        .main {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
            color: #000000;
            font-size: 2.1rem !important;
            font-weight: 700;
            margin-right: 17.5px;
            margin-left: 17.5px;
        }
    }
    .wrapperDatePicker {
        width: 17rem;
    }
`;
