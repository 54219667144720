import React from "react";

import { StyledAlert } from ".";
import cancel_icon from "@assets/Icons/cancel_icon.svg";
import wrap_cancel_btn from "@assets/Icons/wrap_cancel_btn.svg";

type Props = {
    icon?: any;
    text?: string;
    handleClose?: () => void;
};

export const AppAlert = (props: Props) => {
    const { icon, text, handleClose } = props;
    return (
        <StyledAlert>
            <div className="alert__wrapper">
                <div className="alert__content">
                    <span className="alert__cancelIconBg">
                        <img src={wrap_cancel_btn} alt="bg" />
                    </span>
                    {/* <span className="">
                        <img className="alert__groupIcon" src={popup_icons} alt="icons" />
                    </span> */}
                    <span onClick={handleClose} className="alert__cancelIcon">
                        <img src={cancel_icon} alt="X" />
                    </span>
                    {text && <p className="alert__text">{text}</p>}
                    <span className="alert__icon">{icon}</span>
                </div>
            </div>
        </StyledAlert>
    );
};
