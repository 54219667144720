import * as React from "react";

import { FormControl, TextareaAutosize } from "@mui/material";
import { Error, StyledTextArea } from "..";

interface IProps {
    label?: string;
    name?: string;
    value?: string | number;
    error?: any;
    handleChange: (value: any) => void;
    handleBlur?: (value: any) => void;
    touched?: any;
    placeholder?: string;
    rows?: number;
    isNumber?: boolean;
}

export const AppTextArea = (props: IProps) => {
    const { label, value, error, name, placeholder, handleChange, handleBlur, touched, isNumber } =
        props;
    // const handleOnChange = React.useCallback(
    //     (event: any) => {
    //         handleChange(event.target.value);
    //     },
    //     [handleChange]
    // );
    const handleOnChange = React.useCallback(
        (event: any) => {
            // Kiểm tra nếu isNumber là true và giá trị nhập không phải là số thì không thực hiện handleChange
            if (isNumber && isNaN(Number(event.target.value))) {
                return;
            }
            handleChange(event.target.value);
        },
        [handleChange, isNumber]
    );
    return (
        <StyledTextArea>
            <div className="input__wrapper">
                {label && <div className="input__label">{label}</div>}
                <FormControl sx={{ width: "100%" }} variant="outlined">
                    <TextareaAutosize
                        value={value}
                        onChange={handleOnChange}
                        onBlur={handleBlur}
                        name={name}
                        autoComplete="off"
                        placeholder={placeholder}
                        maxRows={8}
                    />

                    {/* show error message only when change input value */}
                    {!!error && !!touched && <Error>{error}</Error>}
                    {/* show error message only when imply first submit action  */}
                    {/* {!!error || (name && <ErrorMessage name={name} component={Error} />)} */}
                </FormControl>
            </div>
        </StyledTextArea>
    );
};
