import { StyledAppCustomSelect, StyledAppCustomeOption } from "./styles";
// import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Avatar } from "@mui/material";
import { LABEL_ALL_CONTRACTORS, LABEL_BOOK_MARKED } from "@configs";
import { useEffect, useState } from "react";
interface IProps {
    placeholder?: string;
    options?: any;
    onChange?: (value: any) => void;
    value?: any;
    isMulti?: boolean;
    name?: string;
    className?: string;
    noError?: boolean;
    isShowAvatar?: boolean;
    workTypeOptions?: any;
    groupOptions?: any;
    groupContructions?: any;
    groupUserOptions?: any;
    defaultValue?: any;
    error?: string;
    touched?: boolean;
    isClearable?: boolean;
    // createNew?: boolean;
}
const FormatOptionLabel = (props) => {
    const { values, isShowAvatar } = props;
    return (
        <StyledAppCustomeOption>
            {isShowAvatar && values?.avatar !== "emptyBlank" ? <Avatar src={values?.avatar} /> : ""}
            <div className="optionName">
                <span>
                    {values.label} {values.name}
                </span>
            </div>
            <div>{values.customAbbreviation}</div>
        </StyledAppCustomeOption>
    );
};

export const AppCustomeSelect = (props: IProps) => {
    const {
        placeholder,
        className,
        options,
        onChange,
        value,
        name,
        isMulti,
        defaultValue,
        isShowAvatar,
        groupOptions,
        groupContructions,
        groupUserOptions,
        error,
        touched,
        isClearable,
        // createNew,
    } = props;
    const groupedOptions = [
        {
            label: LABEL_BOOK_MARKED,
            options: groupOptions?.recentlyUsedContractor?.length
                ? groupOptions?.recentlyUsedContractor.map((item) => {
                      return {
                          label: item.name,
                          value: item.id.toString(),
                      };
                  })
                : [],
        },
        {
            label: LABEL_ALL_CONTRACTORS,
            options: groupOptions?.contractors?.length
                ? groupOptions?.contractors.map((item) => {
                      return {
                          label: item.name,
                          value: item.id.toString(),
                      };
                  })
                : [],
        },
        {
            label: LABEL_BOOK_MARKED,
            options: groupContructions?.recentlyUsedConstructions?.length
                ? groupContructions?.recentlyUsedConstructions.map((item) => {
                      return {
                          label: item.name,
                          value: item.id.toString(),
                      };
                  })
                : [],
        },
        {
            label: LABEL_ALL_CONTRACTORS,
            options: groupContructions?.constructions?.length
                ? groupContructions?.constructions.map((item) => {
                      return {
                          label: item.name,
                          value: item.id.toString(),
                      };
                  })
                : [],
        },
        {
            label: "",
            options: groupUserOptions ? groupUserOptions : [],
        },
    ];
    return (
        <StyledAppCustomSelect>
            <CreatableSelect
                classNamePrefix="filter"
                name={name}
                value={value}
                defaultValue={defaultValue}
                onChange={(values) => {
                    onChange && onChange(values);
                }}
                options={groupOptions ? groupedOptions : options}
                placeholder={placeholder}
                isMulti={isMulti}
                className={className}
                formatOptionLabel={(props) => (
                    <FormatOptionLabel
                        values={props}
                        options={options}
                        isShowAvatar={isShowAvatar}
                    />
                )}
                hideSelectedOptions={false}
                // isValidNewOption={() => (createNew ? false : true)}
                isClearable={isClearable}
                noOptionsMessage={() => "オプションなし"}
            />
            {/* {noError || (name && <ErrorMessage name={name} component={Error} />)} */}
            {error && touched && (
                <p style={{ color: "red", fontSize: "13px", margin: "8px" }}>{error}</p>
            )}
        </StyledAppCustomSelect>
    );
};

export const AppCustomeSelectCreateNew = (props: IProps) => {
    const { placeholder, className, options, onChange, value, name, isMulti, isShowAvatar } = props;

    const [valueSelect, setValueSelect] = useState<any>(value);
    const [optionsSelect, setOptionsSelect] = useState<any[]>([]);

    useEffect(() => {
        setOptionsSelect(options);
    }, [options]);

    const handleChangeSelectValue = (values) => {
        const createOptions = values.find((value) => value?.__isNew__);
        if (createOptions) {
            setOptionsSelect((pre) => [
                ...pre,
                { label: createOptions.label, value: createOptions.value },
            ]);
        }
        const valuesChecked = values.map((value) => ({
            label: value.label,
            value: value.value,
        }));
        setValueSelect(valuesChecked);
        onChange && onChange(valuesChecked?.map((item) => item.value));
    };
    return (
        <StyledAppCustomSelect>
            <CreatableSelect
                classNamePrefix="filter"
                name={name}
                value={valueSelect}
                defaultValue={valueSelect}
                onChange={handleChangeSelectValue}
                options={optionsSelect}
                placeholder={placeholder}
                isMulti={isMulti}
                className={className}
                formatOptionLabel={(props) => {
                    return (
                        <FormatOptionLabel
                            values={props}
                            options={options}
                            isShowAvatar={isShowAvatar}
                        />
                    );
                }}
                noOptionsMessage={() => "オプションなし"}
            />
            {/* {noError || (name && <ErrorMessage name={name} component={Error} />)} */}
        </StyledAppCustomSelect>
    );
};

export const AppCustomeSelectLiveLetEmployee = (props: IProps) => {
    const { placeholder, className, options, onChange, value, name, isMulti, isShowAvatar } = props;

    const [valueSelect, setValueSelect] = useState<any>(value);
    const [optionsSelect, setOptionsSelect] = useState<any[]>([]);

    useEffect(() => {
        setOptionsSelect(options);
    }, [options]);

    const handleChangeSelectValue = (values) => {
        const createOptions = values.find((value) => value?.__isNew__);
        if (createOptions) {
            setOptionsSelect((pre) => [
                ...pre,
                { label: createOptions.label, value: createOptions.value },
            ]);
        }
        const valuesChecked = values.map((value) => ({
            label: value.label,
            value: value.value,
        }));
        setValueSelect(valuesChecked);
        onChange && onChange(valuesChecked?.map((item) => item));
    };
    return (
        <StyledAppCustomSelect>
            <CreatableSelect
                classNamePrefix="filter"
                name={name}
                value={valueSelect}
                defaultValue={valueSelect}
                onChange={handleChangeSelectValue}
                options={optionsSelect}
                placeholder={placeholder}
                isMulti={isMulti}
                className={className}
                formatOptionLabel={(props) => {
                    return (
                        <FormatOptionLabel
                            values={props}
                            options={options}
                            isShowAvatar={isShowAvatar}
                        />
                    );
                }}
                noOptionsMessage={() => "オプションなし"}
            />
            {/* {noError || (name && <ErrorMessage name={name} component={Error} />)} */}
        </StyledAppCustomSelect>
    );
};
