import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { StyledInventoryDetail } from ".";
import { AppTopBar, AppModal, AppAlertConfirm, CircularLoader } from "..";
import arrow_left from "@assets/Icons/arrow_left.svg";
import { Grid } from "@mui/material";
import { InventoryDetialHistoryComponent } from ".";
import { InventoryDetailInfoComponent } from "./InventoryDetailInfo";
import { appRouteEnums } from "@enums";
import { updateSnackBar, resetAuth, setIdConstuctionEmployeeSearch } from "@redux";
import { inventoryApi } from "@api";
import cool_blue_frame from "@assets/Icons/cool_blue_frame.svg";
import { AppAlert } from "../Common";
import { linkImage } from "@utils";
import { TITLE_BORROW_SCHEDULE, TITLE_RETURN_SCHEDULE } from "@configs";

export const InventoryDetailView = () => {
    const { id } = useParams<{ id: any }>();
    // hook
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const categoryId = query.get("categoryId");
    const subCategoryId = query.get("subCategoryId");
    const history = useHistory();
    const dispatch = useDispatch();
    // component state
    const [loading, setLoading] = React.useState<boolean>(false);
    const [show, setShow] = React.useState<boolean>(false);
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    const [reload, setReload] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState("");
    const [valueForm, setValueForm] = React.useState({
        taken: "",
        added: "",
        title: "",
        selectType: "",
        inventoryId: "",
    });
    const [productDetail, setProductDetail] = React.useState<any>();
    // what: call api borrow pruducts
    const fetchBorrowingProducts = async (params: any) => {
        setLoading(true);
        try {
            await inventoryApi.borrowingInventoryProduct({
                product_id: id,
                quantity: params.quantity,
                construction_id: params.construction_id,
            });
            setMessage("出庫できました");
            setLoading(false);
            setIsSuccess(true);
            dispatch(setIdConstuctionEmployeeSearch(params?.construction_id));
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response?.data?.message,
                })
            );
        }
    };
    // what: call api return products
    const fetchReturnProducts = async (params: any) => {
        setLoading(true);
        try {
            const res: any = await inventoryApi.returnInventoryProduct({
                product_id: id,
                quantity: params.quantity,
                construction_id: params.construction_id,
            });
            setMessage(res?.data?.message);
            setLoading(false);
            setIsSuccess(true);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };
    // what: fetch api get detail function
    const fetchDetailApi = async () => {
        setLoading(true);
        try {
            const response = await inventoryApi.viewInventoryDetail({
                id,
            });
            setLoading(false);
            setProductDetail(response.data.data);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data?.message,
                })
            );
            if (err?.response?.status === 401) {
                dispatch(resetAuth());
            } else if (err?.response?.status === 404) {
                history.push(appRouteEnums.PATH_INVENTORY);
            }
        }
    };
    React.useEffect(() => {
        fetchDetailApi();
    }, [reload]);
    // events
    const handleAccept = () => {
        if (valueForm?.selectType === "borrow") {
            fetchBorrowingProducts(valueForm);
        } else {
            fetchReturnProducts(valueForm);
        }
        setShow(false);
    };
    const handleReject = () => {
        setShow(false);
    };
    const handleSubmit = (values: any) => {
        setShow(true);
        setValueForm({
            title: productDetail?.name,
            icon: linkImage(productDetail?.image?.path),
            ...values,
        });
    };
    const handleCloseModal = () => {
        setIsSuccess(false);
        setReload(!reload);
    };
    return (
        <StyledInventoryDetail>
            <CircularLoader loading={loading} />
            <AppModal open={show}>
                <AppAlertConfirm
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                    name={valueForm?.title}
                    inventory={valueForm}
                    title={
                        valueForm?.selectType === "borrow"
                            ? TITLE_BORROW_SCHEDULE
                            : TITLE_RETURN_SCHEDULE
                    }
                    // icons
                    icon={linkImage(productDetail?.image?.path)}
                />
            </AppModal>
            <AppModal open={isSuccess}>
                <AppAlert
                    icon={<img src={cool_blue_frame} alt="icon" />}
                    text={message}
                    handleClose={handleCloseModal}
                />
            </AppModal>
            <AppTopBar />
            {productDetail ? (
                <div className="wrapperInventoryDetail">
                    <div className="InventoryDetail">
                        <div className="InventoryDetail__top">
                            <span
                                className="InventoryDetail__back"
                                onClick={() =>
                                    categoryId
                                        ? history.push(
                                              appRouteEnums.PATH_INVENTORY +
                                                  `?categoryId=${categoryId}&subCategoryId=${subCategoryId}`
                                          )
                                        : history.push(appRouteEnums.PATH_INVENTORY)
                                }
                            >
                                <img src={arrow_left} alt="<" />
                            </span>
                            <div className="InventoryDetail__top-title">
                                <p className="main">{productDetail?.name}</p>
                                <p className="subTitle">
                                    {productDetail?.category} ・ {productDetail?.subCategory}
                                </p>
                            </div>
                        </div>
                        <div className="InventoryDetail__content">
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={4}>
                                    <InventoryDetialHistoryComponent
                                        historyList={productDetail?.history}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <InventoryDetailInfoComponent
                                        infoDetail={productDetail}
                                        handleSubmit={handleSubmit}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </StyledInventoryDetail>
    );
};
