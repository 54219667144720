import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { StyledInstrumentsItem } from "./styles";
import { linkProductImage } from "@utils";
import { Box } from "@mui/system";
interface IProps {
    item?: any;
    categoryId?: any;
}
export const InstrumentItemComponent = (props: IProps) => {
    // props
    const { item } = props;
    //hooks
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const categoryId = query.get("categoryId");
    const subCategoryId = query.get("subCategoryId");
    const history = useHistory();
    return (
        <StyledInstrumentsItem>
            <div
                className="wrapper disabled"
                onClick={() => {
                    categoryId
                        ? history.push(
                              `/instruments/calendar/${item?.id}?categoryId=${categoryId}&subCategoryId=${subCategoryId}`
                          )
                        : history.push(`/instruments/calendar/${item?.id}`);
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "1rem",
                    }}
                >
                    <div className="wrapperImage">
                        <img
                            className="image"
                            src={linkProductImage(item?.image?.path)}
                            alt="inventory item"
                        />
                    </div>
                    <div className="wrapperContent">
                        <p className="title" style={{ whiteSpace: "pre-line" }}>
                            {item?.name}
                        </p>
                    </div>
                    {/* <span>{!item?.borrowing && <img src={star_icon} />}</span> */}
                    <span>
                        {!item?.borrowing && (
                            <p style={{ color: "red" }}>{item?.isLate ? "遅延" : "使用中"}</p>
                        )}
                    </span>
                </Box>
                <p className="link">
                    <a className="link__text" href={item?.url} target="_blank">
                        {item?.url}
                    </a>
                </p>
            </div>
        </StyledInstrumentsItem>
    );
};
