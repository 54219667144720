import styled from "styled-components";
import bg_profile from "@assets/Images/bg_profile.png";

export const StyledProfileView = styled.div`
    .profile {
        background-color: #f6f6f6;
        background-repeat: no-repeat;
        background-size: cover;
        @media (min-width: 768px) {
            background-image: url(${bg_profile});
            background-color: ${(p) => p.theme.colors.white};
            height: 100vh;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
            height: 100%;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            height: 100vh;
        }
        &__logo {
            text-align: left;
        }
        &__topbar {
            background-color: ${(p) => p.theme.colors.white};
            border-bottom: 2px solid ${(p) => p.theme.colors.gray};
        }
        &__content {
            padding: 4rem 0;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                position: relative;
                margin: 0 10rem;
                z-index: 1;
                &:after {
                    content: "";
                    z-index: -1;
                    top: 12rem;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 85%;
                    background-color: #f6f6f6;
                    color: ${(p) => p.theme.colors.gray};
                    border-radius: 2.4rem;
                }
            }
            /* @media (min-width: ${(p) => p.theme.breakPoints.breakMobile}) {
                margin: 0 2rem;
            } */
            @media (min-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
                margin: 0 15rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin: 0 10rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                margin: 0 25rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
                margin: 0 35rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                margin: 0 40rem;
            }
        }
        &__error {
            color: ${(p) => p.theme.colors.error};
        }
        &__userInfo {
            display: flex;
            justify-content: center;
            flex-direction: column;

            &-imageWrapper {
                height: 16rem;
                width: 16rem;
                max-width: 16rem;
                min-height: 16rem;
                border-radius: 100px;
                border-radius: 100%;
                background-color: ${(p) => p.theme.colors.white};
                .image {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }
            }
            &-edit {
                color: ${(p) => p.theme.colors.secondary};
                font-size: 1.6rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                text-decoration: underline;
                padding-top: 1.8rem;
                &:hover {
                    cursor: pointer;
                }
            }
            &-name {
                font-size: 3.2rem;
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                color: ${(p) => p.theme.colors.darkBlack};
                padding: 0 2rem;
                word-break: break-all;
                display: block;
                display: -webkit-box;
                max-width: 100%;
                height: 80px;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    padding: 0 10rem;
                }
                .firstName {
                    padding-left: 1rem;
                }
                .lastName {
                    padding-right: 1rem;
                }
            }
            &-id {
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                color: ${(p) => p.theme.colors.darkBlack};
                font-size: 2.2rem;
            }
        }
        &__items {
            padding: 0 1.6rem;
            padding-top: 2.5rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                padding: 0 10rem;
                padding-top: 2.5rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 0 10rem;
                padding-top: 2.5rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                padding: 0 15rem;
                padding-top: 2.5rem;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakSuperBig}) {
                padding: 0 35rem;
                padding-top: 2.5rem;
            }
        }
        &__inputUpload {
            display: none;
        }
        .item__date {
            margin-right: 1rem !important;
        }
        &__item {
            text-align: left;
            display: flex;
            padding: 1rem;
            border-radius: 1.6rem;
            margin-bottom: 1.6rem;
            background-color: ${(p) => p.theme.colors.white};
            .MuiIconButton-root {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: ${(p) => p.theme.colors.primary};
                    opacity: 0.1;
                    border-radius: 1.2rem;
                }
            }

            &-text {
                display: flex;
                align-items: center;
                padding-left: 1.1rem;
                font-size: 1.6rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                color: ${(p) => p.theme.colors.darkBlack};
                word-break: break-all;
            }
            .email {
                display: block;
                display: -webkit-box;
                max-width: 100%;
                padding-top: 0.8rem;
                height: 45px;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-listCertis {
            }
            &-certi {
                padding: 1rem 1.3rem;
                background-color: ${(p) => p.theme.colors.gray};
                font-size: 1.6rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                margin: 0 0 1rem 1rem;
                border-radius: 1.2rem;
                color: ${(p) => p.theme.colors.darkBlack};
                display: block;
                display: -webkit-box;
                height: 6rem;
                max-width: 100%;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 1rem;
            }
        }
        &__btn {
            text-align: left;
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            border-radius: 1.6rem;
            margin-top: 2rem;
            background-color: ${(p) => p.theme.colors.white};
            transition: 250ms;
            cursor: pointer;
            &:hover {
                background-color: ${(p) => p.theme.colors.darkBlack};
            }
            .MuiIconButton-root {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: ${(p) => p.theme.colors.secondary};
                    opacity: 0.1;
                    border-radius: 1.2rem;
                }
            }
            &-text {
                width: 90%;
                display: flex;
                align-items: center;
                padding-left: 1.1rem;
                font-size: 1.6rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                color: ${(p) => p.theme.colors.secondary};
            }
            &-next {
                display: flex;
                align-items: center;
            }
        }
        &__btnLogout {
            display: flex;
            justify-content: center;
            padding-top: 7rem;
            padding-bottom: 5rem;
            .btn {
                cursor: pointer;
            }
            .text {
                font-size: 1.4rem;
                color: ${(p) => p.theme.colors.gray1};
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                padding-left: 0.8rem;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
`;
