import dayjs, { Dayjs } from "dayjs";

enum FormatDateEnum {
    "YYYY-MM-DD" = "YYYY-MM-DD",
    "DD-MM-YYYY" = "DD-MM-YYYY",
    "MM-DD-YYYY" = "MM-DD-YYYY",
    "DD/MM/YYYY" = "DD/MM/YYYY",
    "HH:mm - DD/MM/YYYY" = "HH:mm - DD/MM/YYYY",
    "MMM DD" = "MMM DD",
    "MMM DD, YYYY" = "MMM DD, YYYY",
    "hh : mm : A" = "hh : mm : A",
    "DD/MM" = "DD / MM",
    "mm" = "mm",
    "ss" = "ss",
    "HH:mm" = "HH:mm",
    "HH:mm:ss" = "HH:mm:ss",
    "YYYY-MM-DD HH:mm:ss" = "YYYY-MM-DD HH:mm:ss",
}

type FormatDateType = {
    date: Date | string | Dayjs;
    format: keyof typeof FormatDateEnum;
};

/**
 * "Format a date string using the dayjs library."
 *
 * The first line of the function is a comment. It's a good idea to include a comment at the top of
 * each function that describes what the function does
 * @param {Date|string|Dayjs} date - The date you want to format.
 * @param {FormatDateEnum} format - The format you want to use to format the date.
 */
export const formatDate = ({ date, format }: FormatDateType): string =>
    dayjs(date).format(FormatDateEnum[format]);
