import { Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import { Formik } from "formik";
import moment from "moment";

import { StyledInventoryDetailInfo } from ".";
import { AppButton, AppSelect, AppInput, AppCustomeSelect } from "..";
import { inventorySchema, linkImage, useConstructionSites } from "@utils";
import { timesheetApi } from "@api";
import { LABEL_CONSTRUCTIONSITE, PLACEHOLDER_CONSTRUCTIONSITE } from "@configs";
import { selectAuth, useAppSelector } from "@redux";
interface IProps {
    handleSubmit: (values: any) => void;
    infoDetail?: any;
}
const optionsType = [
    { label: "出庫", value: "1" },
    { label: "入庫", value: "2" },
];
export const InventoryDetailInfoComponent = (props: IProps) => {
    const { handleSubmit, infoDetail } = props;
    const [optionSiteConstructions, setOptionSiteConstructions] = useState<any>();
    const { idConstuctionEmployeeSearch } = useAppSelector(selectAuth);
    // component state
    // hooks
    const { updateConstructionHistory, sortConstructionSites } = useConstructionSites();
    // form state
    const initialValuesPackage = {
        selectType: "1",
        quantityProduct: "1",
        selectQuantity: "",
        siteConstruction: "",
    };
    React.useEffect(() => {
        fetchListConstructions();
    }, []);
    // fetch api
    const fetchListConstructions = async () => {
        try {
            const result = await timesheetApi.getListConstructions(idConstuctionEmployeeSearch);
            setOptionSiteConstructions(sortConstructionSites(result.data.data));
        } catch (err) {}
    };
    return (
        <StyledInventoryDetailInfo>
            <div className="InventoryDetailInfo">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className="info__detail">
                            <span className="info__title">画像</span>
                            <div className="info__imageWrapper">
                                <img
                                    className="image"
                                    src={linkImage(infoDetail?.image?.path)}
                                    alt="detail product inventory"
                                />
                            </div>
                            {/* <Divider /> */}
                            {/* <div className="info__infoWrapper">
                                <span className="info__title">予約時間</span>
                                <span className="info__number">
                                    {!!infoDetail?.lastUpdateHistory &&
                                        moment(infoDetail?.lastUpdateHistory).format(
                                            "YYYY/MM/DD・HH:mm"
                                        )}
                                </span>
                            </div> */}
                            <div className="info__infoWrapper">
                                <span className="info__title">更新日時</span>
                                <span className="info__number">
                                    {!!infoDetail?.lastUpdate &&
                                        moment(infoDetail?.lastUpdate).format("YYYY/MM/DD・HH:mm")}
                                    {/* {infoDetail?.lastUpdateHistory} */}
                                </span>
                            </div>
                            <Divider />
                            <div className="info__infoWrapper">
                                <span className="info__title">在庫数</span>
                                <span className="info__number">{infoDetail?.amount}</span>
                            </div>
                            <Divider />
                            <div className="info__infoWrapper">
                                <span className="info__title">荷姿</span>
                                <span className="info__number">{infoDetail?.unit}</span>
                            </div>
                            <Divider />
                            <div className="info__infoWrapper">
                                <span className="info__title">保管場所</span>
                                <span className="info__number">{infoDetail?.location}</span>
                            </div>
                            <Divider />
                            <div className="info__infoWrapper">
                                <span className="info__title">材料情報 </span>
                                <span
                                    className="info__link"
                                    onClick={() => window.open(infoDetail?.external_url, "_blank")}
                                >
                                    {infoDetail?.external_url}
                                </span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Formik
                            initialValues={initialValuesPackage}
                            onSubmit={(values) => {
                                updateConstructionHistory(values?.siteConstruction);

                                handleSubmit({
                                    quantity: values.quantityProduct,
                                    selectType: values.selectType === "1" ? "borrow" : "return",
                                    construction_id: values.siteConstruction,
                                });
                            }}
                            validationSchema={inventorySchema}
                        >
                            {({
                                handleSubmit,
                                values,
                                handleChange,
                                errors,
                                touched,
                                setFieldValue,
                            }) => {
                                const submit = () => {
                                    handleSubmit();
                                };

                                return (
                                    <>
                                        <div className="form">
                                            <div className="form__amount">
                                                <div style={{ paddingBottom: "1rem", zIndex: "2" }}>
                                                    <p style={{ paddingBottom: "1rem" }}>
                                                        {LABEL_CONSTRUCTIONSITE}
                                                    </p>
                                                    {!!optionSiteConstructions && (
                                                        <AppCustomeSelect
                                                            placeholder={
                                                                PLACEHOLDER_CONSTRUCTIONSITE
                                                            }
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "siteConstruction",
                                                                    e?.value
                                                                );
                                                            }}
                                                            groupContructions={
                                                                optionSiteConstructions
                                                            }
                                                            options={
                                                                optionSiteConstructions
                                                                    ?.constructions?.length &&
                                                                optionSiteConstructions?.constructions.map(
                                                                    (item) => {
                                                                        return {
                                                                            label: item?.name,
                                                                            value: item?.id,
                                                                        };
                                                                    }
                                                                )
                                                            }
                                                            // value={values.siteConstruction}
                                                            name="siteConstruction"
                                                        />
                                                    )}
                                                </div>
                                                <AppSelect
                                                    options={optionsType}
                                                    name="selectType"
                                                    handleChange={handleChange("selectType")}
                                                    value={values.selectType}
                                                    placeholder="タイプを選択してください"
                                                />

                                                <div className="form__amount-input">
                                                    <AppInput
                                                        isNumber
                                                        value={values.quantityProduct}
                                                        name="quantityProduct"
                                                        handleChange={handleChange(
                                                            "quantityProduct"
                                                        )}
                                                        error={errors.quantityProduct}
                                                        touched={touched.quantityProduct}
                                                        min={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn">
                                            <AppButton text="決定" onClick={submit} />
                                        </div>
                                    </>
                                );
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </div>
        </StyledInventoryDetailInfo>
    );
};
