import styled from "styled-components";

export const StyledCalendar = styled.div`
    .workSchedule {
        text-align: left;
        padding: 1rem 1.5rem;
        .desktop {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                max-height:650px;
            }
            display: flex !important;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        .wrapper-nav{
            display: flex;
            margin-bottom:5rem;
            justify-content:space-between;
            @media (max-width: 425px) {
                display:flex;
                flex-direction:column;
        }
        }
        .newOT__btn{
                    margin-right:1.5rem;
                    margin-left:1.5rem;
                    border-radius: 1.6rem;
                    height: 100%;
                    border: 2px solid orange;
                    padding-right: 1rem;
                    padding-left: 1rem;  
                    display: flex;
                    align-item: center;
                    justify-content: center;
                    font-weight:700;
                    color: #d1d1d1;
                    min-width: 100px;
                    cursor: pointer;
                    color: orange!important;
        }
        .datePicker{
            display: flex;
            align-item:center;
            flex
        }
        .view-more-btn {
            margin: 0 auto;
            margin-top: 16px;
            border-radius: 1.6rem;
            height: 100%;
            border: 2px solid orange;
            padding: 1rem;
            display: flex;
            align-item: center;
            justify-content: center;
            font-weight: 700;
            color: #d1d1d1;
            min-width: 100px;
            cursor: pointer;
            color: orange !important;
            font-size: 1.6rem;

            &.disabled {
                opacity: 0.3;
                cursor: not-allowed;
            }
        }
    }  
    .timesheet__filter {
            padding: 2rem 0;
            display: flex;
            overflow-y: auto;
            column-gap: 0.5rem;
            margin-bottom: 2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
                grid-template-columns: auto auto auto auto;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                max-width: 80rem;
                margin-bottom: 0;
            }
            .active {
                border: 1px solid ${(p) => p.theme.colors.secondary} !important;
                .title {
                    color: ${(p) => p.theme.colors.secondary}!important;
                }
            }
            .timesheet__filter-item {
                    display: flex;
                    padding: 0.5rem 1rem;
                    border-radius: 1.6rem;
                    border: 1px solid ${(p) => p.theme.colors.darkBlack};
                    justify-content: center;
                    cursor: pointer;
                    min-width: 12rem;
                    .title {
                        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                        padding-right: 1rem;
                        display: block;
                        display: -webkit-box;
                        max-width: 100%;
                        height: 20px;
                        line-height: 1.2;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
        } 
    .label{
        margin-top:10px;
        margin-right:10px;
    }
`;

export const StyledTableViewWeeklySchedule = styled.div`
    /* @media (min-width: 500px) {
             {
                position: sticky;
                left: 0;
                z-index:2;
            }
            
        } */
    position: sticky;
    left: 0;
    z-index: 2;

    .up-down-button:hover {
        color: orange;
    }
    .tableNameList {
        td {
            vertical-align: top;
            font-size: 16px;
            font-weight: 900;
        }
        @media (max-width: 425px) {
            td {
                display: flex;
                justify-centent: center;
                align-item: center;
                font-size: 12px;
                flex-direction: column;
                width: 100px !important;
            }
        }
        &__colName {
            vertical-align: top;
            display: flex;
            max-width: 120px;
            justify-content: center;
            height: 13.2rem;

            p {
                font-size: 16px;
                font-weight: 900;
            }
            @media (max-width: 425px) {
                p {
                    font-size: 12px;
                    max-width: 50px !important;
                    overflow: hidden;
                }
            }
        }
        .table__colName {
            display: flex;
            justify-content: center;
        }
        .date {
            width: 8rem;
        }
        &__header {
            color: #8e8e8e;
            background-color: #f4f4f4;
            height: 40px;
            td {
                padding-top: 11px;
                height: 40px;
                display: flex;
                text-align: center;
                justify-content: center;
                align-item: center;
                font-size: 1.2rem;
            }
        }
        &__body {
            border-bottom: 1px solid #dedede !important;
            height: 13.2rem;
            width: 50px;
            td {
                padding-right: 0;
                width: 50px;
            }
        }
    }
`;
export const StyledTableWrapper = styled.div`
    width: 100%;
    overflow-x: auto;
    display: flex;
    .table {
        table-layout: fixed;
        width: 1300px;
        overflow: hidden;
        .dayOff {
            background-color: #ffffff;
        }
        &__header {
            border: 1px solid #d4d4d4;
            height: 40px;

            td {
                text-align: center;
                background-color: #ebe6e6;
            }
        }
        &__body {
            td {
                border: 1px solid #d4d4d4;
                /* width: 15rem !important; */
                overflow: hidden;
                padding: 1rem;
                height: 15.2rem;
                /* text-align: left; */
            }
        }
        &__event {
            .event {
                cursor: pointer;
                width: 100%;
                height: 100%;
                border-radius: 1.6rem;
                padding: 0.5rem;
                max-height: 15.2rem;
                &__name {
                    display: block;
                    height: 40px;
                    overflow: auto !important;
                    display: -webkit-box;
                    line-height: 1.2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    max-width: 100%;
                }
                &__wrapper {
                    display: flex;
                }
                &__content {
                    padding-left: 1rem;
                }
                .MuiAvatar-root {
                    width: 3rem;
                    height: 3rem;
                }
                .workContent {
                    display: block;
                    display: -webkit-box;
                    max-width: 100%;
                    line-height: 1.2;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    td {
        width: 25rem !important;
        overflow: hidden;
    }
    .text {
        &__construction {
            display: block;
            display: -webkit-box;
            line-height: 1.2;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            max-width: 100%;
        }
    }
`;