export const SmallArrowDownIcon = () => {
    return (
        <>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.72 5.94006L7.06667 10.2867C7.58 10.8001 8.42 10.8001 8.93333 10.2867L13.28 5.94006"
                    stroke="#43CDEC"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
