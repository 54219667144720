import { InventoryDetailView } from "@components";
import React from "react";

export const InventoryDetail = () => {
    return (
        <>
            <InventoryDetailView />
        </>
    );
};
