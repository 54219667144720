import styled from "styled-components";

export const StyledAppDatePicker = styled.div`
    .MuiOutlinedInput-adornedEnd {
        font-size: 1.6rem;
        border-radius: 1.6rem;
        background-color: white;
    }
    .MuiOutlinedInput-input {
        /* max-width: 8rem; */
        padding: 1rem 0;
        padding-left: 1rem;
    }
    .MuiFormControl-marginNormal{
        margin-top: 0;
    }
`;
