import React from "react";
interface IProps {
    data?: any;
}
const OTDetailVacation = (props: IProps) => {
    const { data } = props;
    return (
        <div>
            {data?.overtimeData?.reason?.name && (
                <div className="des user-content__right">
                    <p className="des__title">事由</p>
                    <p className="des__content">{data?.overtimeData?.reason?.name}</p>
                </div>
            )}
            {data?.overtimeData?.note && (
                <div className="des user-content__right">
                    <p className="des__title">事由</p>
                    <p className="des__content">{data?.overtimeData?.note}</p>
                </div>
            )}
            {data?.overtimeData?.phone_number && (
                <div className="des">
                    <p className="">連絡先</p>
                    <p className="des__content">{data?.overtimeData?.phone_number}</p>
                </div>
            )}
            {data?.overtimeData?.start_at && data?.overtimeData?.stop_at && (
                <div className="date des">
                    <div className="date__from date__left">
                        <p className="title">開始時間</p>
                        <p className="content">{data?.overtimeData?.start_at}</p>
                    </div>
                    <div className="date__from date__right">
                        <p className="title">終了時間</p>
                        <p className="content">{data?.overtimeData?.stop_at}</p>
                    </div>
                </div>
            )}
            {data?.overtimeData?.memo && (
                <div className="des">
                    <p className="">備考</p>
                    <p className="des__content">{data?.overtimeData?.memo}</p>
                </div>
            )}
        </div>
    );
};

export default OTDetailVacation;
