import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";

import { appRouteEnums, menuTitleEnums } from "@enums";
import { StyledInventoryView, InventoryCatetoryComponent, InventoryListItemComponent } from ".";
import { TitleMenuComponent, AppInput, AppTopBar, AppIconButton } from "..";
import { SearchIcon } from "@assets";
//import { categoriesApi } from "@api";
import { /*updateSnackBar, resetAuth,*/ setCategory, useAppSelector, selectPreloadData, selectPreloadDataLoading } from "@redux";
import { API_CATEGORY_TYPE_INVENTORY, PLACEHOLDER_SEARCH } from "@configs";
import { useWindowDimensions } from "@utils";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { useHistory, useLocation } from "react-router-dom";
//import { set } from "lodash";
//import { title } from "process";
//import path from "path";
//import { update } from "lodash";

type InventoryCategoryDataType = {
    id: number;
    name: string;
    parent_id: number;
    type: number;
}

  export const InventoryView = () => {
    // component state
    const [inputValue, setInputValue] = React.useState<string>("");
    //const [categories, setCategories] = React.useState<Array<any>>();
    //const [listProducts, setListProducts] = React.useState<any>();
    const [subCategoryId, setSubCategoryId] = React.useState<any>("");
    const [loading, setLoading] = React.useState<boolean>(true);
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const messagesEndRef = React.useRef<any>(null);
    const [categoryViewMobile, setCategoryViewMobile] = React.useState<boolean>(true);
    // hooks
    const dispatch = useDispatch();
    const width = useWindowDimensions().width;
    const history = useHistory();
    const preloadData = useAppSelector(selectPreloadData);
    const preloadDataLoading = useAppSelector(selectPreloadDataLoading);

    const inventory = !preloadData?.inventory ? [] : preloadData.inventory.map(product => {
        const productCopy = { ...product };
        const inventoryProduct = preloadData.inventoryProducts.find((invProduct: any) => invProduct.product_id === productCopy.id);
        const quantity = inventoryProduct?.quantity || 0;

        const subCategory:InventoryCategoryDataType | undefined = preloadData.categories.find(
            (cat: InventoryCategoryDataType) => cat.id === productCopy.category_id && cat.type === API_CATEGORY_TYPE_INVENTORY
        );

        let category: InventoryCategoryDataType | undefined = undefined;
        if (subCategory) {
            category = preloadData.categories.find((cat: InventoryCategoryDataType) => cat.id === subCategory.parent_id);
        }


        const attachment = preloadData.attachments.find((att: any) =>
            att.attachmentable_id === productCopy.id &&
            att.attachmentable_type.toLowerCase().endsWith("product")
        );

        const image = attachment ? {
            id: attachment.id,
            path: attachment.path,
            updated_at: attachment.updated_at,
        } : null;

        // Borrowings and returns related to this product
        const borrowings = preloadData.borrowings.filter((borrowing: any) =>
            borrowing.construction_id !== null &&
            borrowing.borrowingable_id === productCopy.id &&
            borrowing.borrowingable_type.toLowerCase().endsWith("product")
        ).map((borrowing: any) => {
            const autmentedBorrowing = { ...borrowing };
            autmentedBorrowing.typeCollection = 1;
            return autmentedBorrowing;
        });

        const returns = preloadData.returnedItems.filter((returnedItem: any) => 
            returnedItem.construction_id !== null &&
            returnedItem.borrowingable_id === productCopy.id &&
            returnedItem.borrowingable_type.toLowerCase().endsWith("product")
        ).map((returnedItem: any) => {
            const augmentedReturnedItem = { ...returnedItem };
            augmentedReturnedItem.typeCollection = 2;
            return augmentedReturnedItem;
        });

        const augmentEvent = (event: any) => {
            const augmentedEvent = { ...event };

            // Get the construction_name
            const construction = preloadData.constructions.find(
                (cons: any) => cons.id === event.construction_id
            );
            augmentedEvent.construction_name = construction?.name || null;

            // Get the user info and their attachment
            const user = preloadData.users.find(
                (userObj: any) => userObj.id === event.user_id
            );
            const augmentedUser = user ? { ...user } : null;

            if (augmentedUser) {
                const userAttachment = preloadData.attachments.find(
                    (att: any) => 
                        att.attachmentable_id === augmentedUser.id &&
                        att.attachmentable_type.toLowerCase().endsWith("user")
                );

                // Add user object and possibly their attachment as part of user
                augmentedEvent.user = {
                    ...augmentedUser,
                    avatar: userAttachment || null
                };
            }

            delete augmentedEvent.borrowingable_type;

            return augmentedEvent;
        };

        // Sort and slice the 5 most recent events
        const allEvents = [...borrowings, ...returns];
        const sortedEvents = allEvents.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        const recentEvents = sortedEvents.slice(0, 5);

        // Augment the 5 most recent events
        const productEvents = recentEvents.map(productEvent => augmentEvent(productEvent));
        const lastUpdateHistory = productEvents.length > 0 ? productEvents[0].updated_at : null;

        const externalUrl = productCopy.url;
        delete productCopy.url;

        const lastUpdate = productCopy.updated_at;
        delete productCopy.updated_at;

        const location = productCopy.description;

        delete productCopy.category_id;
        delete productCopy.user_id;
        delete productCopy.created_at;
        delete productCopy.deleted_at;
        delete productCopy.description;

        return {
            ...productCopy, // Existing product fields
            external_url: externalUrl,
            lastUpdate: lastUpdate,
            lastUpdateHistory: lastUpdateHistory,
            location: location,

            amount: quantity,

            subCategoryId: subCategory?.id || null,
            subCategory: subCategory?.name || null,
            categoryId: category?.id || null,
            category: category?.name || null,

            image: image,

            history: productEvents,
        };
    }).sort((a, b) => a.position - b.position);

    //console.log(inventory);

    const inventoryCategories = !preloadData?.categories ? [] : preloadData.categories
        .filter(category => category.type === API_CATEGORY_TYPE_INVENTORY && category.parent_id === 0) // Get top-level inventory categories
        .sort((a, b) => a.position - b.position)
        .map(parentCategory => ({
            ...parentCategory, // Copy all properties of the parent category
            title: parentCategory.name, // Rename 'name' to 'title'
            subCategory: preloadData.categories
                .filter(subCategory => subCategory.type === API_CATEGORY_TYPE_INVENTORY && subCategory.parent_id === parentCategory.id) // Get matching inventory sub-categories
                .sort((a, b) => a.position - b.position)
    }));

    //console.log(inventoryCategories);

    useEffect(() => {
        setLoading(preloadDataLoading);
    }, [preloadDataLoading]);

    //What: scroll to bottom to view data results when device is mobile
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    //What: call api get categories
//    React.useEffect(() => {
//        const fetchCategoriesApi = async () => {
//            try {
//                const response = await categoriesApi.getCategories({
//                    type: API_CATEGORY_TYPE_INVENTORY,
//                });
//                setCategories(response.data.data);
//            } catch (err: any) {
//                dispatch(
//                    updateSnackBar({
//                        messageType: "error",
//                        message: err.response.data.message,
//                    })
//                );
//                dispatch(resetAuth());
//            }
//        };
//        fetchCategoriesApi();
//    }, []);
//    //what: call api get list products
//    const fetchListProducts = async (params: any) => {
//        setLoading(true);
//        try {
//            const response = await categoriesApi.getListProducts({
//                category: params.category || "",
//                name: params.name || "",
//            });
//            setLoading(false);
//            setListProducts(response.data.data);
//        } catch (err: any) {
//            setListProducts(undefined);
//            setLoading(false);
//        }
//    };
//
//    //what: call api after re-render
//    //how: when sub category id is updated, this page is re-render
//    React.useEffect(() => {
//        fetchListProducts({
//            category: subCategoryId,
//        });
//    }, [subCategoryId]);
    //what: events
    const handleOnChange = (value: string) => {
        setInputValue(value);
    };
    //what: call api in search form
    const handleOnSearchSubmit = () => {
        setCategoryViewMobile(false);
//        fetchListProducts({
//            name: inputValue,
//        });
        setSelectedIndex(-1);
        if (width < 768) {
            scrollToBottom();
        }
        dispatch(setCategory({ category: "", subCategory: "" }));
    };
    //what: select a subCategory ID
    const handleSelectSubCategory = (id: number/*, categoryId: number*/) => {
        setSubCategoryId(id);
        setCategoryViewMobile(false);
        if (width < 768) {
            scrollToBottom();
        }
        setInputValue("");
//        history.push(`/inventory?categoryId=${categoryId}&subCategoryId=${id}`);
    };
    //what: active select category
    const handleClick = (index: any) => {
        if (selectedIndex === index) {
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(index);
        }
    };
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const categoryId = query.get("categoryId");

    const filteredInventory = subCategoryId ?
        inventory.filter((item) => item.subCategoryId === subCategoryId) :
        inputValue ? inventory.filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase())) :
        inventory;
    const inputValueMatchCount = inputValue ? filteredInventory.length : undefined;

    return (
        <StyledInventoryView>
            <AppTopBar />
            <div className="wrapperInventory">
                <div className="inventory">
                    <div className="inventory__top">
                        <p
                            className="newOT__top-icon"
                            onClick={() => history.push(appRouteEnums.PATH_HOME)}
                        >
                            <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                        </p>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={8}>
                                <div className="inventory__top-title">
                                    <TitleMenuComponent text={menuTitleEnums.TITLE_INVENTORY} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className="inventory__top-searchForm" style={{flexDirection: "column"}}>
                                    <AppInput
                                        value={inputValue}
                                        iconStart={<SearchIcon />}
                                        handleChange={handleOnChange}
                                        placeholder={PLACEHOLDER_SEARCH}
                                        disabled={false}
                                        onClick={handleOnSearchSubmit}
                                    />
                                    {inputValueMatchCount !== undefined ? <span style={{fontSize: "0.8em", color:"#666", padding:"0.5rem 2rem"}}>一致数: {inputValueMatchCount}</span> : null}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="inventory__content visible-pc">
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <div className="category">
                                    {inventoryCategories &&
                                        inventoryCategories.map((category, index) => (
                                            <div key={category.id}>
                                                <InventoryCatetoryComponent
                                                    category={category}
                                                    checked={
                                                        String(categoryId) ===
                                                            String(category.id) ||
                                                        index === selectedIndex
                                                    }
                                                    onClick={() => {
                                                        handleClick(index);
                                                    }}
                                                    handleSelectSubCategory={
                                                        handleSelectSubCategory
                                                    }
                                                />
                                            </div>
                                        ))}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <div className="listItem" ref={messagesEndRef}>
                                    <InventoryListItemComponent
                                        loading={loading}
                                        products={filteredInventory}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="visible-mobile">
                        {categoryViewMobile ? (
                            <div className="category">
                                {inventoryCategories &&
                                    inventoryCategories.map((category, index) => (
                                        <div key={category.id}>
                                            <InventoryCatetoryComponent
                                                category={category}
                                                checked={
                                                    String(categoryId) === String(category.id) ||
                                                    index === selectedIndex
                                                }
                                                onClick={() => {
                                                    handleClick(index);
                                                }}
                                                handleSelectSubCategory={handleSelectSubCategory}
                                            />
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <div>
                                <div
                                    style={{ width: "100%", textAlign: "left", paddingTop: "2rem" }}
                                    onClick={() => setCategoryViewMobile(true)}
                                >
                                    <img src={mobile_arrow_left} alt="icon" />
                                </div>
                                <InventoryListItemComponent
                                    loading={loading}
                                    products={filteredInventory}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </StyledInventoryView>
    );
};
