import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { preloadApi } from "src/Api/preload";
import { RootState } from ".";

type preloadDataType = {
    attachments: any,
    borrowings: any,
    boxDocuments: any,
    categories: any,
    constructions: any,
    documents: any,
    documentCategories: any,
    groups: any,
    groupsUsers: any,
    instruments: any,
    instrumentItems: any,
    inventory: any,
    inventoryCategories: any,
    inventoryProducts: any,
    returnedItems: any,
    users: any,
    vehicles: any,
    vehicleImages: any,
    vehicleCategories: any,
    weeklySchedule: any,

    loadingPreloadData: boolean;
    loadingPreloadDataError: string;
};

const initialState: preloadDataType = {
    attachments: [],
    borrowings: [],
    boxDocuments: [],
    categories: [],
    constructions: [],
    documents: [],
    documentCategories: [],
    groups: [],
    groupsUsers: [],
    instruments: [],
    instrumentItems: [],
    inventory: [],
    inventoryCategories: [],
    inventoryProducts: [],
    returnedItems: [],
    users: [],
    vehicles: [],
    vehicleImages: [],
    vehicleCategories: [],
    weeklySchedule: [],

    loadingPreloadData: false,
    loadingPreloadDataError: "",
};

export const fetchPreloadData = createAsyncThunk(
    "preload/fetchPreloadData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await preloadApi.getPreloadData();
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

const preloadSlice = createSlice({
    name: "preload",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPreloadData.pending, (state) => {
            state.loadingPreloadData = true;
        });
        builder.addCase(fetchPreloadData.fulfilled, (state, action) => {
            const preloadData = action.payload.data;

            const updatedReturnedItems = preloadData.returnedItems.map((returnedItem) => {
                const correspondingBorrowing = preloadData.borrowings.find((borrowing) =>
                    borrowing.id === returnedItem.borrowing_id
                );
                return {
                    ...returnedItem,
                    borrowingable_id: correspondingBorrowing?.borrowingable_id || null,
                    borrowingable_type: correspondingBorrowing?.borrowingable_type || null,
                    user_id: correspondingBorrowing?.user_id || null,
                };
            });

            state.attachments = preloadData.attachments;
            state.borrowings = preloadData.borrowings;
            state.boxDocuments = preloadData.boxDocuments;
            state.categories = preloadData.categories;
            state.constructions = preloadData.constructions;
            state.documents = preloadData.documents;
            state.documentCategories = preloadData.documentCategories;
            state.groups = preloadData.groups;
            state.groupsUsers = preloadData.groupsUsers;
            state.instruments = preloadData.instruments;
            state.instrumentItems = preloadData.instrumentItems;
            state.inventory = preloadData.inventory;
            state.inventoryCategories = preloadData.inventoryCategories;
            state.inventoryProducts = preloadData.inventoryProducts;
            state.returnedItems = updatedReturnedItems;
            state.users = preloadData.users;
            state.vehicles = preloadData.vehicles;
            state.vehicleImages = preloadData.vehicleImages;
            state.vehicleCategories = preloadData.vehicleCategories;
            state.weeklySchedule = preloadData.weeklySchedule;

            state.loadingPreloadData = false;
            state.loadingPreloadDataError = "";
        });
        builder.addCase(fetchPreloadData.rejected, (state) => {
            state.loadingPreloadData = false;
            state.loadingPreloadDataError = "Failed to fetch preload data";
        });
    },
});

export default preloadSlice.reducer;

export const preloadActions = preloadSlice.actions;

export const selectPreloadData = (state: RootState) => state.preload;
export const selectPreloadDataLoading = (state: RootState) => state.preload.loadingPreloadData;
export const selectPreloadDataError = (state: RootState) => state.preload.loadingPreloadDataError;