import { BellIcon, InstrumentIcon, OverTimeIcon, VehicleIcon } from "@assets";
import { IconButton } from "@mui/material";
import React from "react";

import red_dot from "@assets/Icons/Notification_Icons/red_dot.svg";
import trash_icon from "@assets/Images/trash_icon.png";

import { StyledNotificationItem } from ".";
import { NotificationTypeEnums } from "@enums";

interface IProps {
    data?: any;
    isSelected: boolean;
    handleOnSelectNoti: (values: any) => void;
    handleDeleteNoti: () => void;
    handleToggleSelect: () => void;
}
export const NotificationItem = (props: IProps) => {
    const { data, handleOnSelectNoti, handleDeleteNoti, handleToggleSelect, isSelected } = props;
    return (
        <StyledNotificationItem>
            <div className="noti__item">
                <div className="noti__item-icon">
                    {data?.type?.type === NotificationTypeEnums.TYPE_REQUEST_OT && (
                        <IconButton className="icon__overtime" size="medium" disableRipple>
                            <OverTimeIcon />
                        </IconButton>
                    )}
                    {data?.type?.type === NotificationTypeEnums.TYPE_VEHICLE && (
                        <IconButton className="icon__instrument" size="medium" disableRipple>
                            <InstrumentIcon />
                        </IconButton>
                    )}
                    {data?.type?.type === NotificationTypeEnums.TYPE_INSTRUMENT && (
                        <IconButton className="icon__vehicle" size="medium" disableRipple>
                            <VehicleIcon />
                        </IconButton>
                    )}
                    {data?.type?.type === NotificationTypeEnums.TYPE_REQUEST_TIMESHEET && (
                        <IconButton className="icon__vehicle" size="medium" disableRipple>
                            <BellIcon />
                        </IconButton>
                    )}
                </div>
                <div
                    className="noti__item-content"
                    onClick={() =>
                        handleOnSelectNoti({
                            type: data?.type,
                            id: data?.id,
                            status: data?.status,
                            otID: data?.type?.otID,
                            tsID: data?.type?.tsID,
                            timesheetType: data?.type?.timesheet_type,
                        })
                    }
                >
                    <p className="noti__item-title">
                        {data.title}
                        <span style={{ marginRight: "7px" }}></span>
                        {data?.type?.typeOfOvertime?.toString() === "0" && "(予定届)"}
                        {data?.type?.typeOfOvertime?.toString() === "1" && "(本届)"}
                    </p>
                    <p className="noti__item-description">{data.content}</p>
                </div>
                <div className="noti__item-checkboxContain">
                    <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        checked={isSelected}
                        onChange={handleToggleSelect}
                    />
                </div>
                <img
                    onClick={handleDeleteNoti}
                    src={trash_icon}
                    className="noti__item-deleteIcon"
                />
                <div className="noti__item-status">
                    {!data.status ? (
                        <span>
                            <img src={red_dot} alt="icon" />
                        </span>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </StyledNotificationItem>
    );
};
