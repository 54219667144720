import axiosClient from "./axiosClient";

export const alcoholApi = {
    confirmCheckAlcohol: (body: { id: number }) => {
        const url = `/api/timesheet/confirm-check-alcohol`;
        return axiosClient.post(url, body);
    },
    getAlcoholInformation: (body: any) => {
        const url = `/api/timesheet/get-list-alcohol-information?date=${body.date}`;
        return axiosClient.get(url);
    },
    getAlcoholInformationHistory: (body: any) => {
        const url = `/api/timesheet/get-list-alcohol-confirm-history?date=${body.date}`;
        return axiosClient.get(url);
    }
};


