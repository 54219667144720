import React from "react";

import { NotificationView } from "@components";

export const Notification = () => {
    return (
        <div>
            <NotificationView />
        </div>
    );
};
