import styled from "styled-components";

export const StyledAppDateRangePicker = styled.div`
    width: 100%;
    .MuiOutlinedInput-root {
        border-radius: 2rem;
    }
    .MuiOutlinedInput-input {
        font-size: 1.4rem;
        text-align: center;
        padding: 12px;
    }
`;
export const StyledAppRangeDatePicker = styled.div`
    .wrapperDateRange {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 2rem;
    }
    .MuiOutlinedInput-input {
        font-size: 1.4rem;
    }
    .MuiOutlinedInput-root {
        padding-right: 0;
        border-radius: 1.6rem;
        /* margin-top: 0.5rem; */
    }
    .label {
        color: #656565;
        /* padding-bottom: 0.5rem; */
    }
`;
