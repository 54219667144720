import { appRouteEnums } from "@enums";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import clsx from "clsx";
import { Formik } from "formik";

import { AppTopBar, AppIconButton, AppSelect, AppButton, CircularLoader } from "..";
import { StyledNewTimeSheet } from "./styles";              
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { getDateParamOnUrl, selectTimeSheetTypeSchema, timesheetType } from "@utils";
import { CoppyIcon } from "@assets";
import { timesheetApi } from "@api";
import { LABEL_TIME_SHEET_TYPE } from "@configs";

export const NewTimeSheet = () => {
    // component state
    const [radioValue, setRadioValue] = React.useState<any>(0);
    const [lastestTimesheets, setLastestTimesheets] = React.useState<any>();
    const [loading, setLoading] = React.useState<boolean>(false);
    // hooks
    const history = useHistory();
    const fetchLastestTimesheet = async () => {
        setLoading(true);
        try {
            const response = await timesheetApi.listLastestTimesheet();
            setLoading(false);
            setLastestTimesheets(response.data.data);
        } catch (err: any) {
            setLoading(false);
        }
    };
    React.useEffect(() => {
        fetchLastestTimesheet();
    }, []);
    // events
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };
    const handleBtnCoppy = () => {
        history.push(
            `${appRouteEnums.PATH_TIMESHEET}/new/${radioValue.split("/")[0]}?copy=${
                radioValue.split("/")[1]
            }&date=${getDateParamOnUrl(window.location.href)}`
        );
    };
    return (
        <StyledNewTimeSheet>
            <AppTopBar />
            <div className="detail">
                <div className="detail__top">
                    <p
                        className="detail__top-icon"
                        onClick={() => history.push(appRouteEnums.PATH_TIMESHEET)}
                    >
                        <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                    </p>
                    <p className="detail__top-title">申請項目</p>
                </div>
                <div className="content">
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Formik
                                initialValues={{ selectValue: "" }}
                                onSubmit={(values) => {
                                    history.push(
                                        `${appRouteEnums.PATH_TIMESHEET_NEW}/${values.selectValue}?date=${getDateParamOnUrl(window.location.href)}`
                                    );
                                }}
                                validationSchema={selectTimeSheetTypeSchema}
                            >
                                {({ handleSubmit, values, handleChange }) => {
                                    return (
                                        <>
                                            <div className="select">
                                                <p className="subTitle">新しく作る</p>
                                                <label className="label">{LABEL_TIME_SHEET_TYPE}</label>
                                                <AppSelect
                                                    options={timesheetType}
                                                    placeholder="タイムシートタイプを選択"
                                                    value={values.selectValue}
                                                    handleChange={handleChange("selectValue")}
                                                    name="selectValue"
                                                />
                                            </div>
                                            <div className="btn">
                                                <AppButton onClick={handleSubmit} text="次へ" />
                                            </div>
                                        </>
                                    );
                                }}
                            </Formik>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <div className="divider">
                                <span className="divider__text">ま た</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className="radio">
                                <p className="subTitle">日付変更してコピー</p>
                                <label className="label">
                                    コピーする最終日のアプリケーションの1つを選択します
                                </label>
                                {loading ? (
                                    <CircularLoader loading={loading} type="fullContent" />
                                ) : (
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={radioValue}
                                        onChange={handleRadioChange}
                                    >
                                        {lastestTimesheets?.length
                                            ? lastestTimesheets.map((item) => (
                                                  <FormControlLabel
                                                      key={item.id}
                                                      value={`${item.type}/${item.id}`}
                                                      className={clsx(
                                                          radioValue === `${item.type}/${item.id}`
                                                              ? "active"
                                                              : "",
                                                          "radioForm"
                                                      )}
                                                      control={<Radio />}
                                                      label={
                                                          <Box sx={{ paddingLeft: "1rem" }}>
                                                              <p className="date">{item.date}</p>
                                                              <p className="text">
    
                                                                  <span>{item?.title}</span>
                                                              </p>
                                                          </Box>
                                                      }
                                                  />
                                              ))
                                            : ""}
                                    </RadioGroup>
                                )}

                                <div className="btn">
                                    <AppButton
                                        text="Copy"
                                        icon={<CoppyIcon />}
                                        disabled={!radioValue ? true : false}
                                        onClick={handleBtnCoppy}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </StyledNewTimeSheet>
    );
};
