import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Grid } from "@mui/material";
import "date-fns";
import { ja } from "date-fns/locale";
import React, { useEffect, useState } from "react";

import date_picker_icon from "@assets/Icons/date_picker_icon.svg";

import { StyledAppDatePicker } from ".";

interface IProps {
    label?: string;
    maxDate?: any;
    minDate?: any;
    format?: string;
    handleChange: (value: any) => void;
    date?: any;
    views?: any;
    monthOnly?: boolean;
    disabled?: boolean;
}
export const AppDatePicker = (props: IProps) => {
    const { label, maxDate, minDate, format = "yyyy-MM-dd", handleChange, date, disabled } = props;
    const [openCalendar, setOpenCalendar] = React.useState(false);

    const [value, setValue] = useState<string | null>(null);

    useEffect(() => {
        if (!date) return;
        setValue(date);
        setOpenCalendar(false);
    }, [date]);

    return (
        <StyledAppDatePicker>
            <MuiPickersUtilsProvider locale={ja} utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                        fullWidth
                        inputVariant="outlined"
                        onClick={disabled ? undefined : () => setOpenCalendar(!openCalendar)}
                        onClose={() => setOpenCalendar(false)}
                        open={openCalendar}
                        minDate={minDate}
                        maxDate={maxDate}
                        disableToolbar
                        variant="inline"
                        format={format}
                        defaultValue={value}
                        margin="normal"
                        id="date-picker-inline"
                        label={label}
                        value={value}
                        onChange={(value) => {
                            setValue(value as any);
                            handleChange(value);
                        }}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        // views={views}
                        keyboardIcon={<img src={date_picker_icon} alt="icon" />}
                        disabled={disabled}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </StyledAppDatePicker>
    );
};
