import { StyledAppIconButton } from "./styles";
import { IconButton } from "@mui/material";

type IAppIconButtonProps = {
    text?: string;
    icon: any;
    color?: string;
    onClick?: any;
    disabled?: boolean;
};
export const AppIconButton = (props: IAppIconButtonProps) => {
    // props
    const { icon, color, onClick, text, disabled} = props;

    return (
        <StyledAppIconButton color={color}>
            <IconButton disabled={disabled} onClick={onClick} color="primary">
                {icon}
                {text}
            </IconButton>
        </StyledAppIconButton>
    );
};
