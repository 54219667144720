import React from "react";
import { HomeContentComponent } from "@components";

export const Home = () => {
    return (
        <div>
            <HomeContentComponent />
        </div>
    );
};
