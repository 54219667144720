import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Avatar, Divider, Popover } from "@mui/material";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { linkImage, useWindowDimensions } from "@utils";
import { WIDTH_DRAWER_MOBILE, WIDTH_DRAWER_PC } from "@configs";
import { StyledOverTimePopoverList, StyledOverTimeContentModal } from ".";
import { AppAlert, AppButton, AppModal, CircularLoader } from "..";
import arrow_toggle from "@assets/Icons/arrow/arrow_toggle.svg";
import { appRouteEnums } from "@enums";
import { useAppSelector, selectAuth, updateSnackBar } from "@redux";
import { overtimeApi } from "@api";
import { OT_STATUS_OK, OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import checked_status_icon from "@assets/Icons/OT/checked_status_icon.svg";
import unchecked_status_icon from "@assets/Icons/OT/unchecked_status_icon.svg";
import { useDispatch } from "react-redux";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";

type Anchor = "top" | "left" | "bottom" | "right";

interface IProps {
    openToogle?: boolean;
    anchor?: any;
    handleCloseToggle?: () => void;
    timeSheetDateData: any;
    date?: any;
    id: any;
    groupId: any;
}
interface IUserItem {
    overtime?: any;
}
const UserItem = (props: IUserItem) => {
    const { overtime } = props;
    // redux store
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    const anchorRefElement = React.useRef<HTMLDivElement>(null);
    const history = useHistory();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [typeOT, setTypeOT] = React.useState<boolean>(false);
    const [openPopupNoti, setOpenPopupNoti] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");
    const dispatch = useDispatch();

    const submitChangeTypeOT = async (event, data) => {
        event.stopPropagation();
        setLoading(true);
        try {
            const res = await overtimeApi.changeOverTimesType({
                overtime_id: data,
                // overtime_id: 1,
            });
            setMessage(res.data.message);
            setOpenPopupNoti(true);
            setTypeOT(true);
            setLoading(false);
            // window.location.reload();
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response?.data?.message,
                })
            );
        }
    };

    const handleViewDetail = (values: { otId; userId; status; step; typeOvertime }) => {
        if (
            (userInfo?.id === values.userId && values.status === OT_STATUS_PENDING && Number(values.typeOvertime) === 0) ||
            (userInfo?.id === values.userId && values.status === OT_STATUS_REJECT && Number(values.typeOvertime) === 0) ||
            (userInfo?.id === values.userId && Number(values.status) === OT_STATUS_OK && Number(values.step) !== 5 && Number(values.typeOvertime) === 0)
        ) {
            history.push(`${appRouteEnums.PATH_OVERTIME}/edit/${values.otId}`);
        } else {
            history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otId}`);
        }
    };

    const handleClosePopupNoti = () => {
        setOpenPopupNoti(false);
        // isSuccess && history.push(`${appRouteEnums.PATH_OVERTIME}`);
    };

    return (
        <>
            <AppModal open={openPopupNoti} handleClose={handleClosePopupNoti}>
                <AppAlert icon={<img src={hot_yellow_frame} alt="icon" />} text={message} handleClose={handleClosePopupNoti} />
            </AppModal>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* <div className="item" ref={anchorRefElement} onClick={onOpenPopover}> */}
                <div className="item" ref={anchorRefElement}>
                    <Avatar src={linkImage("https://livelet.s3.amazonaws.com/" + overtime?.employee?.avatar?.path)} />
                    <span className="item__title">
                        {overtime?.employee?.last_name}
                        {overtime?.employee?.first_name}
                    </span>
                </div>
                {loading ? (
                    <CircularLoader loading={loading} type="fullContent" />
                ) : (
                    <StyledOverTimePopoverList>
                        {/* {overtime?.employee?.map((item, index) => {
                            return (
                                <>
                                    <div className="contain">
                                        <div className="wrapItemTempo">
                                            <div className="item__status">
                                                {item?.type_of_overtime === 0 && overtime?.employee?.id === userInfo?.id && !typeOT ? (
                                                    <div className="item__status-official" onClick={(event) => submitChangeTypeOT(event, item?.id)}>
                                                        <span className="text">本届に変更</span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div
                                            className="wrapItem"
                                            onClick={() => {
                                                handleViewDetail({
                                                    otId: item?.id,
                                                    userId: overtime?.employee?.id,
                                                    status: item?.status,
                                                    step: item?.step,
                                                    typeOvertime: item?.type_of_overtime,
                                                });
                                            }}
                                        >
                                            <div className="item__status">
                                                {item?.status === OT_STATUS_OK && (
                                                    <div className="item__status-checked">
                                                        <span className="text">OK</span>
                                                        <img className="icon" src={checked_status_icon} alt="icon" />
                                                    </div>
                                                )}
                                                {item?.status === OT_STATUS_REJECT && (
                                                    <div className="item__status-unChecked">
                                                        <span className="text">NG</span>
                                                        <img className="icon" src={unchecked_status_icon} alt="icon" />
                                                    </div>
                                                )}
                                                {item?.status === OT_STATUS_PENDING && (
                                                    <div className="item__status-pending">
                                                        <span className="text">未決</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="title" key={index}>
                                        {item?.title}
                                    </div>
                                </>
                            );
                        })} */}
                        <>
                            <div className="contain">
                                <div className="wrapItemTempo">
                                    <div className="item__status">
                                        {overtime?.type_of_overtime === 0 && overtime?.employee?.id === userInfo?.id && !typeOT ? (
                                            <div className="item__status-official" onClick={(event) => submitChangeTypeOT(event, overtime?.id)}>
                                                <span className="text">本届に変更</span>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className="wrapItem"
                                    onClick={() => {
                                        handleViewDetail({
                                            otId: overtime?.id,
                                            userId: overtime?.employee?.id,
                                            status: overtime?.status,
                                            step: overtime?.step,
                                            typeOvertime: overtime?.type_of_overtime,
                                        });
                                    }}
                                >
                                    <div className="item__status">
                                        {overtime?.status === OT_STATUS_OK && (
                                            <div className="item__status-checked">
                                                <span className="text">OK</span>
                                                <img className="icon" src={checked_status_icon} alt="icon" />
                                            </div>
                                        )}
                                        {overtime?.status === OT_STATUS_REJECT && (
                                            <div className="item__status-unChecked">
                                                <span className="text">NG</span>
                                                <img className="icon" src={unchecked_status_icon} alt="icon" />
                                            </div>
                                        )}
                                        {overtime?.status === OT_STATUS_PENDING && (
                                            <div className="item__status-pending">
                                                <span className="text">未決</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="title">{overtime?.title}</div>
                        </>
                    </StyledOverTimePopoverList>
                )}
            </div>
        </>
    );
};
export const OverTimeToggleModal = (props: IProps) => {
    const { openToogle, anchor, handleCloseToggle, timeSheetDateData, id, groupId } = props;
    //  HOOKS
    const width = useWindowDimensions().width;
    const history = useHistory();
    // events
    const date = moment(timeSheetDateData?.date).format("YYYY-MM-DD");
    const handleOpenNewApplication = () => {
        history.push(`${appRouteEnums.PATH_OVERTIME}/new?date=${date}`);
    };
    const List = (anchor: Anchor) => {
        // redux states
        const auth = useAppSelector(selectAuth);
        const { userInfo } = auth;
        const [showListTimeSheet, setShowListTimeSheet] = React.useState(false);
        const anchorRefElement = React.useRef<HTMLDivElement>(null);
        const [loading, setLoading] = React.useState<boolean>(false);
        const [data, setData] = React.useState<any>();
        const onClosePopover = React.useCallback(() => {
            setShowListTimeSheet(false);
        }, []);
        const onOpenPopover = React.useCallback(() => {
            setShowListTimeSheet(true);
        }, []);
        React.useEffect(() => {
            fetchApiViewTimesheetByDay();
        }, [timeSheetDateData?.date, id?.id]);
        // fetch api view timesheet by date
        const fetchApiViewTimesheetByDay = async () => {
            setLoading(true);
            const date = moment(timeSheetDateData?.date).format("YYYY-MM-DD");
            const pickUserId = id?.id;
            const pickGroupId = groupId?.groupId;
            try {
                const response = await overtimeApi.getListOTModal({
                    date: date,
                    userId: pickUserId,
                    groupId: pickGroupId,
                }); // const response = await timesheetApi.viewTimesheetByDay({ date, pickUserId });
                setLoading(false);
                setData(response.data.data);
            } catch (err) {
                setLoading(false);
            }
        };

        return (
            <Box
                sx={{
                    width: anchor === "top" || anchor === "bottom" ? "auto" : width > 768 ? WIDTH_DRAWER_PC : WIDTH_DRAWER_MOBILE,
                }}
                role="presentation"
            >
                <StyledOverTimeContentModal>
                    <div className="toggle__top">
                        <p className="toggle__top-collapse" onClick={handleCloseToggle}>
                            <img className="toggle__top-icon" src={arrow_toggle} />
                        </p>
                        <p className="toggle__top-date">{timeSheetDateData?.date && moment(timeSheetDateData?.date).format("YYYY-MM-DD")}</p>
                    </div>
                    <Divider />
                    <div className="toggle__user">
                        <div className="toggle__user-item" onClick={onOpenPopover} ref={anchorRefElement}>
                            <Avatar src={linkImage(userInfo?.avatar?.path)} />
                            <span className="toggle__user-name">
                                {userInfo?.last_name}
                                {userInfo?.first_name}
                            </span>
                        </div>
                        <div className="toggle__user-btn">
                            <AppButton onClick={handleOpenNewApplication} text="新規作成" />
                        </div>
                        <Popover
                            open={showListTimeSheet}
                            anchorEl={anchorRefElement.current}
                            onClose={onClosePopover}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <StyledOverTimePopoverList>
                                {/* {data?.length
                                    ? filterUserTimesheets(data, userInfo?.id).map((item: any) => {
                                          console.log("item", item);
                                          return (
                                              <div className="wrapItem">
                                                  <span key={item?.id} className="item" onClick={() => history.push(`${appRouteEnums.PATH_TIMESHEET}/edit/${item?.type}/${item?.id}`)}>
                                                      {item?.title}
                                                  </span>
                                                  <div className="item__status">
                                                      {item?.status === OT_STATUS_OK && (
                                                          <div className="item__status-checked">
                                                              <span className="text">OK</span>
                                                              <img className="icon" src={checked_status_icon} alt="icon" />
                                                          </div>
                                                      )}
                                                      {item?.status === OT_STATUS_REJECT && (
                                                          <div className="item__status-unChecked">
                                                              <span className="text">NG</span>
                                                              <img className="icon" src={unchecked_status_icon} alt="icon" />
                                                          </div>
                                                      )}
                                                      {item?.status === OT_STATUS_PENDING && (
                                                          <div className="item__status-pending">
                                                              <span className="text">未決</span>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          );
                                      })
                                    : ""} */}
                                {/* {data && data?.length
                                    ? data?.map((item, index) => {
                                          console.log("status", item?.status);
                                          return (
                                                <div className="wrapItem" key={index}>
                                                    <span key={item?.id} className="item" onClick={() => history.push(`${appRouteEnums.PATH_TIMESHEET}/edit/${item?.type}/${item?.id}`)}>
                                                        {item?.title}
                                                    </span>
                                                    <div className="item__status">
                                                        {item?.status === OT_STATUS_OK && (
                                                            <div className="item__status-checked">
                                                                <span className="text">OK</span>
                                                                <img className="icon" src={checked_status_icon} alt="icon" />
                                                            </div>
                                                        )}
                                                        {item?.status === OT_STATUS_REJECT && (
                                                            <div className="item__status-unChecked">
                                                                <span className="text">NG</span>
                                                                <img className="icon" src={unchecked_status_icon} alt="icon" />
                                                            </div>
                                                        )}
                                                        {item?.status === OT_STATUS_PENDING && (
                                                            <div className="item__status-pending">
                                                                <span className="text">未決</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                          );
                                      })
                                    : "Hello"} */}
                            </StyledOverTimePopoverList>
                        </Popover>
                    </div>
                    <Divider />
                    {loading ? (
                        <CircularLoader loading={loading} type="fullContent" />
                    ) : (
                        <div className="wrapperList">
                            {data?.length
                                ? data?.map((item, index) => {
                                      return (
                                          <div className="toggle__groupUser" key={index}>
                                              {/* <div className="toggle__groupUser-title">{item?.users?.[0]?.groups?.[0]?.name}</div> */}
                                              <div className="toggle__groupUser-title">{item?.name}</div>
                                              {/* <div className="toggle__groupUser-list">
                                                  {item?.users.map((user, id) =>
                                                      user?.overtimes.length ? (
                                                          <div key={id}>
                                                              <UserItem user={user} />
                                                              <Divider />
                                                          </div>
                                                      ) : (
                                                          ""
                                                      )
                                                  )}
                                              </div> */}
                                              <div className="toggle__groupUser-list">
                                                  {item?.overtimes.map((overtime, id) => {
                                                      return (
                                                          <div key={id}>
                                                              <UserItem overtime={overtime} />
                                                              <Divider />
                                                          </div>
                                                      );
                                                  })}
                                              </div>
                                          </div>
                                      );
                                  })
                                : ""}
                        </div>
                    )}
                </StyledOverTimeContentModal>
            </Box>
        );
    };

    return (
        <div>
            <React.Fragment key={anchor}>
                <Drawer anchor={anchor} open={openToogle} onClose={width > 768 ? handleCloseToggle : () => ""}>
                    {List(anchor)}
                </Drawer>
            </React.Fragment>
        </div>
    );
};
