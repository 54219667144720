import styled from "styled-components";

export const StyledChangePassword = styled.div`
    display: flex;
    height: 100vh;
    justify-content: center;
    max-height: 100vh;
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        background-color: #e5e5e5;
        align-items: center;
    }
    .forgotPassword__wrapper {
        text-align: left;
        padding: 40px 15px 0 15px; /*top right bottom left */
        background-color: ${(p) => p.theme.colors.white};
        max-height: 100%;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width: 400px;
            margin-top: 20rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            width: 808px;
            max-width: 808px;
            border-radius: 24px;
            margin-top: 0;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            padding: 0px 15px 0 15px;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            padding: 40px 15px 0 15px;
        }
    }
    .backToPrevious {
        &__text {
            font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            color: ${(p) => p.theme.colors.black};
        }
    }
    .fillForm {
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 20px 180px 40px 180px;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            padding: 0px 180px 10px 180px;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            padding: 20px 180px 80px 180px;
        }
        &__title {
            width: 56%;
            position: relative;
            z-index: 0;
            display: inline-block;
            margin-top: 20px;
            @media (min-width: ${(p) => p.theme.breakPoints.breakOnlyMobile}) {
                width: 98%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
                margin-top: 0px;
                width: 99%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
                width: 87%;
                margin-top: 40px;
            }
            .text {
                font-size: 32px;
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                color: ${(p) => p.theme.colors.black};
            }
            .line {
                position: absolute;
                width: 100%;
                z-index: -1;
                bottom: -8px;

                &__icon {
                    width: 100%;
                    height: 10px;
                }
            }
        }
        &__input {
            margin-top: 40px;
        }
        &__btn {
            margin-top: 35px;
        }
    }
    .forgotPassword__title {
        width: 56%;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            width: 100%;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
            width: 90%;
        }
    }
`;
