import React from "react";
import { InstrumentsView } from "@components";

export const Instruments = () => {
    return (
        <div>
            <InstrumentsView />
        </div>
    );
};
