import { Box, Button, IconButton, Popover, TextField } from "@mui/material";
import * as React from "react";
import { ja } from "date-fns/locale";
import { DateRange, DateRangeProps, Calendar } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import date_picker_icon from "@assets/Icons/date_picker_icon.svg";
import { StyledAppRangeDatePicker } from ".";
import { addDays, subDays } from "date-fns";
import { BUTTON_SAVE } from "@configs";

export interface IDateRangeCustomProps {
    helperText?: string;
    calendarMode?: boolean;
    label?: string;
    error?: boolean;
    value?: any;
    date?: Date;
    onChange?: (dateRange) => void;
    dateRangeProps?: Partial<DateRangeProps>;
    name?: string;
    startText?: string;
    endText?: string;
    minDate?: any;
    dateFormat?: any;
    showTimeSelect?: boolean;
    disabled?: boolean;
}

export const AppDateRangePicker = (props: IDateRangeCustomProps) => {
    const _KEY = "selection";
    const {
        label,
        value,
        dateRangeProps,
        date,
        calendarMode,
        name,
        onChange,
        startText = "開始日",
        endText = "終了日",
    } = props;
    // component state
    const [isShowDatePicker, setShowDatePicker] = React.useState(false);
    const anchorRefElement = React.useRef<HTMLDivElement>(null);
    const onClosePopover = React.useCallback(() => {
        setShowDatePicker(false);
    }, []);
    const dateRangeValue = React.useMemo(() => {
        const { startDate, endDate } = value;
        return {
            startDate: startDate,
            endDate: endDate && addDays(new Date(), 7),
            key: _KEY,
        };
    }, [value?.startDate, value?.endDate]);
    const handleOnBlur = React.useCallback(() => {
        setShowDatePicker((current) => !current);
    }, []);
    const handleChangeDate = React.useCallback(
        (event) => {
            setShowDatePicker(false);
            if (onChange) {
                onChange(event);
            }
        },
        [onChange]
    );
    const [state, setState] = React.useState([
        {
            startDate: value.startDate ? new Date(value?.startDate) : subDays(new Date(), 7),
            endDate: value.endDate ? new Date(value?.endDate) : addDays(new Date(), 1),
            key: "selection",
        },
    ]);

    const handleOnChange = (ranges) => {
        const { selection } = ranges;
        onChange && onChange(selection);
        setState([selection]);
    };
    const handleChangeDateRange = React.useCallback(
        (ranges: any) => {
            // const minDate = dateRangeProps?.minDate;
            // const isStartDateInValid =
            //     minDate && moment(ranges[_KEY].startDate).isBefore(minDate as unknown as string);
            const startDateSelection = ranges[_KEY].startDate;
            if (onChange) {
                const startDate = startDateSelection;
                const endDate = ranges[_KEY].endDate;
                onChange({
                    startDate,
                    endDate,
                });
            }
        },
        [onChange, dateRangeProps]
    );
    const controlComponent = React.useMemo(() => {
        if (calendarMode) {
            return <Calendar onChange={handleChangeDate} date={date ?? new Date()} locale={ja} />;
        }
        return (
            <DateRange
                editableDateInputs
                //
                ranges={state}
                moveRangeOnFirstSelection={false}
                // ranges={state}
                onChange={handleOnChange}
                {...dateRangeProps}
                locale={ja}
                // showSelectionPreview={true}
            />
        );
    }, [
        calendarMode,
        dateRangeValue,
        handleChangeDateRange,
        dateRangeProps,
        handleChangeDate,
        date,
        ja,
    ]);
    return (
        <StyledAppRangeDatePicker>
            <div className="wrapperDateRange">
                <Box>
                    <label className="label">{startText}</label>
                    <TextField
                        ref={anchorRefElement}
                        disabled={isShowDatePicker}
                        value={moment(value.startDate).format("YYYY-MM-DD")}
                        label={label}
                        size="small"
                        onClick={handleOnBlur}
                        name={name}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <IconButton>
                                    <img src={date_picker_icon} alt="icon" />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
                <Box>
                    <label className="label">{endText}</label>
                    <TextField
                        ref={anchorRefElement}
                        // disabled={isShowDatePicker}
                        value={moment(value.endDate).format("YYYY-MM-DD")}
                        label={label}
                        size="small"
                        // onClick={handleOnBlur}
                        name={name}
                        variant="outlined"
                        disabled={true}
                        InputProps={{
                            endAdornment: (
                                <IconButton disabled={true}>
                                    <img src={date_picker_icon} alt="icon" />
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
            </div>
            <Popover
                open={isShowDatePicker}
                anchorEl={anchorRefElement.current}
                onClose={onClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {controlComponent}
                <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Button
                        style={{
                            padding: "1rem 3rem",
                            backgroundColor: "#43CDEC",
                            borderRadius: "0.7rem",
                            fontSize: "1.6rem",
                            marginBottom: "1rem",
                        }}
                        onClick={onClosePopover}
                    >
                        {BUTTON_SAVE}
                    </Button>
                </Box>
            </Popover>
        </StyledAppRangeDatePicker>
    );
};
