import { Avatar, Box } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import React, { Children, cloneElement } from "react";
import "moment/locale/ja";
import { selectAuth, useAppSelector } from "@redux";
import Button from "@mui/material/Button";
import { AppIconButton } from "@components";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";

import small_arrow_left from "@assets/Icons/arrow/small_arrow_left.svg";
import small_arrow_right from "@assets/Icons/arrow/small_arrow_right.svg";
import { StyledAppCalendar, StyledToolTipCalendar } from ".";
import { checkListDateContainsPreviousDate, compareDateToCurrentDate, convertCalendarData, dateListMoment, linkImage } from "@utils";

const localizer = momentLocalizer(moment);

interface IAppCalendar {
    setSelected?: any;
    events?: any;
    listEventsDate?: any;
    handleOpenEditModal: (values: any) => void;
    setIsEditable?: any;
}
const TouchCellWrapper = ({ children, value, onSelectSlot }) =>
    cloneElement(Children.only(children), {
        onTouchEnd: () => onSelectSlot({ action: "click", slots: [value] }),
        style: {
            className: `${children}`,
        },
    });
export const AppCalendar = (props: IAppCalendar) => {
    const { setSelected, events = [], handleOpenEditModal, setIsEditable } = props;

    // redux store
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    // component state
    const [editEventId, setEditEventId] = React.useState<any>(undefined);
    // hooks
    const [infoSlot, setInfoSlot] = React.useState<any>();
    const arraySelectd = infoSlot?.map((item) => moment(item).format("YYYY-MM-DD"));
    const event = convertCalendarData(events, userInfo?.id);
    let index = -1;

    const onSelectSlot = ({ slots }) => {
        if (compareDateToCurrentDate(slots[0]) >= 0) {
            const slotsConvertToString = slots.map((e) => moment(e).format("YYYY-MM-DD"));
            index = event.findIndex((e) => slotsConvertToString.some((s) => dateListMoment(e.startDate, e.endDate).includes(s)));

            if (index >= 0) {
                if (userInfo?.id === event[index]?.user?.id) {
                    setEditEventId(event[index]?.borrowingId);
                    setSelected([...dateListMoment(event[index].startDate, event[index].endDate), ...slots]);
                    setInfoSlot([...dateListMoment(event[index].startDate, event[index].endDate), ...slots]);
                    handleOpenEditModal({
                        events: event[index],
                        selectedDate: [...dateListMoment(event[index].startDate, event[index].endDate), ...slots],
                    });
                    setIsEditable(event[index]?.borrowingId ? true : false);
                } else {
                    setSelected(undefined);
                    setInfoSlot(undefined);
                }
            } else {
                setSelected(slots);
                setInfoSlot(slots);
                setEditEventId(undefined);
                setIsEditable(false);
            }
        }
    };
    const customDayPropGetter = (date: Date) => {
        if (arraySelectd && arraySelectd.includes(moment(date).format("YYYY-MM-DD")) && checkListDateContainsPreviousDate(arraySelectd)) {
            return {
                className: "special-day",
            };
        } else return {};
    };
    return (
        <StyledAppCalendar>
            <Calendar
                localizer={localizer}
                style={{ height: "100%" }}
                events={event}
                startAccessor="startDate"
                endAccessor={"endDate"}
                components={{
                    event: (event) => <Event editEventId={editEventId} event={event} />,
                    toolbar: CustomToolbar,
                    month: {
                        dateHeader: CustomDateHeader,
                    },
                    dateCellWrapper: (props) => <TouchCellWrapper children={undefined} value={undefined} {...props} onSelectSlot={onSelectSlot} />,
                }}
                selectable
                onSelectSlot={onSelectSlot}
                views={["month"]}
                dayPropGetter={customDayPropGetter}
            />
        </StyledAppCalendar>
    );
};
const CustomToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
        toolbar.onNavigate("prev");
    };

    const goToNext = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        toolbar.onNavigate("next");
    };

    const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.onNavigate("current");
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span>
                <b>{date.format("MMMM")}</b>
                <span> {date.format("YYYY")}</span>
            </span>
        );
    };

    return (
        <div className="toolbar-container">
            <label className="label-date">{label()}</label>

            <div className="back-next-buttons">
                <div className="btn-back">
                    <AppIconButton onClick={goToBack} icon={<img src={small_arrow_left} alt="<" />} />
                </div>
                <label className="btn-current" onClick={goToCurrent}>
                    {toolbar.label}
                </label>
                <div className="btn-next">
                    <AppIconButton onClick={goToNext} icon={<img src={small_arrow_right} alt=">" />} />
                </div>
            </div>
        </div>
    );
};
function CustomDateHeader({ label }) {
    return (
        <span>
            <a>{label}</a>
        </span>
    );
}
function Event(props: any) {
    const { event, editEventId } = props;
    // redux store
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    const [showTooltip, setShowTooltip] = React.useState(false);
    const closeTooltip = () => {
        setShowTooltip(false);
    };
    const openTooltip = () => {
        setShowTooltip(true);
    };
    const ref = React.useRef(null);
    return (
        <Box
            className="event-background"
            sx={{
                width: "100%",
                height: "100%",
                backgroundColor: editEventId === event.event?.borrowingId ? "transparent" : "#D5D5D5",
            }}
            ref={ref}
        >
            {userInfo?.id === event.event.user.id ? (
                compareDateToCurrentDate(event.event.endDate) ? (
                    <Button
                        sx={{
                            backgroundColor: editEventId === event.event?.borrowingId ? "transparent" : "blue",
                            opacity: "0.2",
                        }}
                    ></Button>
                ) : (
                    <Button
                        sx={{
                            backgroundColor: editEventId === event.event?.borrowingId ? "transparent" : "blue",
                            opacity: "0.1",
                        }}
                    ></Button>
                )
            ) : (
                <Tooltip
                    open={showTooltip}
                    onClose={closeTooltip}
                    onOpen={openTooltip}
                    title={
                        <StyledToolTipCalendar>
                            <Avatar src={linkImage(event.event.user.avatar?.path)} />
                            <p className="title">
                                {event.event.user?.last_name}
                                {event.event.user?.first_name}
                            </p>
                        </StyledToolTipCalendar>
                    }
                    arrow
                >
                    <Button></Button>
                </Tooltip>
            )}
        </Box>
    );
}
