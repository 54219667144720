import { theme } from "@utils";
import { IIconProps } from ".";

export const GetInIcon = (props: IIconProps) => {
    const { color = theme.colors.darkBlack } = props;
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.73328 12.2333L11.8666 10.0999L9.73328 7.96661"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.33337 10.1L11.8084 10.1"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.15 3.33329C13.8334 3.33329 16.8167 5.83329 16.8167 9.99996C16.8167 14.1666 13.8334 16.6666 10.15 16.6666"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
