import styled from "styled-components";
interface IStyledAppSnackbar {
    messageType?: "error" | "info" | "warning" | "success";
}
export const StyledAppSnackbar = styled.div<IStyledAppSnackbar>`
    .MuiAlert-root {
        border-radius: 1.6rem;
        padding: 0;
        overflow: hidden;
        background-color: ${(p) => {
        if (p.messageType === "warning") return "#FFE4CC";
        else if (p.messageType === "error") return "#FFCCCC";
        else if (p.messageType === "info") return "#CCE4FF;";
        else if (p.messageType === "success") return "#CCFFE0;";
    }} !important;
    }
    .MuiAlert-icon {
        padding-left: 1rem;
        margin-right: 0;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            margin-right: 1rem;
        }
        img {
            width: 4rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                width: 5rem;
            }
        }
    }
    .MuiAlert-message {
        display: flex;
        align-items: center;
        text-align: left;
        max-width: 21rem;
        padding: 0.5rem;
    }
    .message {
        padding: 0.2rem 1rem;
        &__title {
            font-size: 1.8rem;
            font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            color: ${(p) => {
        if (p.messageType === "warning") return "#FF9D43";
        else if (p.messageType === "error") return "#FF4343";
        else if (p.messageType === "success") return "#20E06D";
        else if (p.messageType === "info") return "#4399FF";
    }} !important;
        }
        &__subtitle {
            font-size: 1.1rem;
            color: ${(p) => {
        if (p.messageType === "warning") return "#663C00";
        else if (p.messageType === "error") return "#FF4343";
        else if (p.messageType === "success") return "#006629";
        else if (p.messageType === "info") return "#003066";
    }} !important;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                font-size: 1.4rem;
            }
        }
    }
    .MuiAlert-action {
        display: flex;
        align-items: center;
        padding: 0;
        padding-left: 5px;
        padding-right: 10px;
        background-color: rgba(0, 0, 0, 0.08);
        .MuiSvgIcon-root {
            width: 2em;
            height: 2rem;
        }
    }
`;
