export enum NotificationTypeEnums{
    TYPE_INSTRUMENT = 1,
    TYPE_VEHICLE=2,
    TYPE_REQUEST_OT=3,
    TYPE_REQUEST_TIMESHEET=4
}
export enum NotificationLinkEnums{
    RETURN_VEHICLE = "return-schedule",
    REQUEST_OT = "overtime"
}
export enum FilterNotificationEnums{
    ENUMS_FILTER_ALL = "全て",
    ENUMS_FILTER_UNREAD = "未読",
    ENUMS_FILTER_READ = "読んだ"
}