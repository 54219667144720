import * as React from "react";
import { WorkTypeNameEnums } from "@enums";
import { useHistory, useParams } from "react-router-dom";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";

import { StyledTimeSheetDetail } from ".";
import { AppTopBar, AppIconButton, TimeSheetUserInfo, CircularLoader, OTApprovalProcess } from "..";
import { Box } from "@mui/system";
import { ClockIcon } from "@assets";
import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
// import { convertMinutesToHourAndMinute } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { notiApi, timesheetApi } from "@api";
import { selectNoti, updateNumberNoti, updateSnackBar } from "@redux";
import { LABEL_CONTRACTORS, LABEL_INSPECTOR, LABEL_LIVELET_EMPLOYEE, LABEL_START_TIME, LABEL_STOP_TIME, OT_STATUS_OK, OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import checked_status_icon from "@assets/Icons/OT/checked_status_icon.svg";
import unchecked_status_icon from "@assets/Icons/OT/unchecked_status_icon.svg";

export const TimeSheetDetailNightShift = () => {
    // components state
    const [loading, setLoading] = React.useState<boolean>(false);
    const [weatherCondition, setWeatherCondition] = React.useState<any>();
    const [data, setData] = React.useState<any>();
    const [optionSiteContractor, setOptionSiteContractor] = React.useState<any>();
    const [reloadPage, setReloadPage] = React.useState<boolean>(false);

    const notifications = useSelector(selectNoti);
    const urlParams = new URLSearchParams(window.location.search);
    const idNoti = Number(urlParams?.get("noti"));
    const statusNoti = Number(urlParams?.get("status"));
    const [check, setCheck] = React.useState<any>();

    const weatherId1 = data?.items[0]?.TimesheetItemConstruction?.map((item) => item.weather_id);
    const matchIdWeather1 = weatherCondition?.map((weather) => {
        return weather;
    });
    const getIdWeather1 = matchIdWeather1?.filter(function (item) {
        return weatherId1?.includes(item.id);
    });

    const functionGetIdDay = (index) => {
        const getIdContractor1 = data?.items[0]?.TimesheetItemConstruction[index]?.contractor_timesheet_item.map((item) => item.contractor_id);
        const getListNameContractor = optionSiteContractor?.data?.contractors.map((item) => item);

        const a = getListNameContractor?.filter(function (item) {
            return getIdContractor1?.includes(item.id);
        });
        return a;
    };
    const functionGetEmployeeDay = (index) => {
        const getEmployees = data?.items[0]?.TimesheetItemConstruction[index]?.employee_timesheet_item.map((item) => item);
        return getEmployees;
    };
    // hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: any }>();

    // const idSelectType = converUrlToGetId(window.location.href);
    React.useEffect(() => {
        fetchApiDetail();
    }, []);
    React.useEffect(() => {
        weatherApi();
    }, []);
    React.useEffect(() => {
        if (!check && statusNoti === 0) {
            handleOnSelectEmployeeNoti();
        }
        fetchApiDetailTimeSheet();
    }, [id, reloadPage]);

    // fetch api
    const fetchApiDetailTimeSheet = async () => {
        setLoading(true);
        try {
            const response = await timesheetApi.viewTimesheetDetail(id);
            setCheck(response.data?.checkConfirm);
            setData(response.data);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response?.data?.message,
                })
            );
        }
    };

    // match id
    const fetchApiDetail = async () => {
        setLoading(true);
        try {
            const listContractor = await timesheetApi.getListContractors();
            setOptionSiteContractor(listContractor?.data);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
        }
    };

    // API weather-id
    const weatherApi = async () => {
        setLoading(true);
        try {
            const result = await timesheetApi.getListWeathers();
            setWeatherCondition(result?.data?.data);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
        }
    };

    // fetch api approval status
    const fetchApprovalStatusTimesheet = async (values: any, resetForm: any) => {
        setLoading(true);
        try {
            await timesheetApi.confirmApprovalStatus({
                timesheet_id: id,
                status: values.radioValue,
                comment: values.inputValue || "",
            });
            setLoading(false);
            setReloadPage(!reloadPage);
            resetForm();
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.data,
                })
            );
        }
    };
    // events
    const handleOnSelectEmployeeNoti = async () => {
        const res = await notiApi.updateStatusNotification(idNoti);
        if (res.status === 200) {
            dispatch(updateNumberNoti(notifications.numberNoti === 0 ? notifications.numberNoti : notifications.numberNoti - 1));
        }
    };
    // const handleOnSelectManagerNoti = async () => {
    //     const res = await notiApi.updateStatusNotification(idNoti);
    //     if (res.status === 200) {
    //         dispatch(updateNumberNoti(notifications.numberNoti === 0 ? notifications.numberNoti : notifications.numberNoti - 1));
    //         history.push(`${appRouteEnums.PATH_TIMESHEET}`);
    //     }
    // };
    const handleSubmitConfirmApprovalStatus = (values: any, resetForm: any) => {
        // handleOnSelectManagerNoti();
        fetchApprovalStatusTimesheet(values, resetForm);
    };

    const dataDay = data?.items?.map((item) => {
        return item;
    })[0];
    //check work type day
    const functionCheckWorkTypeDay1 = (index, i) => {
        const listAllWorkType = dataDay?.TimesheetItemConstruction[index].contractor_timesheet_item[i].work_types;
        return listAllWorkType
            ?.filter((item) => item?.type === 1)
            ?.map((item) => item.name)
            .join(", ");
    };
    const functionCheckWorkTypeDay2 = (index, i) => {
        const listAllWorkType = dataDay?.TimesheetItemConstruction[index].contractor_timesheet_item[i].work_types;
        return listAllWorkType
            ?.filter((item) => item?.type === 2)
            ?.map((item) => item.name)
            .join(", ");
    };
    const functionCheckWorkTypeDay3 = (index, i) => {
        const listAllWorkType = dataDay?.TimesheetItemConstruction[index].contractor_timesheet_item[i].work_types;
        return listAllWorkType
            ?.filter((item) => item?.type === 3)
            ?.map((item) => item.name)
            .join(", ");
    };
    return (
        <StyledTimeSheetDetail>
            <AppTopBar />
            <CircularLoader loading={loading} />
            <div className="detail">
                <div className="detail__top">
                    <p className="detail__top-icon" onClick={() => history.goBack()}>
                        <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                    </p>
                    <p className="detail__top-title">申請項目</p>
                    <div className="detail__top-status">
                        {data?.status === OT_STATUS_OK && (
                            <div className="detail__top-checked">
                                <span className="text">OK</span>
                                <img className="icon" src={checked_status_icon} alt="icon" />
                            </div>
                        )}
                        {data?.status === OT_STATUS_PENDING && (
                            <div className="detail__top-pending">
                                <span className="text">未決</span>
                            </div>
                        )}
                        {data?.status === OT_STATUS_REJECT && (
                            <div className="detail__top-unChecked">
                                <span className="text">NG</span>
                                <img className="icon" src={unchecked_status_icon} alt="icon" />
                            </div>
                        )}
                    </div>
                </div>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <div className="detail__content">
                        {/* {!!data?.checkComfirm?.length && ( */}
                        <div className="boxWrapper">
                            <OTApprovalProcess showForm={data?.checkConfirm} data={data} handleOnSubmit={handleSubmitConfirmApprovalStatus} />
                        </div>
                        {/* )} */}

                        <div className="boxWrapper">
                            <TimeSheetUserInfo type={data?.type} last_name={data?.user?.last_name} first_name={data?.user?.first_name} date={data?.date} created_at={data?.created_at} isDetail />
                            <div className="row">
                                <label className="label">グループ</label>
                                <p className="main">{data?.group?.name}</p>
                            </div>
                        </div>

                        {/* Minh code */}
                        <div className="boxWrapper">
                            {/* <h3>{item?.item_type === 0 ? TEXT_DAY_SHIFT : TEXT_NIGHT_SHIFT}</h3> */}
                            <div className="rowContent">
                                <label className="label">アルコールチェック</label>
                                <RadioGroup>{dataDay?.alcohol_check === 1 ? <FormControlLabel disabled control={<Radio checked={true} />} label="はい" /> : <FormControlLabel disabled control={<Radio checked={false} />} label="はい" />}</RadioGroup>
                            </div>
                            <div className="rowContent">
                                <label className="label">日付</label>
                                <p className="main">{dataDay?.date}</p>
                            </div>
                            <div className="rowContent">
                                <div className="time">
                                    <div>
                                        <label className="label">出勤時</label>
                                        <div className="startTime">
                                            <span className="time__icon">
                                                <ClockIcon />
                                            </span>
                                            <span className="main">{dataDay?.start_time_check_alcohol}</span>
                                        </div>
                                        <div className="main">{dataDay?.start_capacity_alcohol} mg</div>
                                    </div>
                                    <div>
                                        <label className="label">退勤時</label>
                                        <div className="startTime">
                                            <span className="time__icon">
                                                <ClockIcon />
                                            </span>
                                            <span className="main">{dataDay?.end_time_check_alcohol}</span>
                                        </div>
                                        <div className="main">{dataDay?.end_capacity_alcohol} mg</div>
                                    </div>
                                </div>
                            </div>
                            <div className="rowContent">
                                <label className="label">{LABEL_INSPECTOR}</label>
                                <p className="main">
                                    {dataDay?.inspector?.last_name}
                                    {dataDay?.inspector?.first_name}
                                </p>
                            </div>
                            <div className="rowContent">
                                <label className="label">運転者</label>
                                <p className="main">
                                    {dataDay?.driver?.last_name}
                                    {dataDay?.driver?.first_name}
                                </p>
                            </div>
                            <div className="rowContent">
                                <label className="label">車両名</label>
                                <p className="main">{dataDay?.vehicle?.name}</p>
                            </div>
                            <div className="rowContent">
                                <label className="label">アルコールチェックに使用するデバイス</label>
                                <p className="main">{dataDay?.device_check}</p>
                            </div>
                            {data?.items[0]?.TimesheetItemConstruction?.map((item, index) => {
                                const contractorNames = item?.contractor_timesheet_item.map((singleItem) => {
                                    return singleItem?.contractor_name;
                                });
                                return (
                                    <div key={index}>
                                        <Divider style={{ backgroundColor: "black", height: "3px" }} />
                                        <div className="rowContent">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    fontSize: "16px",
                                                    fontWeight: "900",
                                                }}
                                            >
                                                <label className="label">現場名:</label>
                                                <p
                                                    className="main"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        paddingLeft: "5px",
                                                    }}
                                                >
                                                    <span>{item?.construction?.name}</span>
                                                </p>
                                            </div>

                                            <div className="time">
                                                <div>
                                                    <label className="label">{LABEL_START_TIME}</label>
                                                    <div className="startTime">
                                                        <span className="time__icon">
                                                            <ClockIcon />
                                                        </span>
                                                        <span className="main">{dataDay?.TimesheetItemConstruction[index]?.start_at}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className="label">{LABEL_STOP_TIME}</label>
                                                    <div className="startTime">
                                                        <span className="time__icon">
                                                            <ClockIcon />
                                                        </span>
                                                        <span className="main">{dataDay?.TimesheetItemConstruction[index]?.stop_at}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rowContent">
                                            <label className="label">休憩 (hour)</label>
                                            <p className="main">{dataDay?.TimesheetItemConstruction[index]?.break_time}</p>
                                        </div>
                                        <div className="rowContent">
                                            <label className="label">天気</label>
                                            <p className="main">{getIdWeather1[index]?.name}</p>
                                        </div>
                                        <div className="rowContent">
                                            <label className="label">メモ(報告事項)</label>
                                            <p className="main">
                                                <span className="main">{dataDay?.TimesheetItemConstruction[index]?.memo}</span>
                                            </p>
                                        </div>
                                        <div className="rowContent">
                                            <label className="label">{LABEL_LIVELET_EMPLOYEE}: </label>
                                            <p className="main">
                                                {functionGetEmployeeDay(index)?.map((items, i, array) => {
                                                    const isLastItem = i === array.length - 1;
                                                    const userFullName = items?.user_id === 0 ? items.user_name : `${items?.user?.last_name} ${items?.user?.first_name}`;
                                                    return (
                                                        <span key={i}>
                                                            {userFullName}
                                                            {isLastItem ? "" : ", "}
                                                        </span>
                                                    );
                                                })}
                                            </p>
                                        </div>
                                        {functionGetEmployeeDay(index)?.map((items, i, array) => {
                                            const isLastItem = i === array.length - 1;
                                            return (
                                                <div className="rowContent">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <label className="label">スタッフへの注意事項:</label>
                                                        <p
                                                            className="main"
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                paddingLeft: "5px",
                                                            }}
                                                        >
                                                            <span>{items?.user_id === 0 ? items.user_name : `${items?.user?.last_name} ${items?.user?.first_name}`}</span>
                                                        </p>
                                                    </div>
                                                    <label className="label">メモ:</label>
                                                    <p className="main">
                                                        <span>{items.memo}</span>
                                                    </p>
                                                    {!isLastItem && <Divider style={{ marginTop: "5px" }} />}
                                                </div>
                                            );
                                        })}
                                        {functionGetIdDay(index)?.map((items, i) => {
                                            return (
                                                <div key={i}>
                                                    <div
                                                        className="dotted-line"
                                                        style={{
                                                            border: "none",
                                                            borderTop: "3px dotted #000",
                                                        }}
                                                    ></div>
                                                    <div className="rowContent">
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <label className="label">{LABEL_CONTRACTORS}:</label>
                                                            <p
                                                                className="main"
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    paddingLeft: "5px",
                                                                }}
                                                            >
                                                                <span>{contractorNames[i]}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="rowContent">
                                                        <label className="label">人数</label>
                                                        <p className="main">
                                                            <span>{dataDay?.TimesheetItemConstruction[index]?.contractor_timesheet_item[i]?.number_of_people}</span>
                                                        </p>
                                                    </div>
                                                    {
                                                        <>
                                                            <div className="rowContent">
                                                                <label className="label">{WorkTypeNameEnums.TYPE_1}</label>
                                                                <p className="main">
                                                                    <span>{functionCheckWorkTypeDay1(index, i)}</span>
                                                                    {/* {item?.contractors?.map((item, index) => (
                                                        <span key={index}>{(item.work_types?.filter((item) => item.type === 1))?.map((item) => item.name + ", ")}</span>
                                                    ))} */}
                                                                </p>
                                                            </div>
                                                            <div className="rowContent">
                                                                <label className="label">{WorkTypeNameEnums.TYPE_2}</label>
                                                                <p className="main">
                                                                    <span>{functionCheckWorkTypeDay2(index, i)}</span>
                                                                    {/* {item?.contractors?.map((item, index) => (
                                                        <span key={index}>{(item.work_types?.filter((item) => item.type === 2))?.map((item) => item.name + ", ")}</span>
                                                    ))} */}
                                                                </p>
                                                            </div>
                                                            <div className="rowContent">
                                                                <label className="label">{WorkTypeNameEnums.TYPE_3}</label>
                                                                <p className="main">
                                                                    <span>{functionCheckWorkTypeDay3(index, i)}</span>

                                                                    {/* {item?.contractors?.map((item, index) => (
                                                        <span key={index}>{(item.work_types?.filter((item) => item.type === 3))?.map((item) => item.name + ", ")}</span>
                                                    ))} */}
                                                                </p>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Box>
            </div>
        </StyledTimeSheetDetail>
    );
};
