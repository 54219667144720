import { Avatar, Divider, Grid } from "@mui/material";
import React from "react";
import { Formik } from "formik";

import { StyledVehicleDetailInfo } from ".";
import {
    borrowingInstrumentSchema,
    formatDateOfBirth,
    linkImage,
    useWindowDimensions,
    useConstructionSites,
} from "@utils";
import { AppButton, AppDateRangePicker, AppCustomeSelect } from "..";
const queryString = require("query-string");
import moment from "moment";
import "moment-timezone";
import { timesheetApi } from "@api";
import { LABEL_CONSTRUCTIONSITE, PLACEHOLDER_CONSTRUCTIONSITE } from "@configs";
import { selectAuth, useAppSelector } from "@redux";
interface IProps {
    product?: any;
    selectedDate?: any;
    handleOnSubmit: (values: any) => void;
}

export const VehicleDetailInfoComponent = (props: IProps) => {
    const { product, selectedDate, handleOnSubmit } = props;
    const parsed = queryString.parse(location.search);

    const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();
    const { idConstuctionEmployeeSearch } = useAppSelector(selectAuth);

    // hooks
    const width = useWindowDimensions().width;
    const { updateConstructionHistory, sortConstructionSites } = useConstructionSites();
    // form value
    const initialValuesPackage = {
        condition: "",
        siteConstruction: "",
    };
    //component states
    const [dateRate, setDateRate] = React.useState<any>({
        startDate: new Date(),
        endDate: new Date(),
    });
    // fetch api
    const fetchListConstructions = async () => {
        try {
            const result = await timesheetApi.getListConstructions(idConstuctionEmployeeSearch);
            setOptionSiteConstructions(sortConstructionSites(result.data.data));
        } catch (err) {}
    };
    const handleChangeDateRange = (e) => {
        setDateRate({ startDate: e.startDate, endDate: e.endDate });
    };
    React.useEffect(() => {
        setDateRate({
            startDate: selectedDate && formatDateOfBirth(selectedDate[0]),
            endDate: selectedDate && formatDateOfBirth(selectedDate[selectedDate?.length - 1]),
        });
        fetchListConstructions();
    }, []);
    return (
        <StyledVehicleDetailInfo>
            <div className="VehicleDetailInfo">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className="info__detail">
                            <div className="info__wrapper">
                                <span className="info__title">画像</span>
                                <div className="info__imageWrapper">
                                    <img
                                        className="image"
                                        src={linkImage(product?.img?.path)}
                                        alt="detail product vehicle"
                                    />
                                </div>
                            </div>
                            <div className="info__wrapper">
                                {width < 980 && <Divider />}
                                {/* <div className="info__infoWrapper">
                                    <span className="info__title">予約時間</span>
                                    <span className="info__number">
                                        {!!product && !!product?.lastUpdateHistory ? (
                                            <>
                                                {moment(product.lastUpdateHistory).format(
                                                    "YYYY/MM/DD・HH:mm"
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </span>
                                </div>
                                <Divider /> */}
                                {/* <div className="info__infoWrapper">
                                    <span className="info__title">作成者</span>
                                    <span className="info__number">{product?.createdBy}</span>
                                </div> */}
                                {/* <Divider /> */}
                                <div className="info__infoWrapper">
                                    <span className="info__title">最終編集日時</span>
                                    <span className="info__number">
                                        {!!product?.lastUpdate &&
                                            moment(product?.lastUpdate).format("YYYY/MM/DD・HH:mm")}
                                    </span>
                                </div>
                                <Divider />
                                <div className="info__infoWrapper">
                                    <span className="info__title">車検</span>
                                    <span className="info__number">
                                        {!!product?.inspection_at &&
                                            moment(product?.inspection_at).format("YYYY/MM/DD")}
                                    </span>
                                </div>
                                <Divider />
                                <div className="info__infoWrapper">
                                    <span className="info__title">車検場所</span>
                                    <span className="info__number link">
                                        {product?.calibration_location}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {parsed.itemId ? (
                            <div className="info__user">
                                <div className="row">
                                    <Avatar src={product?.history[0]?.user?.avatar?.path} />
                                    <p style={{ paddingLeft: "1rem" }}>
                                        {product?.history[0]?.user?.last_name}
                                        {product?.history[0]?.user?.first_name}
                                    </p>
                                </div>
                                <div className="row">
                                    <p>現場名</p>
                                    <p style={{ paddingLeft: "1rem" }}>
                                        {product?.history[0]?.construction?.name}
                                    </p>
                                </div>
                                <div className="row">
                                    <p>持出</p>
                                    <p style={{ paddingLeft: "1rem" }}>
                                        {product?.history[0]?.from}
                                    </p>
                                </div>
                                <div className="row">
                                    <p>返却</p>
                                    <p style={{ paddingLeft: "1rem" }}>{product?.history[0]?.to}</p>
                                </div>
                            </div>
                        ) : (
                            <Formik
                                initialValues={initialValuesPackage}
                                onSubmit={(values) => {
                                    updateConstructionHistory(values?.siteConstruction);

                                    handleOnSubmit({
                                        startDate: moment(dateRate.startDate).format("YYYY-MM-DD"),
                                        endDate: moment(dateRate.endDate).format("YYYY-MM-DD"),
                                        quantity: 1,
                                        vehicleItemId: product.id,
                                        construction_id: values.siteConstruction,
                                    });
                                }}
                                validationSchema={borrowingInstrumentSchema}
                            >
                                {({ handleSubmit, setFieldValue }) => {
                                    return (
                                        <div className="info__form">
                                            <div style={{ paddingBottom: "1rem" }}>
                                                <p style={{ paddingBottom: "1rem" }}>
                                                    {LABEL_CONSTRUCTIONSITE}
                                                </p>
                                                {!!optionSiteConstructions && (
                                                    <AppCustomeSelect
                                                        placeholder={PLACEHOLDER_CONSTRUCTIONSITE}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                "siteConstruction",
                                                                e.value
                                                            );
                                                        }}
                                                        groupContructions={optionSiteConstructions}
                                                        options={
                                                            optionSiteConstructions?.constructions
                                                                ?.length &&
                                                            optionSiteConstructions?.constructions.map(
                                                                (item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                }
                                                            )
                                                        }
                                                        // value={values.siteConstruction}
                                                        name="siteConstruction"
                                                    />
                                                )}
                                            </div>
                                            <AppDateRangePicker
                                                value={{
                                                    startDate: dateRate?.startDate,
                                                    endDate: dateRate?.endDate,
                                                }}
                                                onChange={(e) => handleChangeDateRange(e)}
                                                startText="持出"
                                                endText="返却"
                                            />
                                            <div className="btn">
                                                <AppButton onClick={handleSubmit} text="決定" />
                                            </div>
                                        </div>
                                    );
                                }}
                            </Formik>
                        )}
                    </Grid>
                </Grid>
            </div>
        </StyledVehicleDetailInfo>
    );
};
