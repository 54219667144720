import { StyledAppButton } from "./styles";
import { Button } from "@mui/material";

type IAppButtonProps = {
    text?: string;
    icon?: any;
    onClick?: any;
    type?: "button" | "submit" | "reset" | undefined;
    disabled?: boolean;
    startIcon?: any;
    form?: any;
    bgColor?: string;
};
export const AppButton = (props: IAppButtonProps) => {
    // props
    const { text, onClick, disabled, icon, startIcon, type = "button", form, bgColor } = props;
    return (
        <StyledAppButton>
            <div className="btn__wrapper">
                <Button form={form} onClick={onClick} disabled={disabled} type={type} variant="outlined" style={{ backgroundColor: bgColor }}>
                    {startIcon}
                    <p className="btn__text">{text}</p>
                    {icon}
                </Button>
            </div>
        </StyledAppButton>
    );
};
