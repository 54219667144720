import { FieldArray, Formik } from "formik";
import React from "react";
import { Button, Divider } from "@mui/material";

import { PlusIcon } from "@assets";
import { IoIosClose } from "react-icons/io";
import { StyledNewFormWorkSchedule } from "..";
import { AppButton, AppDateRangePicker, AppCustomeSelect } from "../..";
import { timesheetApi, workScheduleApi } from "@api";
import moment from "moment";
import { RenderConstractorsFieldsForm, workScheduleClassificationForm } from "@utils";
import { LABEL_CONTRACTOR_AMOUNT } from "@configs";
import { WorkTypeNameEnums } from "@enums";
import { useParams } from "react-router-dom";

interface IProps {
    handleCloseNew?: (resetForm: any) => void;
    handleOnCreateSchedule?: (values: any, resetForm: any) => void;
    data?: any;
    listContructions?: any;
}

export const CreateClassificationForm = (props: IProps) => {
    const { handleCloseNew, handleOnCreateSchedule, data } = props;
    const formattedDate = new Date().toISOString().slice(0, 10);

    const { id } = useParams<{ id: any }>();

    //component states
    const [listWorkTypes, setListWorkTypes] = React.useState<any>();
    const [optionWorkTypes, setOptionWorkTypes] = React.useState<any>();
    const [optionConstractors, setOptionConstractors] = React.useState<any>();
    const [totalPeopleContructors, setTotalPeopleContructors] = React.useState<any>(0);
    const initialValuesPackage = {
        contractions: "",
        constructors: [],
        listConstractors: [],
        workType: [
            {
                type: 2,
                work_type_id: [],
                work_type_date: [
                    {
                        start_date: formattedDate,
                        end_date: formattedDate,
                    },
                ],
            },
        ],
    };
    React.useEffect(() => {
        fetchListWorkTypesApi();
        return () => {
            // clean up
        };
    }, []);
    React.useEffect(() => {
        fetchListConstractors();
        return () => {
            // clean up
        };
    }, []);
    React.useEffect(() => {
        fetchWorkTypeByType();
        return () => {
            // clean up
        };
    }, []);
    const fetchWorkTypeByType = async () => {
        try {
            const result = await workScheduleApi.getWorkTypeByType();
            setOptionWorkTypes(result?.data?.data);
            // setOptionConstractors(result.data.data);
        } catch (err) {}
    };
    // fetch api list contractors
    const fetchListConstractors = async () => {
        try {
            const result = await timesheetApi.getListContractors();
            setOptionConstractors(result.data.data);
        } catch (err) {}
    };
    // fetch api list Work types
    const fetchListWorkTypesApi = async () => {
        try {
            const response = await workScheduleApi.getListWorkTypes();
            setListWorkTypes(response.data.data);
        } catch (err: any) {}
    };
    // events

    const onChangeConstractors = (e, values, setFieldValue) => {
        if (!e?.length) {
            setTotalPeopleContructors(0);
        }
        const listConstractors = e.map((e, i) => {
            return {
                contractor_id: e.value,
                ...values.listConstractors[i],
            };
        });
        setFieldValue("listConstractors", listConstractors);
    };

    const convertDtoWorkType = (values: any) => {
        //const selectedWorkTypeId = values.workType[0]?.work_type_id[0];

        return {
            type: 2,
            //work_type_id: values?.workType.map(() => selectedWorkTypeId).flat(),
            //work_type_id: [selectedWorkTypeId],
            work_type_id: [values.workType[0]?.work_type_id[0]],
            work_type_date: values?.workType.map((item: any) => item?.work_type_date).flat(),
        };
    };

    const handleChangeNumberOfPeople = (e, fieldName, i, valuesForm, setFieldValue) => {
        const temp = [] as any;
        for (let index = 0; index < valuesForm?.length; index++) {
            if (i === index) {
                const value = parseFloat(e.target.value);
                temp.push(value ? value.toFixed(2) : 0);
            } else {
                const existingValue = parseFloat(valuesForm[index]?.number_of_people);
                temp.push(existingValue ? existingValue.toFixed(2) : 0);
            }
        }
        const total = temp.reduce((n, e) => (parseFloat(e) ? parseFloat(e) : 0) + n, 0);
        setTotalPeopleContructors(total);

        setFieldValue(fieldName, e.target.value);
    };
    return (
        <StyledNewFormWorkSchedule>
            <Formik
                initialValues={initialValuesPackage}
                enableReinitialize
                onSubmit={(values, { resetForm }) => {
                    if (values?.listConstractors.length !== 0) {
                        handleOnCreateSchedule?.(
                            {
                                contractors: values?.listConstractors,
                                work_type: convertDtoWorkType(values),
                                construction_id: id,
                                work_schedule_id: data?.work_schedule_id,
                                work_schedule_work_type_id: data?.id,
                                work_type_1_id: data?.work_type_id,
                            },
                            resetForm
                        );
                        setTotalPeopleContructors(0);
                    } else {
                        handleOnCreateSchedule?.(
                            {
                                // construction_id: data?.construction?.id || values.contractions,
                                work_type: convertDtoWorkType(values),
                                construction_id: data?.id,
                                work_schedule_id: data?.work_schedule_id,
                                work_schedule_work_type_id: data?.id,
                                work_type_1_id: data?.work_type_id,
                            },
                            resetForm
                        );
                        setTotalPeopleContructors(0);
                    }
                }}
                validationSchema={workScheduleClassificationForm}
            >
                {({ handleSubmit, values, touched, errors, setFieldValue, resetForm }) => {
                    return (
                        <div className="workSchedule__newForm">
                            <div className="workScheduleNewForm__select">
                                <p style={{ marginBottom: "8px" }} className="label">
                                    工種
                                </p>

                                <p style={{ marginBottom: "8px" }} className="label">
                                    {data?.work_type?.name}
                                </p>

                                {/* Type 2 */}
                                <FieldArray
                                    name="workType"
                                    render={({ insert }) => (
                                        <>
                                            <div>
                                                <label className="label">{WorkTypeNameEnums.TYPE_2}</label>
                                                <AppCustomeSelect
                                                    options={
                                                        !!optionWorkTypes?.type_2?.length &&
                                                        optionWorkTypes?.type_2?.map((item) => {
                                                            return {
                                                                label: item.name,
                                                                value: item.id.toString(),
                                                            };
                                                        })
                                                    }
                                                    className={""}
                                                    groupOptions={null}
                                                    name={`workType.work_type_id.${0}`}
                                                    // value={optionWorkTypes?.type_2
                                                    //     ?.filter((item) => (values.workType[0].work_type_id as any)?.includes(item?.id?.toString()))
                                                    //     .map((item) => ({
                                                    //         label: item.name,
                                                    //         value: item.id.toString(),
                                                    //     }))}
                                                    placeholder={WorkTypeNameEnums.TYPE_2}
                                                    onChange={(value) => {
                                                        setFieldValue(`workType.${0}`, {
                                                            type: 2,
                                                            work_type_id: [value.value],
                                                            work_type_date: [
                                                                {
                                                                    start_date: formattedDate,
                                                                    end_date: formattedDate,
                                                                    work_type_id: value.value,
                                                                },
                                                            ],
                                                        });
                                                    }}
                                                />

                                                {errors?.workType && touched?.workType ? <div className="error"> {(errors.workType[0] as any)?.work_type_id}</div> : null}
                                            </div>
                                            {values?.workType?.map((type2, type2Index) => (
                                                <div key={type2Index}>
                                                    {/* Type 2 Dates */}
                                                    <FieldArray
                                                        name={`workType.${type2Index}.work_type_date`}
                                                        render={({ remove }) => {
                                                            return (
                                                                <>
                                                                    {type2?.work_type_date?.map((_type2Date, type2DateIndex) => {
                                                                        return (
                                                                            <div key={`${type2Index}-${type2DateIndex}`}>
                                                                                <div
                                                                                    className="workScheduleNewForm__datePicker"
                                                                                    style={{
                                                                                        marginTop: "12px",
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            width: "100%",
                                                                                        }}
                                                                                    >
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                remove(type2DateIndex);
                                                                                            }}
                                                                                            style={{
                                                                                                border: "none",
                                                                                                backgroundColor: "#fff",
                                                                                                marginLeft: "auto",
                                                                                            }}
                                                                                        >
                                                                                            <IoIosClose size={25} color="#656565" />
                                                                                        </button>
                                                                                    </div>
                                                                                    <AppDateRangePicker
                                                                                        name={`workType.${type2Index}.work_type_date.${type2DateIndex}`}
                                                                                        value={{
                                                                                            startDate: (type2?.work_type_date[type2DateIndex] as any)?.start_date,

                                                                                            endDate: (type2?.work_type_date[type2DateIndex] as any)?.end_date,
                                                                                        }}
                                                                                        // error={errors?.workType1?.workType2?.[type2Index]?.workType2Date}
                                                                                        onChange={(e) => {
                                                                                            const id = type2?.work_type_id[type2DateIndex];
                                                                                            const newDates: any[] = [...(type2?.work_type_date ?? [])];
                                                                                            const newStartDate = moment(e?.startDate).format("YYYY-MM-DD");
                                                                                            const newEndDate = moment(e?.endDate).format("YYYY-MM-DD");
                                                                                            newDates[type2DateIndex] = {
                                                                                                work_type_id: id,
                                                                                                start_date: newStartDate,
                                                                                                end_date: newEndDate,
                                                                                            };

                                                                                            // const fieldValue = values.workType?.[type2Index].work_type_date?.[type2DateIndex] as any;

                                                                                            setFieldValue(`workType.${type2Index}.work_type_date.${type2DateIndex}`, newDates[type2DateIndex]);

                                                                                            // setTimeout(() => {
                                                                                            //     values?.workType1?.workType1Date?.forEach(({ startDate, endDate }) => {
                                                                                            //         const start1 = moment(startDate, "YYYY-MMM-DD").toDate();
                                                                                            //         const end1 = moment(endDate, "YYYY-MMM-DD").toDate();
                                                                                            //         const start2 = moment(newStartDate, "YYYY-MMM-DD").toDate() || fieldValue?.startDate;
                                                                                            //         const end2 = moment(newEndDate, "YYYY-MMM-DD").toDate() || fieldValue?.endDate;
                                                                                            //         if (
                                                                                            //             start2 &&
                                                                                            //             end2 &&
                                                                                            //             (start2.getTime() <= start1.getTime() ||
                                                                                            //                 start2.getTime() >= end1.getTime() ||
                                                                                            //                 end2.getTime() <= start1.getTime() ||
                                                                                            //                 end2.getTime() >= end1.getTime())
                                                                                            //         ) {
                                                                                            //             setFieldError(
                                                                                            //                 `workType1.workType2.${type2Index}.workType2Date.${type2DateIndex}`,
                                                                                            //                 "開始日及び終了日は職業ごとの期間内で無ければなりません!"
                                                                                            //             );
                                                                                            //         }
                                                                                            //     });
                                                                                            // }, 500);
                                                                                        }}
                                                                                        startText="開始"
                                                                                        endText="終了"
                                                                                    />
                                                                                    {(errors?.workType?.[type2Index] as any)?.work_type_date?.[type2DateIndex] ? (
                                                                                        <div className="error">{(errors?.workType?.[type2Index] as any)?.work_type_date?.[type2DateIndex]}</div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                            <div className="btn-addDate__container">
                                                <button
                                                    className="btn-addClass"
                                                    onClick={() =>
                                                        insert(values?.workType.length, {
                                                            type: 2,
                                                            work_type_id: [values?.workType[0]?.work_type_id[0]], // Consistently use the first work type ID
                                                            work_type_date: [
                                                                {
                                                                    start_date: formattedDate,
                                                                    end_date: formattedDate,
                                                                    work_type_id: values?.workType[0]?.work_type_id[0], // Give the work_type_id again here, not clear why it's needed twice
                                                                },
                                                            ],
                                                        })
                                                    }
                                                >
                                                    + 日付追加
                                                </button>
                                            </div>
                                        </>
                                    )}
                                />
                                <Divider style={{ margin: "2rem 0" }} />
                                <div className="select__contractor">
                                    <AppCustomeSelect
                                        groupOptions={optionConstractors}
                                        options={
                                            optionConstractors?.contractors?.length &&
                                            optionConstractors?.contractors?.map((item) => {
                                                return {
                                                    label: item.name,
                                                    value: item.id.toString(),
                                                };
                                            })
                                        }
                                        placeholder="select 請負業者"
                                        name="constructors"
                                        value={values.constructors}
                                        // noError={errors.constructors}
                                        onChange={(e) => {
                                            onChangeConstractors(e, values, setFieldValue);
                                            setFieldValue("constructors", e);
                                        }}
                                        isMulti
                                    />
                                    <p className="select__number">
                                        <span>{LABEL_CONTRACTOR_AMOUNT}</span>
                                        <span>{totalPeopleContructors}</span>
                                    </p>
                                </div>
                                {errors?.constructors && touched?.constructors ? <div className="error">{errors?.constructors}</div> : null}

                                <RenderConstractorsFieldsForm
                                    valuesForm={values.listConstractors}
                                    handleChangeNumberOfPeople={handleChangeNumberOfPeople}
                                    values={values.constructors.map((e: any) => e?.value)}
                                    options={listWorkTypes}
                                    typeOfConstractors="listConstractors"
                                    // touched={touched.constructors}
                                    // errors={errors.constructors}
                                    listConstractors={optionConstractors?.contractors}
                                    workSchedule
                                    setFieldValue={setFieldValue}
                                />
                                {errors?.listConstractors && touched?.listConstractors ? <div className="error">{(errors?.listConstractors?.[0] as any)?.number_of_people}</div> : null}
                            </div>

                            <div className="workScheduleNewForm__btn">
                                <AppButton text="追加" onClick={handleSubmit} startIcon={<PlusIcon />} />
                            </div>
                            <div className="workScheduleNewForm__btn cancel">
                                <Button
                                    onClick={() => {
                                        handleCloseNew?.(resetForm);
                                        setTotalPeopleContructors(0);
                                    }}
                                >
                                    キャンセル
                                </Button>
                            </div>
                        </div>
                    );
                }}
            </Formik>
        </StyledNewFormWorkSchedule>
    );
};
