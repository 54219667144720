import { menuApi } from "@api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { updateSnackBar } from "./app";

interface IState {
    show: boolean;
    isProcess: boolean;
    dataMenu: Array<IMenu> | null;
}
const initialState: IState = {
    show: false,
    isProcess: false,
    dataMenu: null,
};
interface IMenu {
    dispatch?: any;
}

export const getMenus = createAsyncThunk("menu/get-menu", async (params: IMenu) => {
    try {
        const res = await menuApi.getMenus();
        return res.data.data;
    } catch (err: any) {
        params.dispatch(
            updateSnackBar({
                messageType: "error",
                message: err.response.data.message,
            })
        );
        return err;
    }
});

const menuSlice = createSlice({
    name: "menu",
    initialState: initialState,

    reducers: {
        setMenu: (state) => {
            state.show = true;
        },
        resetMenu: (state) => {
            state.show = false;
        },
    },
    extraReducers: (builder) => {
        // change password
        builder.addCase(getMenus.pending, (state) => {
            state.isProcess = true;
        });
        builder.addCase(getMenus.fulfilled, (state, action: { payload: any }) => {
            state.isProcess = false;
            state.dataMenu = action.payload.menus;
        });
        builder.addCase(getMenus.rejected, (state) => {
            state.isProcess = false;
        });
    },
});

export const selectMenu = (state: RootState) => state.menu;
export const { setMenu, resetMenu } = menuSlice.actions;
export default menuSlice.reducer;
