import React from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Box } from "@mui/system";

import { appRouteEnums, menuTitleEnums } from "@enums";
import { timesheetApi } from "@api";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { StyledWorkScheduleScreen, WorkScheduleWrap } from ".";
import { AppTopBar, AppIconButton, CircularLoader } from "..";
import WorkScheduleListConstructionSite from "./WorkScheduleListConstructionSite";
import { useDispatch } from "react-redux";
import { selectPage, setPage, useAppSelector } from "@redux";

export const NewWorkScheduleScreen = () => {
    // hooks
    const history = useHistory();
    const dispatch = useDispatch();
    const page = useAppSelector(selectPage)?.page;

    // component states

    const [loading, setLoading] = React.useState(false);
    const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();

    React.useEffect(() => {
        fetchListConstructions();
        return () => {
            // do something with cleanup
        };
    }, []);

    const fetchListConstructions = async () => {
        setLoading(true);
        try {
            const result = await timesheetApi.getListConstructions();
            if (result?.data?.success) {
                setOptionSiteConstructions(result.data.data);
                setLoading(false);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <StyledWorkScheduleScreen>
            <AppTopBar />

            <CircularLoader loading={loading} />

            <div className="workSchedule">
                <div className="newOT">
                    <div className="newOT__top-icon" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
                        <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                    </div>
                    <div onClick={() => dispatch(setPage(1))} className={clsx(page === 1 ? "newOT__btn-1 active" : "newOT__btn-1")}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>{menuTitleEnums.TITLE_WORK_SCHEDULE_LIST_CONSTRUCTION}</Box>
                    </div>
                    <div onClick={() => dispatch(setPage(2))} className={clsx(page === 2 ? "newOT__btn-2 active" : "newOT__btn-2")}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>{menuTitleEnums.TITLE_WORK_SCHEDULE}</Box>
                    </div>
                </div>

                {page === 1 ? <WorkScheduleListConstructionSite options={optionSiteConstructions} /> : <WorkScheduleWrap />}
            </div>
        </StyledWorkScheduleScreen>
    );
};
