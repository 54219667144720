import React from "react";
import { IconButton, Avatar } from "@mui/material";

import { StyledInstrumentDetailHistory } from ".";
import arrow_increase from "@assets/Icons/arrow_increase.svg";
import arrow_decrease from "@assets/Icons/arrow_decrease.svg";
import { linkImage } from "@utils";
import default_avatar from "@assets/Images/default_avatar.jpg";
interface IProps {
    historyList?: any;
}
export const InstrumentDetialHistoryComponent = (props: IProps) => {
    const { historyList } = props;
    return (
        <StyledInstrumentDetailHistory>
            <div className="title">使用履歴</div>
            <div className="wrapper">
                {historyList &&
                    historyList?.borrowings?.map((item, index) => {
                        return (
                            <div className="HistoryInventory" key={index}>
                                <div className="wrapperImage">
                                    <Avatar
                                        className="image"
                                        src={
                                            item?.user?.avatar?.path
                                                ? linkImage(item?.user?.avatar?.path)
                                                : default_avatar
                                        }
                                    />
                                </div>
                                <div className="wrapperContent">
                                    <p className="name">
                                        {item?.user?.last_name}
                                        {item?.user?.first_name}
                                    </p>

                                    <p className="subTitle">
                                        <span className="amount">{item.from}~{item.to}</span>
                                    </p>
                                </div>
                                <div className="button">
                                    <div className="wrapperbtn">
                                        <span className="amount">-{item.quantity}</span>
                                        <span className="btn">
                                            <IconButton disableRipple={true}>
                                                <img src={arrow_decrease} alt="icon" />
                                            </IconButton>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                {historyList &&
                    historyList?.return_items?.map((item, index) => {
                        return (
                            <div className="HistoryInventory" key={index}>
                                <div className="wrapperImage">
                                    <img
                                        className="image"
                                        src={
                                            item?.user?.avatar?.path
                                                ? linkImage(item?.user?.avatar?.path)
                                                : default_avatar
                                        }
                                        alt="inventory history"
                                    />
                                </div>
                                <div className="wrapperContent">
                                    <p className="name">
                                        {item?.user?.last_name}
                                        {item?.user?.first_name}
                                    </p>
                                    <p className="subTitle">
                                        <span className="amount">{item.from}~{item.to}</span>
                                    </p>
                                </div>
                                <div className="button">
                                    <div className="wrapperbtn">
                                        <span className="amount">+{item.quantity}</span>
                                        <span className="btn">
                                            <IconButton disableRipple={true}>
                                                <img src={arrow_increase} alt="icon" />
                                            </IconButton>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                {historyList &&
                    historyList?.map((item, index) => {
                        return (
                            <div className="HistoryInventory" key={index}>
                                <div className="wrapperImage">
                                    <img
                                        className="image"
                                        src={
                                            item?.user?.avatar?.path
                                                ? linkImage(
                                                      //   "https://livelet.s3.amazonaws.com/" +
                                                      item?.user?.avatar?.path
                                                  )
                                                : default_avatar
                                        }
                                        alt="inventory history"
                                    />
                                </div>
                                <div className="wrapperContent">
                                    <p className="name">
                                        {item?.user?.last_name}
                                        {item?.user?.first_name}
                                    </p>
                                    <p className="constructionName">
                                        {item && item?.construction_name
                                            ? item?.construction_name
                                            : item?.construction
                                            ? item?.construction?.name
                                            : ""}
                                    </p>
                                    <p className="subTitle">
                                        <span className="amount">{item.from}~{item.to}</span>
                                    </p>
                                </div>
                                {/* <div className="button">
                                    {item?.typeCollection === 1 ? (
                                        <div className="wrapperbtn">
                                            <span className="amount">-{item.quantity}</span>
                                            <span className="btn">
                                                <IconButton disableRipple={true}>
                                                    <img src={arrow_decrease} alt="icon" />
                                                </IconButton>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="wrapperbtn">
                                            <span className="amount">+{item.quantity}</span>
                                            <span className="btn">
                                                <IconButton disableRipple={true}>
                                                    <img src={arrow_increase} alt="icon" />
                                                </IconButton>
                                            </span>
                                        </div>
                                    )}
                                </div> */}
                            </div>
                        );
                    })}
            </div>
        </StyledInstrumentDetailHistory>
    );
};
