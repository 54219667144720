import React from "react";
import { linkImage, useConstructionSites } from "@utils";

import checked_status_icon from "@assets/Icons/OT/checked_status_icon.svg";
import unchecked_status_icon from "@assets/Icons/OT/unchecked_status_icon.svg";
import { OT_STATUS_OK, OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import { Avatar } from "@mui/material";
import { StyledDaily } from "./styles";
import { TimeSheetFormTypeEnums, appRouteEnums } from "@enums";
import { selectAuth, setLoading, useAppSelector } from "@redux";
import { timesheetApi } from "@api";
import { useHistory } from "react-router-dom";

interface IProps {
    data?: any;
    handleViewDetail?: ({ otId, userId, status }) => void;
}
export const DailyViewTableComponent = (props: IProps) => {
    const [optionSiteConstructions, setOptionSiteConstructions] = React.useState<any>();
    // props
    const { data } = props;
    const user = useAppSelector(selectAuth);
    const { userInfo } = user;
    const history = useHistory();

    // hooks
    const { sortConstructionSites } = useConstructionSites();
    const handleShowTypeTimeSheet = (value) => {
        /**
         * 日報承認要求
         * 日勤 :0
         * 夜勤 :1
         * 日勤と夜勤 :2
         * 休日出勤 : 3
         * 有給休暇: 4
         * 半日出勤 :5
         * 欠勤 :6
         * 公休日: 7
         */
        switch (value) {
            case TimeSheetFormTypeEnums.ENUMS_TYPE_DAYSHIT:
                return "日勤";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_NIGHTSHIFT:
                return "夜勤";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_DATSHIFT_AND_NIGHTSHIFT:
                return "日勤からの夜勤";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_HOLIDAYWORK:
                return "休日出勤";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_PAID_TIME_OFF:
                return "有給休暇";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_HALFDAY_WORK:
                return "半日有給";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_ABSENCE:
                return "欠勤";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_LEGAL_HOLIDAY:
                return "公休日";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_NIGHTSHIFT_AND_DAYSHIFT:
                return "夜勤からの日勤";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_EARLY_LEAVE:
                return "早退";
            case TimeSheetFormTypeEnums.ENUMS_TYPE_TARDINESS:
                return "遅刻";
            default:
                return "";
        }
    };

    const fetchApiDetail = async () => {
        setLoading(true);
        try {
            const result = await timesheetApi.getListConstructions();
            setOptionSiteConstructions(sortConstructionSites(result?.data.data?.constructions));
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
        }
    };
    React.useEffect(() => {
        fetchApiDetail();
    }, []);

    const handleToTimesheet = (id, employee_id, type_timsheet) => {
        if (employee_id === userInfo?.id) {
            history.push(`${appRouteEnums.PATH_TIMESHEET}/edit/${type_timsheet}/${id}`);
        } else {
            history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${type_timsheet}/${id}`);
        }
    };

    return (
        <div>
            <StyledDaily className="check">
                {data &&
                    data?.map((item, index) => {
                        const numberContruction = item?.TimesheetItemConstruction?.map(
                            (item) => item.id
                        );

                        const contructionFill = item?.TimesheetItemConstruction?.map((item) => {
                            const getId = item?.construction_id;
                            return getId;
                        });

                        const constructionId =
                            numberContruction?.length < 2
                                ? item?.TimesheetItemConstruction?.[0]?.construction_id
                                : item?.TimesheetItemConstruction?.map(
                                      (item) => item?.construction_id
                                  );

                        // const constructionId = item?.TimesheetItemConstruction?.[0].construction_id;
                        const constructionsMatch =
                            optionSiteConstructions && numberContruction?.length < 2
                                ? optionSiteConstructions?.find(
                                      (construction) => construction?.id === constructionId
                                  )
                                : optionSiteConstructions?.find(
                                      (construction) => construction?.id === contructionFill?.[0]
                                  );

                        // const listWorkType = item?.TimesheetItemConstruction?.[0]?.contractor_timesheet_item
                        const numberOfPeople = item?.TimesheetItemConstruction?.map(
                            (item) => item?.contractor_timesheet_item[0]?.number_of_people
                        );

                        const getIdContruction = optionSiteConstructions?.map((item) => item);

                        const getItemById = getIdContruction?.filter(function (item) {
                            return contructionFill?.includes(item.id);
                        });
                        const contruction = getItemById?.map((item) => item.name);

                        return (
                            <div className="item" key={index}>
                                <div className="item__top">
                                    <div className="item__userInfo">
                                        <span className="item__userInfo-avatar">
                                            <Avatar src={linkImage(item?.user?.avatar?.path)} />
                                        </span>
                                        <span className="item__userInfo-title">
                                            <p className="name">
                                                {item?.user?.last_name +
                                                    " " +
                                                    item?.user?.first_name}
                                            </p>
                                        </span>
                                    </div>
                                    <div className="item__date desktop">
                                        <div className="item__date-start">
                                            <p className="title">日にち</p>
                                            <p className="content">{item?.date}</p>
                                        </div>
                                        <div className="item__title">
                                            <p className="title">日報種別</p>
                                            <div className="">
                                                {handleShowTypeTimeSheet(item?.type_timesheet)}
                                            </div>
                                        </div>
                                        <div className="item__title">
                                            <p className="title">現場名</p>
                                            <div className="">
                                                {constructionsMatch &&
                                                    contruction.map((item) => {
                                                        return <p>{item}</p>;
                                                    })}
                                            </div>
                                        </div>
                                        <div className="item__date-hour">
                                            <div className="item__work-type">
                                                <div className="work-type-item-title">
                                                    <p className="title">工種</p>
                                                </div>
                                                {item?.TimesheetItemConstruction &&
                                                    item?.TimesheetItemConstruction?.map((item) =>
                                                        item?.contractor_timesheet_item[0]?.work_types?.map(
                                                            (contractors, contractorsIndex) => {
                                                                return (
                                                                    <div
                                                                        key={contractorsIndex}
                                                                        className="work-type-item"
                                                                    >
                                                                        <p className="content work-type-content">
                                                                            {contractors.type ===
                                                                                1 &&
                                                                                contractors.name}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                    )}
                                            </div>
                                            <div className="item__work-type">
                                                <div className="work-type-item-title">
                                                    <p className="title">種別</p>
                                                </div>
                                                {item?.TimesheetItemConstruction &&
                                                    item?.TimesheetItemConstruction?.map((item) =>
                                                        item?.contractor_timesheet_item[0]?.work_types?.map(
                                                            (contractors, contractorsIndex) => {
                                                                return (
                                                                    <div
                                                                        key={contractorsIndex}
                                                                        className="work-type-item"
                                                                    >
                                                                        <p className="content work-type-content">
                                                                            {contractors.type ===
                                                                                2 &&
                                                                                contractors.name}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                    )}
                                            </div>
                                            <div className="item__work-type">
                                                <div className="work-type-item-title">
                                                    <p className="title">タイプ</p>
                                                </div>
                                                {item?.TimesheetItemConstruction &&
                                                    item?.TimesheetItemConstruction?.map((item) =>
                                                        item?.contractor_timesheet_item[0]?.work_types?.map(
                                                            (contractors, contractorsIndex) => {
                                                                return (
                                                                    <div
                                                                        key={contractorsIndex}
                                                                        className="work-type-item"
                                                                    >
                                                                        <p className="content work-type-content">
                                                                            {contractors.type ===
                                                                                3 &&
                                                                                contractors.name}
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }
                                                        )
                                                    )}
                                            </div>
                                        </div>

                                        <div className="item__date-number">
                                            <p className="title">総合計</p>
                                            {numberOfPeople?.map((item, i) => (
                                                <p className="content" key={i}>
                                                    {item}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="item__status">
                                        {item?.status === OT_STATUS_OK && (
                                            <div
                                                className="item__status-checked"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleToTimesheet(
                                                        item?.timesheet_id,
                                                        item?.user?.id,
                                                        item?.type_timesheet
                                                    )
                                                }
                                            >
                                                <span className="text">OK</span>
                                                <img
                                                    className="icon"
                                                    src={checked_status_icon}
                                                    alt="icon"
                                                />
                                            </div>
                                        )}
                                        {item?.status === OT_STATUS_REJECT && (
                                            <div
                                                className="item__status-unChecked"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleToTimesheet(
                                                        item?.timesheet_id,
                                                        item?.user?.id,
                                                        item?.type_timesheet
                                                    )
                                                }
                                            >
                                                <span className="text">NG</span>
                                                <img
                                                    className="icon"
                                                    src={unchecked_status_icon}
                                                    alt="icon"
                                                />
                                            </div>
                                        )}
                                        {item?.status === OT_STATUS_PENDING && (
                                            <div
                                                className="item__status-pending"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                    handleToTimesheet(
                                                        item?.timesheet_id,
                                                        item?.user?.id,
                                                        item?.type_timesheet
                                                    )
                                                }
                                            >
                                                <span className="text">未決</span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* </div> */}
                                {/* ))} */}
                            </div>
                        );
                    })}
            </StyledDaily>
        </div>
    );
};
