// export const timesheetType = [
//     {
//         // day shift
//         label: "日勤 ",
//         value: "0",
//     },
//     {
//         // night shift
//         label: "夜勤",
//         value: "1",
//     },
//     {
//         // Day shift and ngiht shift
//         label: "日勤と夜勤",
//         value: "2",
//     },
//     {
//         // Holiday Work
//         label: "休日出勤",
//         value: "3",
//     },
//     {
//         // Paid Time Off
//         label: "有給休暇",
//         value: "4",
//     },
//     {
//         // Half-day work
//         label: "半日出勤",
//         value: "5",
//     },
//     {
//         // Absence
//         label: "欠勤",
//         value: "6",
//     },
//     {
//         // Legal Holiday
//         label: "公休日",
//         value: "7",
//     },
// ];

export const timesheetType = [
    {
        // day shift
        label: "日勤 ",
        value: "0",
    },
    {
        // Holiday Work
        label: "休日出勤",
        value: "3",
    },
    {
        // night shift
        label: "夜勤",
        value: "1",
    },
    {
        // Day shift and ngiht shift
        label: "日勤からの夜勤",
        value: "2",
    },
    {
        // night shift and day shift
        label: "夜勤からの日勤",
        value: "8",
    },
    {
        // Half-day work
        label: "半日有給",
        value: "5",
    },
    {
        // behind time- tardiness
        label: "遅刻",
        value: "9",
    },
    {
        // leaving early - early leave
        label: "早退",
        value: "10",
    },

];

