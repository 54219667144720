import React, { cloneElement, Children } from "react";
// import { TouchBackend } from "react-dnd-touch-backend";
import styled from "styled-components";

// import { DndProvider } from "react-dnd";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import moment from "moment";
import { AppIconButton } from "..";
import small_arrow_right from "@assets/Icons/arrow/small_arrow_right.svg";
import small_arrow_left from "@assets/Icons/arrow/small_arrow_left.svg";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import {
    checkListDateContainsPreviousDate,
    checkSelectSlot,
    convertCalendarData,
    linkImage,
    validateResizeEvents,
} from "@utils";
import { Button, Tooltip, Avatar } from "@mui/material";
import { selectAuth, useAppSelector } from "@redux";
import { appRouteEnums, ItemTypeEnum } from "@enums";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

interface IAppDndCalendar {
    selected?: any;
    setSelected?: any;
    events?: any;
    listEventsDate?: any;
    handleOpenEditModal: (values: any) => void;
    setIsEditable?: any;
}
const TouchCellWrapper = ({ children, value, onSelectSlot }) =>
    cloneElement(Children.only(children), {
        onTouchEnd: () => {
            onSelectSlot({ action: "click", slots: [value] });
        },
        style: {
            className: `${children}`,
        },
    });
const Dnd = (props: IAppDndCalendar) => {
    // store prop
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    const { events, setSelected, listEventsDate = [], handleOpenEditModal, selected } = props;
    const [eventsData, setEventsData] = React.useState(convertCalendarData(events, userInfo?.id));
    const [infoSlot, setInfoSlot] = React.useState<any>();
    const arraySelectd = infoSlot?.map((item) => moment(item).format("YYYY-MM-DD"));
    const eventPropGetter = React.useCallback((event) => {
        return {
            ...(event.isDragable ? { className: "isDraggable" } : { className: "noneDraggable" }),
        };
    }, []);
    const resizeEvent = React.useCallback(
        ({ event, start, end }) => {
            setInfoSlot([]);
            setSelected([]);
            if (
                validateResizeEvents(event, end, start, eventsData)
                // !checkListMonthDate([start, end])
            ) {
                setEventsData(eventsData);
            } else {
                setEventsData((prev) => {
                    const existing = prev.find((ev) => ev.borrowingId === event.borrowingId) ?? {};
                    const filtered = prev.filter((ev) => ev.borrowingId !== event.borrowingId);
                    return [
                        ...filtered,
                        {
                            ...existing,
                            start,
                            end: moment(end).subtract({ hours: 0, minutes: 0, seconds: 59 }),
                        },
                    ];
                });
                handleOpenEditModal({ events: event, selectedDate: [start, end] });
            }
        },
        [setEventsData, eventsData]
    );
    const onSelectSlot = ({ slots }) => {
        if (
            slots
                ?.map((item) => moment(item).format("YYYY-MM-DD"))
                .some((r) => listEventsDate.includes(r)) ||
            !checkListDateContainsPreviousDate(slots)
            // !checkListMonthDate(slots)
        ) {
            setInfoSlot([]);
            setSelected([]);
        } else {
            if (checkSelectSlot(selected, slots)) {
                setInfoSlot([...selected, ...slots]);
                setSelected([...selected, ...slots]);
            } else {
                setInfoSlot([]);
                setSelected([]);
            }
        }
    };
    const customDayPropGetter = (date: Date) => {
        if (
            arraySelectd &&
            arraySelectd.includes(moment(date).format("YYYY-MM-DD")) &&
            checkListDateContainsPreviousDate(arraySelectd)
        ) {
            return {
                className: "special-day",
            };
        } else {
            return {};
        }
    };
    return (
        <StyledAppDragAndDropCalendar>
            <DragAndDropCalendar
                showMultiDayTimes={true}
                localizer={localizer}
                style={{ height: "100%" }}
                events={eventsData}
                resizable
                onEventResize={resizeEvent}
                resizableAccessor={(event: any) => event?.isDragable}
                components={{
                    event: (event) => <Event event={event} />,
                    toolbar: CustomToolbar,
                    month: {
                        dateHeader: CustomDateHeader,
                    },
                    dateCellWrapper: (props) => (
                        <TouchCellWrapper
                            children={undefined}
                            value={undefined}
                            {...props}
                            onSelectSlot={onSelectSlot}
                        />
                    ),
                }}
                selectable
                onSelectSlot={onSelectSlot}
                dayPropGetter={customDayPropGetter}
                eventPropGetter={eventPropGetter}
            />
        </StyledAppDragAndDropCalendar>
    );
};
function CustomDateHeader({ label }) {
    return (
        <span>
            <a>{label}</a>
        </span>
    );
}
const CustomToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
        // toolbar.date.setMonth(moment(date).add(1, "M"));
        toolbar.onNavigate("prev");
    };

    const goToNext = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        // toolbar.date = moment(toolbar.date).add(1, "M");
        toolbar.onNavigate("next");
    };

    const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.onNavigate("current");
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span>
                <b>{date.format("MMMM")}</b>
                <span> {date.format("YYYY")}</span>
            </span>
        );
    };

    return (
        <div className="toolbar-container">
            <label className="label-date">{label()}</label>

            <div className="back-next-buttons">
                <div className="btn-back">
                    <AppIconButton
                        onClick={goToBack}
                        icon={<img src={small_arrow_left} alt="<" />}
                    />
                </div>
                <label className="btn-current" onClick={goToCurrent}>
                    {toolbar.label}
                </label>
                <div className="btn-next">
                    <AppIconButton
                        onClick={goToNext}
                        icon={<img src={small_arrow_right} alt=">" />}
                    />
                </div>
            </div>
        </div>
    );
};
// styled
const StyledAppDragAndDropCalendar = styled.div`
    width: 100%;
    height: 100%;
    height: 37rem;
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        width: 60rem;
    }
    .rbc-month-row + .rbc-month-row {
        border-top: 1px solid transparent !important;
    }
    .rbc-day-bg + .rbc-day-bg {
        border-left: 1px solid transparent !important;
    }
    .rbc-header + .rbc-header {
        border-left: 1px solid transparent !important;
    }
    .label-date {
        display: none;
    }
    .rbc-event {
        background-color: #d5d5d5;
        opacity: 0.5;
    }
    .noneDraggable {
        padding: 0;
        .MuiBox-root {
            padding: 0;
        }
    }
    .isDraggable {
        background-color: blue;
    }
    .back-next-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
        .btn-current {
            display: flex;
            align-items: center;
        }
    }
    .rbc-row-bg .special-day {
        background-color: ${(p) => p.theme.colors.secondary};
        color: white !important;
        &:first-child {
            border-top-left-radius: 2rem !important;
            border-bottom-left-radius: 2rem !important;
        }
        &:last-child {
            border-top-right-radius: 2rem !important;
            border-bottom-right-radius: 2rem !important;
        }
    }
    .rbc-button-link {
        display:none;
    }
    .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon {
        @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            display: block;
            margin-bottom: auto;
            height: 10px;
            cursor: ew-resize;
            background-color: #ff9d43;
            border-radius: 100%;
        }
    }
    .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon:hover {
        @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            background-color: red;
        }
    }
    .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon {
        height: 2rem !important;
    }
    .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
        width: 2rem;
        top: 0;
        @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            width: 6rem;
        }
        @media (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
            width: 3rem;
        }
    }

    .rbc {
        &-month-header {
            max-height: 3rem;
            position: relative !important;
        }
        &-month-row {
            z-index: 1;
            max-height: 5rem;
            border: none;
        }
        &-row-bg {
            position: absolute;
            z-index: 0;
        }
        &-row-content {
            position: relative;
            z-index: 1;
        }
        &-row {
            height: 100%;
        }
        &-row:first-child {
            position: absolute;
            z-index: 0;
            width: 100%;
        }
        &-button-link {
            font-size: 1.4rem;
            user-select: none;
            &:before {
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                background-color: transparent;
            }
        }
        &-date-cell {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            padding-top: 2rem;
        }
        &-event {
            padding: 1rem;
            border-radius: 2rem;
        }
        &-event-content {
            .MuiButton-root {
                height: 100% !important;
                /* padding: 0; */
            }
        }
        &-background-event {
            padding: 0;
        }
        &-row-segment {
            padding: 0;
        }
        &-off-range {
            color: #b7a8a8 !important;
        }
        &-selected-sell {
            background-color: gray !important;
        }
    }
`;
function Event(props: any) {
    const { id } = useParams<{ id: any }>();
    const { event } = props;
    // redux store
    const [showTooltip, setShowTooltip] = React.useState(false);
    // redux store
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    const history = useHistory();
    const closeTooltip = () => {
        setShowTooltip(false);
    };
    const openTooltip = () => {
        setShowTooltip(true);
    };
    const ref = React.useRef(null);
    const gotoViewDetailHistory = () => {
        if (event?.event?.itemType === ItemTypeEnum.TYPE_VEHICLE) {
            history.push(
                `${appRouteEnums.PATH_VEHICLES}/${id}?itemId=${event?.event?.borrowingId}`
            );
        } else {
            history.push(
                `${appRouteEnums.PATH_INSTRUMENTS}/${id}?itemId=${event?.event?.borrowingId}`
            );
        }
    };
    return (
        <Box sx={{ margin: "0 0.5rem", padding: "1rem" }} ref={ref} onClick={gotoViewDetailHistory}>
            {userInfo?.id !== event.event.user?.id && (
                <Tooltip
                    open={showTooltip}
                    onClose={closeTooltip}
                    onOpen={openTooltip}
                    title={
                        <StyledToolTipCalendar onClick={gotoViewDetailHistory}>
                            <Avatar src={linkImage(event.event.user.avatar?.path)} />
                            <p className="title">
                                {event.event.user?.last_name}
                                {event.event.user?.first_name}
                            </p>
                        </StyledToolTipCalendar>
                    }
                    arrow
                >
                    <Button sx={{ width: "100%" }}></Button>
                </Tooltip>
            )}
        </Box>
    );
}
const StyledToolTipCalendar = styled.div`
    display: flex;
    margin: 0 1rem;
    .MuiAvatar-root {
        width: 3.6rem;
        height: 3.6rem;
    }
    .title {
        font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
        display: flex;
        align-items: center;
        padding-left: 1rem;
        font-size: 1.6rem;
    }
    .content {
        height: 100%;
        width: 100%;
    }
    .MuiButton-root {
        height: 4rem;
        width: 4rem;
    }
`;
const AppDragAndDrop = (props: IAppDndCalendar) => {
    const { events, setSelected, selected, listEventsDate, handleOpenEditModal } = props;
    return (
        // <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <Dnd
            events={events}
            setSelected={setSelected}
            listEventsDate={listEventsDate}
            handleOpenEditModal={handleOpenEditModal}
            selected={selected}
        />
        // </DndProvider>
    );
};
export default AppDragAndDrop;
