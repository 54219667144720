const _ = require("lodash");

// WHAT: slice array to get exact number first items for slider
export const sliceArray = (array: any, start: number, end: number) => {
    const newArray = array && array.slice(start, end);
    return newArray;
};
// get quantity when select inventory
export const getQuantitySelection = (locations: any, id: any) => {
    const index = locations.findIndex((x) => x.id === id);
    return locations[index]?.pivot?.quantity;
};
//  filter id inventory
export const filterIdInventory = (inventoryId: any, productList: any) => {
    return productList?.filter((x) => x.inventory_id === inventoryId);
};

// get list users in timesheet
// 4 users
// receive list of user, number of users be rendered
// => list users are showed in timesheet
export const getFirstOfListUsersTimeSheet = (users: any, number: number) => {
    return sliceArray(users, 0, number);
};
// number of users in timesheet
export const getNumberOfUsersTimeSheet = (group: any) => {
    const listArrayUsers = group.map((users) => users.users);
    const merged = [].concat(...listArrayUsers) as any;
    return merged.length;
};
// get list of groups in timesheet
export const filterGroupTimeSheet = (data: Array<any>) => {
    const listGroups = data.map((item) => item.users);
    const merged = [].concat(...listGroups) as any;
    return _.chain(merged)
        .groupBy("id")
        .map((value, key) => ({ id: key, name: value[0].name, list: value }))
        .value();
};
// get listUsersTimesheet
export const filterUserTimesheets = (data: Array<any>, usersId: any) => {
    const listGroups = data.map((item) => item.users);
    const merged = [].concat(...listGroups) as any;
    return [].concat(
        ...merged.filter((item) => item.id === usersId).map((item) => item.timesheets)
    );
};

// convert number to array of interger
export const converNumberToArrayOfInterger = (number: number) => {
    const tempArray = [] as any;
    for (let i = 0; i <= number; i++) {
        tempArray.push(i + 1);
    }
    return tempArray;
};

// flat array
export const flatArrayToString = (key, arr) => {
    let output = "";

    for (let i = 0; i < arr.length; i++) {
        output += "&" + key + "[" + i + "]=" + arr[i];
    }

    return output;
};

// check selected user
export const checkExistUserSelected = (id:any,selectedGroup:any) =>{
    const index = selectedGroup.findIndex((e)=>e===id);
    if(index<0){
        return false;
    }
    return true;
};