import styled from "styled-components";
export const StyledSignIn = styled.div`
    .SignIn {
        overflow: hidden;
        position: relative;
        max-height: 100vh;
        @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
            max-height: 100%;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            max-height: 100%;
        }
        /* @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
            max-height: 100vh;
        } */
        &__iconBg {
            z-index: -1;
            position: absolute;
            right: -0.5rem;
            top: 13.2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                right: 4rem;
                top: 10rem;
            }
        }
        .link {
            font-size: 12px;
            color: ${(p) => p.theme.colors.secondary};
            margin-top: 60px;
            margin-bottom: 20px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin-top: 120px;
                text-align: left;
            }
            span {
                word-spacing: -1px;
            }
        }
        &__left {
            width: 100%;
            height: 100vh;
            @media (max-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                display: none;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
                height: 100%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                height: 100vh;
            }

            &-bg {
                width: 100%;
                height: 100%;
            }
        }
        &__content {
            display: flex;
            justify-content: center;
            padding: 0 60px;
            flex-direction: column;
            height: 100vh;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                padding: 60px 160px;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakIpadPro}) {
                text-align: left;
                padding-top: 160px;
                padding: 60px 80px;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakLarge}) {
                height: 100%;
                padding: 60px 160px;
                padding-top: 160px;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakBig}) {
                padding: 60px 200px;
                height: 100%;
            }
        }
        &__form {
            width: 100%;
            &-title {
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
                font-size: 52px;
                padding-top: 2rem;
            }
            &-wrapper {
                width: 100%;
            }
            &-input {
                margin-top: 24px;
                width: 100%;
            }
            &-btn {
                padding-top: 45px;
            }
            &-checkbox {
                text-align: left;
                margin-top: 24px;
                .Mui-checked {
                    color: ${(p) => p.theme.colors.primary} !important;
                }
                svg {
                    width: 2em;
                    height: 2em;
                }

                .MuiFormControlLabel-label {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_400};
                    font-size: 12px;
                    color: ${(p) => p.theme.colors.gray1};
                }
            }
        }
    }
`;
