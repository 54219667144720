import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { StyledVehicleItem } from "./styles";
import { linkImage } from "@utils";
import { selectVehicleName } from "@redux";
interface IProps {
    item?: any;
    categoryId?: any;
}
export const VehicleItemComponent = (props: IProps) => {
    // props
    const { item } = props;
    //hooks
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const categoryId = query.get("categoryId");
    const subCategoryId = query.get("subCategoryId");
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <StyledVehicleItem>
            <div
                className="wrapper disabled"
                onClick={() => {
                    dispatch(selectVehicleName(item.title));
                    // history.push(`/vehicles/calendar/${item?.id}`);
                    categoryId
                        ? history.push(
                              `/vehicles/calendar/${item?.id}?categoryId=${categoryId}&subCategoryId=${subCategoryId}`
                          )
                        : history.push(`/vehicles/calendar/${item?.id}`);
                }}
            >
                <div className="wrapperImage">
                    <img className="image" src={linkImage(item.image?.path)} alt="inventory item" />
                </div>
                <div className="wrapperContent">
                    <p className="title" style={{ whiteSpace: "pre-line" }}>
                        {item?.name}
                    </p>
                </div>
                {/* <span>{!item?.borrowing && <img src={star_icon} />}</span> */}
                <span>
                    {!item?.borrowing && (
                        <p style={{ color: "red" }}>{item?.isLate ? "遅延" : "使用中"}</p>
                    )}
                </span>
            </div>
        </StyledVehicleItem>
    );
};
