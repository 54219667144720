import { InstrumentDetailView } from "@components";
import React from "react";

export const InstrumentDetail = () => {
    return (
        <>
            <InstrumentDetailView />
        </>
    );
};
