import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IState {
    categories?: any;
    dataResult?: any;
    processing: boolean;
    selectedDate?: any;
    intrumentName?: string;
    idConstuctionInstrumentSearch?: string;
}

const initialState: IState = {
    processing: false,
    categories: undefined,
    dataResult: undefined,
    selectedDate: [],
    idConstuctionInstrumentSearch: "",
};

const instrumentsSlice = createSlice({
    name: "instruments",
    initialState: initialState,
    reducers: {
        setInstrumentsCategory: (state, action: { payload: any }) => {
            state.dataResult = action.payload;
        },
        setSelectedDateInstrument: (state, action: { payload: any }) => {
            state.selectedDate = action.payload;
        },
        selectInstrumentName: (state, action: { payload: string }) => {
            state.intrumentName = action.payload;
        },
        setIdConstuctionInstrumentSearch: (state, action: { payload: string }) => {
            state.idConstuctionInstrumentSearch = action.payload;
        }
    },
});

export const selectInstrument = (state: RootState) => state.instrument;

export const { setInstrumentsCategory, setSelectedDateInstrument, selectInstrumentName, setIdConstuctionInstrumentSearch } =
    instrumentsSlice.actions;

export default instrumentsSlice.reducer;
