import styled from "styled-components";

import small_inventory_bg from "@assets/Background/small_inventory_bg.png";
import inventory_bg from "@assets/Background/inventory_bg.png";

interface IStyledVehicleItem {
    isAvailable?: boolean;
}
export const StyledVehicleView = styled.div`
    background-image: url(${small_inventory_bg});
    background-repeat: no-repeat;
    background-color: #f6f6f6;
    min-height: 100vh;
    padding-bottom: 4rem;
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        background-image: url(${inventory_bg});
    }
    .vehicles {
        padding: 1rem 1.5rem;
        min-height: 80vh;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        .visible-pc {
            display: none;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display: block;
            }
        }
        .visible-mobile {
            padding: "0 2rem";
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                display: none;
            }
        }
        .activeCategory {
            color: ${(p) => p.theme.colors.white} !important;
            background-color: ${(p) => p.theme.colors.primary} !important;
        }
        &__top {
            text-align: left;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                margin-bottom: 4rem;
            }
            &-searchForm {
                height: 100%;
                display: flex;
                align-items: end;
                .MuiIconButton-root {
                    padding: 0rem 1rem;
                    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                        padding: 0rem 2rem;
                    }
                }
            }
        }
        &__content {
            padding-top: 2rem;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                border-top: 1px solid #d4d4d4;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding-top: 0;
            }
            .category {
                padding: 2rem;
                padding-top: 0;
                @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                    padding-top: 0;
                    min-height: 77vh;
                    border-right: 1px solid rgb(212, 212, 212);
                    padding-left: 0px;
                }
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding: 0;
                }
                &__list {
                    text-align: center;
                    padding: 1.5rem 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                        text-align: center;
                    }
                }
                &__item {
                    cursor: pointer;
                    position: relative;
                    font-size: 1.6rem;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                    color: ${(p) => p.theme.colors.darkBlack};
                    display: inline-block;
                    display: -webkit-box;
                    max-width: 100%;
                    /* height: 20px; */
                    line-height: 1.2;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    width: fit-content;
                    text-align: center;
                    margin: 0 auto;
                    cursor: pointer;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-color: transparent;
                    }
                }
                .listItem {
                    min-height: 75vh;
                }
            }
        }
    }
`;
export const StyledVehicleItem = styled.div<IStyledVehicleItem>`
    .wrapper {
        background-color: ${(p) => p.theme.colors.white};
        padding: 2.5rem;
        border-radius: 1.6rem;
        display: flex;
        position: relative;
        z-index: 1;
        justify-content: space-between;
        /* cursor: ${(p) => (p.isAvailable ? "pointer" : "no-drop")}; */
        /* cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: ${(p) => p.theme.colors.gray4};
            opacity: 0.5;
            border-radius: 1.6rem;
            /* z-index: ${(p) => (p.isAvailable ? -1 : 2)}; 
        } */
        .wrapperImage {
            width: 20%;
            height: 5rem;
            max-height: 5rem;
            border-radius: 1.2rem;
            border: 2px solid ${(p) => p.theme.colors.gray3};
            overflow: hidden;
            width: 5rem;
            .image {
                width: 100%;
                height: 100%;
            }
        }
        .wrapperContent {
            padding-left: 2.5rem;
            text-align: left;
            width: 60%;
            .title {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                font-size: 1.6rem;
                padding-top: 1rem;
                display: block;
                display: -webkit-box;
                max-width: 100%;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
        }
        .notAvailable {
            display: block;
            padding-top: 1rem;
            color: ${(p) => p.theme.colors.error};
            display: block;
            display: -webkit-box;
            max-width: 6rem;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            height: 45px;
        }
    }
`;
export const StyledVehicleListItemComponent = styled.div`
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding-left: 2.5rem;
    }
    .VehicleItems {
        &__content {
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding-top: 2rem;
            }
        }
        &__title {
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            font-size: 1.6rem;
            padding: 2.5rem 0;
            text-align: left;
            display: block;
            height: 65px;
            display: -webkit-box;
            line-height: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            max-width: 100%;
        }
        .error {
            min-height: 70vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;
