import { TimeSheetFormContextProvider } from "../../context";
import { TimeSheetDayAndNight } from "./time-sheet-day-and-night";

export function TimeSheetDayAndNightWrapper() {
    return (
        <TimeSheetFormContextProvider>
            <TimeSheetDayAndNight />
        </TimeSheetFormContextProvider>
    );
}
