import { OVERTIME_TYPE_VALUE } from "@enums";
import { Divider } from "@mui/material";
import React from "react";

interface IProps {
    data?: any;
}
const OTDetailDayAndNight = (props: IProps) => {
    const { data } = props;
    return (
        <div>
            {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT && (
                <div className="des">
                    <Divider />
                    <h3>日勤</h3>
                </div>
            )}
            {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY && (
                <div className="des">
                    <Divider />
                    <h3>夜勤</h3>
                </div>
            )}
            {data?.overtimeData?.constructionId?.name && (
                <div className="des">
                    <p>工事名</p>
                    <p className="des__content">{data?.overtimeData?.constructionId?.name}</p>
                </div>
            )}
            {data?.overtimeData?.contractor.length > 0 && (
                <div className="des">
                    <p className="">業者名</p>
                    {data?.overtimeData?.contractor?.map((item) => {
                        return <p className="des__content">{item?.name}</p>;
                    })}
                </div>
            )}
            {data?.overtimeData?.break_time && (
                <div className="des">
                    <p className="">休憩 (hour)</p>
                    <p className="des__content">{data?.overtimeData?.break_time}</p>
                </div>
            )}
            {data?.overtimeData?.work_time && (
                <div className="des">
                    <p className="">稼働時間 (hour)</p>
                    <p className="des__content">{data?.overtimeData?.work_time}</p>
                </div>
            )}
            {data?.overtimeData?.work_detail && (
                <div className="des">
                    <p className="">業務内容</p>
                    <p className="des__content">{data?.overtimeData?.work_detail}</p>
                </div>
            )}
            {data?.overtimeData?.start_at && data?.overtimeData?.stop_at && (
                <div className="date des">
                    <div className="date__from date__left">
                        <p className="title">開始時間</p>
                        <p className="content">{data?.overtimeData?.start_at}</p>
                    </div>
                    <div className="date__from date__right">
                        <p className="title">終了時間</p>
                        <p className="content">{data?.overtimeData?.stop_at}</p>
                    </div>
                </div>
            )}
            {data?.overtimeData?.memo && (
                <div className="des">
                    <p className="">備考</p>
                    <p className="des__content">{data?.overtimeData?.memo}</p>
                </div>
            )}
            {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_DAYTONIGHT && (
                <div className="des">
                    <Divider />
                    <h3>夜勤</h3>
                </div>
            )}
            {data?.overtimeData?.title === OVERTIME_TYPE_VALUE.ENUM_TYPE_OT_NIGHTTODAY && (
                <div className="des">
                    <Divider />
                    <h3>日勤</h3>
                </div>
            )}
            <div>
                {data?.overtimeData?.constructionIdExtra?.name && (
                    <div className="des">
                        <p>工事名</p>
                        <p className="des__content">
                            {data?.overtimeData?.constructionIdExtra?.name}
                        </p>
                    </div>
                )}
                {data?.overtimeData?.contractorextra.length > 0 && (
                    <div className="des">
                        <p className="">業者名</p>
                        {data?.overtimeData?.contractorextra?.map((item) => {
                            return <p className="des__content">{item?.name}</p>;
                        })}
                    </div>
                )}
            </div>
            {data?.overtimeData?.break_time_extra && (
                <div className="des">
                    <p className="">休憩 (hour)</p>
                    <p className="des__content">{data?.overtimeData?.break_time_extra}</p>
                </div>
            )}
            {data?.overtimeData?.work_time_extra && (
                <div className="des">
                    <p className="">稼働時間 (hour)</p>
                    <p className="des__content">{data?.overtimeData?.work_time_extra}</p>
                </div>
            )}
            {data?.overtimeData?.work_detail_extra && (
                <div className="des">
                    <p className="">業務内容</p>
                    <p className="des__content">{data?.overtimeData?.work_detail_extra}</p>
                </div>
            )}
            {data?.overtimeData?.start_at_extra && data?.overtimeData?.stop_at_extra && (
                <div className="date des">
                    <div className="date__from date__left">
                        <p className="title">開始時間</p>
                        <p className="content">{data?.overtimeData?.start_at_extra}</p>
                    </div>
                    <div className="date__from date__right">
                        <p className="title">終了時間</p>
                        <p className="content">{data?.overtimeData?.stop_at_extra}</p>
                    </div>
                </div>
            )}
            {data?.overtimeData?.memo_extra && (
                <div className="des">
                    <p className="">備考</p>
                    <p className="des__content">{data?.overtimeData?.memo_extra}</p>
                </div>
            )}
        </div>
    );
};

export default OTDetailDayAndNight;
