import React from "react";
import { Grid } from "@mui/material";

import { StyledListItem, ItemComponent } from ".";
import { logItemType } from "src/types";

interface IProps{
    logItems:logItemType[];
}
export const ListItemsComponent = (props:IProps) => {
    const {logItems} = props;
    return (
        <StyledListItem>
            <Grid container columnSpacing={8}>
            {logItems && logItems.map((logItem,index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <ItemComponent logItem={logItem} />
                    </Grid>
                ))}
            </Grid>
        </StyledListItem>
    );
};
