import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { Formik } from "formik";
import queryString from "query-string";

import { selectAuth, updateSnackBar, useAppSelector } from "@redux";
import { StyledNewTimeSheetForm, TimeSheetUserInfo } from ".";
import {
    AppTopBar,
    AppIconButton,
    AppButton,
    CircularLoader,
    AppSelect,
    AppInput,
    AppModal,
    AppAlert,
} from "..";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { appRouteEnums } from "@enums";
import { convertStrToDate, converUrlToGetId, timeSheetHoliday } from "@utils";
import { timesheetApi } from "@api";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";
import moment from "moment";

const optionOthers = [
    {
        name: "その他",
        id: 0,
    },
];
export const NewTimeSheetHoliday = () => {
    // redux state
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    // component state
    const [data, setData] = React.useState<any>();
    const [optionAbsenceTypes, setOptionAbsenceTypes] = React.useState<any>();
    const idSelectType = converUrlToGetId(window.location.href);
    const [message, setMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>(false);
    const parsed = queryString.parse(location.search);
    const [date, setDate] = React.useState<any>(parsed?.date ? parsed.date : new Date());
    //  const [date, setDate] = React.useState(new Date());
    const [optionGroups, setOptionGroup] = React.useState<any>();
    const [initialValuesPackage, setInitialValuesPackage] = React.useState({
        absenceTypes: "",
        absenceContents: "",
    });
    // hooks
    const history = useHistory();
    const { id } = useParams<{ id: any }>();
    const dispatch = useDispatch();
    React.useEffect(() => {
        fetchListAbsenceTypes();
        return () => {
            // clean up
        };
    }, []);
    // Fill data if it's edit form
    React.useEffect(() => {
        if (id) {
            fetchViewDetail(id);
        } else if (parsed.copy) {
            fetchViewDetail(parsed.copy);
        }
    }, [id]);
    React.useEffect(() => {
        if (!id) {
            fetchListGroupsByUser();
        }
        return () => {
            // clean up
        };
    }, []);
    // fetch api
    const fetchListAbsenceTypes = async () => {
        if (!id) {
            try {
                const result = await timesheetApi.getListAbsenceType();
                setOptionAbsenceTypes([...result.data.data, ...optionOthers]);
            } catch (err) {}
        }
    };
    // fetch api detail
    const fetchViewDetail = async (id) => {
        setLoading(true);
        try {
            const resultAbsenceTypes = await timesheetApi.getListAbsenceType();
            setOptionAbsenceTypes([...resultAbsenceTypes.data.data, ...optionOthers]);
            const resultGroups = await timesheetApi.listGroupsByUser(userInfo?.id);
            setOptionGroup(resultGroups.data.data);
            const response = await timesheetApi.viewTimesheetDetail(id);
            setLoading(false);
            setData(response.data);
            setDate(convertStrToDate(parsed.date ? parsed.date : response?.data?.date));
            setInitialValuesPackage({
                absenceTypes: response.data.items[0]?.absence_type?.id
                    ? response.data.items[0]?.absence_type?.id
                    : "0",
                absenceContents: response.data.items[0]?.memo ? response.data.items[0]?.memo : "",
            });
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response?.data?.message,
                })
            );
            history.push(appRouteEnums.PATH_TIMESHEET);
        }
    };

    // api submit form
    const fetchTimeSheetForm = async (params: any, resetForm: any) => {
        setLoading(true);
        try {
            const result = await timesheetApi.storeTimeSheetForm(params);
            setMessage(result?.data?.message);
            setSuccess(true);
            setLoading(false);
            resetForm();
        } catch (error: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: error.response?.data?.message,
                })
            );
        }
    };
    // api submit form edit
    const fetchTimeSheetFormEdit = async (params: any, id: any) => {
        setLoading(true);
        try {
            const result = await timesheetApi.updateTimesheetDetail(params, id);
            setMessage(result.data.message);
            setSuccess(true);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: error.response?.data?.message,
                })
            );
        }
    };
    // api delete timesheet
    const fetchDeleteTimeSheet = async () => {
        setLoading(true);
        try {
            const result = await timesheetApi.deleteTimesheet(id);
            setMessage(result.data.message);
            setSuccess(true);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: result?.data?.message,
                })
            );
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: error?.response?.data?.message,
                })
            );
        }
    };
    // fetch api group by user
    const fetchListGroupsByUser = async () => {
        if (!id) {
            try {
                const result = await timesheetApi.listGroupsByUser(userInfo?.id);
                setOptionGroup(result.data.data);
            } catch (err) {}
        }
    };
    // events
    const handleOnSubmit = (values, resetForm) => {
        fetchTimeSheetForm(values, resetForm);
    };
    const handleCloseModal = () => {
        setSuccess(false);
        if (id) {
            history.push(appRouteEnums.PATH_TIMESHEET);
        }
    };
    const handleChangeDate = (date: any) => {
        setDate(date);
    };
    const handleDelete = () => {
        fetchDeleteTimeSheet();
    };
    return (
        <StyledNewTimeSheetForm>
            <AppTopBar />
            <CircularLoader loading={loading} />
            <AppModal open={success} handleClose={handleCloseModal}>
                <AppAlert
                    icon={<img src={hot_yellow_frame} alt="icon" />}
                    text={message}
                    handleClose={handleCloseModal}
                />
            </AppModal>
            <div className="detail">
                <div className="detail__top">
                    <p
                        className="detail__top-icon"
                        onClick={() => history.push(appRouteEnums.PATH_TIMESHEET)}
                    >
                        <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                    </p>
                    <p className="detail__top-title">申請項目</p>
                </div>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Formik
                        initialValues={initialValuesPackage}
                        enableReinitialize
                        onSubmit={(values, { resetForm }) => {
                            if (id) {
                                fetchTimeSheetFormEdit(
                                    {
                                        id: id,
                                        date: moment(date).format("YYYY-MM-DD"),
                                        type: idSelectType,
                                        data: [
                                            {
                                                id: data?.items[0]?.id,
                                                absence_type_id: values.absenceTypes,
                                                memo: values.absenceContents,
                                            },
                                        ],
                                    },
                                    id
                                );
                            } else {
                                handleOnSubmit(
                                    {
                                        date: moment(date).format("YYYY-MM-DD"),
                                        type: idSelectType,
                                        data: [
                                            {
                                                absence_type_id: values.absenceTypes,
                                                memo: values.absenceContents,
                                            },
                                        ],
                                    },
                                    resetForm
                                );
                            }
                        }}
                        validationSchema={timeSheetHoliday}
                    >
                        {({ handleSubmit, values, handleChange, errors, touched }) => {
                            return (
                                <div className="detail__content">
                                    <div className="boxWrapper">
                                        <TimeSheetUserInfo
                                            type={idSelectType}
                                            first_name={userInfo?.first_name}
                                            last_name={userInfo?.last_name}
                                            date={date}
                                            handleChange={handleChangeDate}
                                            created_at={data?.created_at}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <p className="label">グループ</p>
                                            {!!optionGroups?.length && (
                                                <p className="main">{optionGroups[0]?.name}</p>
                                            )}
                                        </Box>
                                    </div>
                                    <div className="boxWrapper">
                                        <label className="label">選択制</label>
                                        <div className="input select">
                                            <AppSelect
                                                options={
                                                    optionAbsenceTypes?.length &&
                                                    optionAbsenceTypes?.map((item) => {
                                                        return {
                                                            label: item.name,
                                                            value: item.id.toString(),
                                                        };
                                                    })
                                                }
                                                name="absenceTypes"
                                                value={values.absenceTypes}
                                                handleChange={handleChange("absenceTypes")}
                                                placeholder="欠席の理由をお選びください"
                                            />
                                        </div>
                                        {values.absenceTypes === "0" && (
                                            <div className="input select">
                                                <AppInput
                                                    value={values.absenceContents}
                                                    name="absenceContents"
                                                    error={errors.absenceContents}
                                                    touched={touched.absenceContents}
                                                    handleChange={handleChange("absenceContents")}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="btn">
                                        <AppButton
                                            onClick={handleSubmit}
                                            text={id ? "申請" : "更新"}
                                        />
                                    </div>
                                    {id && (
                                        <div className="delete">
                                            <AppButton text="削除する" onClick={handleDelete} />
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    </Formik>
                </Box>
            </div>
        </StyledNewTimeSheetForm>
    );
};
