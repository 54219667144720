import { COLOR_ICON_LIGHT_BLUE } from "@configs";
import { IIconProps } from ".";

export const CakeIcon = (props: IIconProps) => {
    const { color = COLOR_ICON_LIGHT_BLUE } = props;
    return (
        <>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2 22H22"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.11 22V13C3.11 11.34 4.6 10 6.44 10H17.55C19.39 10 20.88 11.34 20.88 13V22"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.56 10V7.17C5.56 5.97 6.64 5 7.98 5H16.03C17.36 5 18.44 5.97 18.44 7.17V10"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.53 13.98L3.9 13.99C4.64 14 5.23 14.6 5.23 15.34V15.67C5.23 16.41 5.83 17.02 6.58 17.02C7.32 17.02 7.93 16.42 7.93 15.67V15.36C7.93 14.62 8.53 14.01 9.28 14.01C10.02 14.01 10.63 14.61 10.63 15.36V15.67C10.63 16.41 11.23 17.02 11.98 17.02C12.72 17.02 13.33 16.42 13.33 15.67V15.36C13.33 14.62 13.93 14.01 14.68 14.01C15.42 14.01 16.03 14.61 16.03 15.36V15.67C16.03 16.41 16.63 17.02 17.38 17.02C18.12 17.02 18.73 16.42 18.73 15.67V15.36C18.73 14.62 19.33 14.01 20.08 14.01H20.53"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 5V3"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16 5V3"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 5V2"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
