import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import React from "react";
import { encode, decode } from "js-base64";

import { StyledSignIn } from ".";
import background_signin from "@assets/Images/background_signin.png";
import logo from "@assets/Icons/logo.svg";
import Xitem from "@assets/Icons/X_Item_Icon.svg";
import { AppButton, AppInput, CircularLoader } from "@components";
import { loginSchema } from "@utils";
import { authRouteEnums, appRouteEnums } from "@enums";
import { LockIcon } from "@assets";
import { login, selectAuth, useAppSelector, setRemember, setRememberMe } from "@redux";

export const SignIn = () => {
    // redux store
    const auth = useAppSelector(selectAuth);
    const { isLoading, tokenInfoAuth, isRemember, emailRemember, passwordRemember } = auth;
    // hooks
    const history = useHistory();
    const dispatch = useDispatch();

    React.useLayoutEffect(() => {
        if (tokenInfoAuth) {
            history.push(appRouteEnums.PATH_HOME);
        }
    }, [auth]);
    React.useEffect(() => {
        if (!isRemember) {
            dispatch(
                setRememberMe({
                    email: "",
                    password: "",
                })
            );
        }
    });
    const initialValuesPackage = {
        email: emailRemember || "",
        password: (passwordRemember && decode(passwordRemember)) || "",
    };
    // events
    const handleChangeRemember = () => {
        dispatch(setRemember(!isRemember));
    };

    return (
        <StyledSignIn>
            <CircularLoader loading={isLoading} />
            <div className="SignIn">
                <span className="SignIn__iconBg">
                    <img src={Xitem} />
                </span>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <div className="SignIn__left">
                            <img
                                className="SignIn__left-bg"
                                src={background_signin}
                                alt="image sign in"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="SignIn__content">
                            <div className="SignIn__form">
                                <img src={logo} />
                                <h2 className="SignIn__form-title">ログイン</h2>
                                <Formik
                                    initialValues={initialValuesPackage}
                                    onSubmit={(values) => {
                                        if (isRemember) {
                                            dispatch(
                                                setRememberMe({
                                                    email: values.email,
                                                    password: encode(values.password),
                                                })
                                            );
                                        }
                                        dispatch(
                                            login({
                                                dispatch,
                                                values,
                                            })
                                        );
                                    }}
                                    validationSchema={loginSchema}
                                >
                                    {({
                                        handleSubmit,
                                        values,
                                        handleChange,
                                        errors,
                                        touched,
                                        handleBlur,
                                    }) => {
                                        return (
                                            <div className="SignIn__form-wrapper">
                                                <div className="SignIn__form-input">
                                                    <AppInput
                                                        value={values.email}
                                                        name="email"
                                                        handleChange={handleChange("email")}
                                                        label="Eメール"
                                                        error={
                                                            touched.email ? errors.email : undefined
                                                        }
                                                        handleBlur={handleBlur("email")}
                                                        touched={touched.email}
                                                    />
                                                </div>
                                                <div className="SignIn__form-input">
                                                    <AppInput
                                                        value={values.password}
                                                        name="password"
                                                        handleChange={handleChange("password")}
                                                        label="パスワード"
                                                        isPassword={true}
                                                        iconStart={<LockIcon />}
                                                        error={errors.password}
                                                        handleBlur={handleBlur("password")}
                                                        touched={touched.password}
                                                    />
                                                </div>
                                                <div className="SignIn__form-checkbox">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isRemember}
                                                                onChange={handleChangeRemember}
                                                            />
                                                        }
                                                        label="ログイン状態を保持する"
                                                    />
                                                </div>
                                                <div className="SignIn__form-btn">
                                                    <AppButton
                                                        text="ログイン"
                                                        onClick={handleSubmit}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }}
                                </Formik>
                            </div>
                            <p className="link">
                                <span
                                    onClick={() => history.push(authRouteEnums.PATH_FOGOTPASSWORD)}
                                >
                                    パスワードをお忘れですか ?
                                </span>
                            </p>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </StyledSignIn>
    );
};
