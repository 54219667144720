import { flatArrayToString } from "@utils";
import axiosClient from "./axiosClient";

export interface IConfirmApprovalStatusTimesheetParams {
    timesheet_id: any;
    status: any;
    comment: string | null;
}
export const timesheetApi = {
    getListConstructions: (id?: any) => {
        let url = 'api/constructions';
        if (id) {
            url += `?construction_id=${id}`;
        }
        return axiosClient.get(url);
    },
    getListWeathers: () => {
        const url = "api/weathers";
        return axiosClient.get(url);
    },
    getListContractors: () => {
        const url = "api/contractors";
        return axiosClient.get(url);
    },
    getListAbsenceType: () => {
        const url = "api/absence-types";
        return axiosClient.get(url);
    },
    storeTimeSheetForm: (params: any) => {
        const url = "api/timesheets";
        return axiosClient.post(url, params);
    },
    viewTimesheetDetail: (id: any) => {
        const url = `api/timesheets/${id}`;
        return axiosClient.get(url);
    },
    updateTimesheetDetail: (params: any, id: any) => {
        const url = `api/timesheets/${id}`;
        return axiosClient.put(url, params);
    },
    deleteTimesheet: (id: number) => {
        const url = `api/timesheets/${id}`;
        return axiosClient.delete(url);
    },
    listTimesheetByGroup: (params: any, arrayGroupId: any) => {
        let url = `api/timesheets?month=${params.month}`;
        if (arrayGroupId?.length) {
            url += flatArrayToString("groupId", arrayGroupId);
        }
        if (params.userId) {
            url += "&userId=" + params.userId;
        }
        return axiosClient.get(url);
    },
    listGroupsTimesheet: () => {
        const url = "api/groups";
        return axiosClient.get(url);
    },
    listLastestTimesheet: () => {
        const url = "api/latest-timesheet";
        return axiosClient.get(url);
    },
    listUsersInGroup: (arrayGroupId: any) => {
        const url = `api/employees?groupId=${flatArrayToString("groupId", arrayGroupId)}`;
        return axiosClient.get(url);
    },
    listUsersAndGroups: () => {
        const url = "api/employees-groups";
        return axiosClient.get(url);
    },
    listGroupsByUser: (userId: any) => {
        const url = `api/groups/user/${userId}`;
        return axiosClient.get(url);
    },
    listAlcoholChecker: () => {
        const url = "api/get-list-alcohol-cheker";
        return axiosClient.get(url);
    },
    listEmployees: () => {
        const url = "api/employees";
        return axiosClient.get(url);
    },
    listVehicles: () => {
        const url = "api/vehicles";
        return axiosClient.get(url);
    },
    confirmApprovalStatus: (params: IConfirmApprovalStatusTimesheetParams) => {
        const url = "/api/timesheet/confirm";
        return axiosClient.post(url, params);
    },
    viewTimesheetByDay: (body: any) => {
        let url = `api/group-users/timesheet?date=${body.date || ""}`;
        if (body.arrayGroupId?.length) {
            url += flatArrayToString("groupId", body.arrayGroupId);
        }
        return axiosClient.get(url, body);
    },

    addMultipleConstructionSite: (body) => {
        const url = "api/timesheets";
        return axiosClient.post(url, body);
    },
    getListLiveletEmployee: () => {
        const url = "api/timesheet/getListEmployee";
        return axiosClient.get(url);
    },
    getLatestInfo: () => {
        const url = "/api/timesheet/get-lastest-alcohol-infor";
        return axiosClient.get(url);
    },
    exportCSV: (type: any) => {
        const url = `/api/${type}/export`;
        return axiosClient.get(url);
    }
};
