import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { timesheetApi } from "@api";
import { RootState } from ".";

interface IState {
    loadingGroups: boolean;
    loadingTimesheets: boolean;
    loadingEmployees: boolean;
    timesheets: Array<{ month: string; data: any }>; // Array to store timesheets by month
    groups?: any;
    employees?: any;
    fetchedMonths: string[];
}

const initialState: IState = {
    loadingGroups: false,
    loadingTimesheets: false,
    loadingEmployees: false,
    timesheets: [],
    groups: undefined,
    employees: undefined,
    fetchedMonths: [],
};

// Async Thunks for fetching data
export const fetchGroups = createAsyncThunk(
    "timesheets/fetchGroups",
    async (_, { rejectWithValue }) => {
        try {
            const response = await timesheetApi.listGroupsTimesheet();
            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const fetchTimesheets = createAsyncThunk(
    "timesheets/fetchTimesheets",
    async ({ month, preFetch = false }: { month: string; preFetch?: boolean; }, { dispatch, getState, rejectWithValue }) => {
        const state = getState() as RootState;

        // Check if the requested month is already fetched or is currently being fetched
        const existingMonth = state.timesheets.timesheets.find((timesheet) => timesheet.month === month);
        const loadingMonth = state.timesheets.fetchedMonths.includes(month);
        if (existingMonth || loadingMonth) {
            if (preFetch) {
                // Upgrade pre-fetch to main fetch if requested directly
                preFetch = false;
            } else {
                // No need to fetch if it's already being fetched or present
                return { data: existingMonth?.data, month, preFetch };
            }
        }

        try {
            const response = await timesheetApi.listTimesheetByGroup({month: month}, null);
            const timesheetData = { data: response.data, month };

            // Only fetch adjacent months if not a prefetch
            if (!preFetch) {
                const date = new Date(month + "-15");
                const prevMonth = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate()).toISOString().slice(0, 7);
                const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate()).toISOString().slice(0, 7);

                if (!state.timesheets.fetchedMonths.includes(prevMonth)) {
                    dispatch(fetchTimesheets({ month: prevMonth, preFetch: true }));
                }

                if (!state.timesheets.fetchedMonths.includes(nextMonth)) {
                    dispatch(fetchTimesheets({ month: nextMonth, preFetch: true }));
                }
            }

            return timesheetData;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const fetchEmployeesAndGroups = createAsyncThunk(
    "timesheets/fetchEmployeesAndGroups",
    async (_, { rejectWithValue }) => {
        try {
            const response = await timesheetApi.listUsersAndGroups();
            const { users, groupsUsers } = response.data.data;

            // Create a copy of users and initialize "groups" as an empty array for each user
            const updatedUsers = users.map((user: any) => ({
                ...user,
                groups: [] as number[],
            }));

            // Iterate over groupsUsers and add group_id to corresponding user
            groupsUsers.forEach((groupUser: { user_id: string; group_id: string }) => {
                const user = updatedUsers.find((u: any) => u.id === groupUser.user_id);
                if (user) {
                    user.groups.push(parseInt(groupUser.group_id, 10) || 0);
                }
            });

            // Return the updated users array
            return updatedUsers;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);


// Slice
const timesheetsSlice = createSlice({
    name: "timesheets",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Handling groups fetch
        builder.addCase(fetchGroups.pending, (state) => {
            state.loadingGroups = true;
        });
        builder.addCase(fetchGroups.fulfilled, (state, action) => {
            state.loadingGroups = false;
            state.groups = action.payload;
        });
        builder.addCase(fetchGroups.rejected, (state) => {
            state.loadingGroups = false;
        });


        // Handling timesheets fetch
        builder.addCase(fetchTimesheets.pending, (state, action) => {
            // Only show loading for main (non-pre-fetch) requests
            if (!action.meta.arg.preFetch) {
                state.loadingTimesheets = true;
            }
        });

        builder.addCase(fetchTimesheets.fulfilled, (state, action) => {
            state.loadingTimesheets = false;
            const { month, data } = action.payload;

            const existingMonth = state.timesheets.find((timesheet) => timesheet.month === month);

            if (!existingMonth) {
                state.timesheets.push({ month, data });
            }

            if (!state.fetchedMonths.includes(month)) {
                state.fetchedMonths.push(month);
            }
        });

        builder.addCase(fetchTimesheets.rejected, (state) => {
            state.loadingTimesheets = false;
        });


        // Handling employees fetch
        builder.addCase(fetchEmployeesAndGroups.pending, (state) => {
            state.loadingEmployees = true;
        });
        builder.addCase(fetchEmployeesAndGroups.fulfilled, (state, action) => {
            state.loadingEmployees = false;
            state.employees = action.payload;
        });
        builder.addCase(fetchEmployeesAndGroups.rejected, (state) => {
            state.loadingEmployees = false;
        });
    },
});

export default timesheetsSlice.reducer;



export const selectGroupsLoading = (state: RootState) => state.timesheets.loadingGroups;
export const selectGroupsData = (state: RootState) => state.timesheets.groups;
export const selectGroupsError = (state: RootState) => state.timesheets.loadingGroups && !state.timesheets.groups;

export const selectTimesheetsLoading = (state: RootState) => state.timesheets.loadingTimesheets;
export const selectTimesheetsData = (state: RootState) => state.timesheets.timesheets;
export const selectTimesheetsError = (state: RootState) => state.timesheets.loadingTimesheets && !state.timesheets.timesheets;
export const selectTimesheetsDataByMonth = (state: RootState, month: string) => {
    return state.timesheets.timesheets.find((timesheet) => timesheet.month === month)?.data;
};

export const selectEmployeesLoading = (state: RootState) => state.timesheets.loadingEmployees;
export const selectEmployeesData = (state: RootState) => state.timesheets.employees;
export const selectEmployeesError = (state: RootState) => state.timesheets.loadingEmployees && !state.timesheets.employees;
