import { AppCustomeSelect } from "@components";
import { WorkTypeNameEnums } from "@enums";
import { Divider } from "@mui/material";
import { ErrorMessage, Field, FieldArray } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";

interface IProps {
    values: any;
    touched?: any;
    errors?: any;
    options?: any;
    listConstractors?: any;
    typeOfConstractors?: string;
    workSchedule?: boolean;
    valuesForm?: any;
    setFieldValue?: any;
    handleChange?: any;
    constructors?: any;
    handleChangeNumberOfPeople?: (e, fieldName, i, valuesForm, setFieldValue) => void;
}

export const RenderConstractorsFieldsForm = (props: IProps) => {
    // const [workTypeOne, setWorkTypeOne] = React.useState<any>();
    // const [workTypeTwo, setWorkTypeTwo] = React.useState<any>();
    // const [workTypeThree, setWorkTypeThree] = React.useState<any>();
    const {
        values,
        touched,
        errors,
        options,
        workSchedule,
        listConstractors,
        typeOfConstractors = "listConstractors",
        valuesForm,
        handleChangeNumberOfPeople,
        setFieldValue,
    } = props;
    const value1 = valuesForm[0]?.work_type_name_1;
    const value2 = valuesForm[0]?.work_type_name_2;
    const value3 = valuesForm[0]?.work_type_name_3;

    const [workTypeOne, setWorkTypeOne] = React.useState<any>(value1);
    const [workTypeTwo, setWorkTypeTwo] = React.useState<any>(value2);
    const [workTypeThree, setWorkTypeThree] = React.useState<any>(value3);

    useEffect(() => {

        if (valuesForm[0]?.work_type_name_1) {
            setWorkTypeOne(valuesForm[0]?.work_type_name_1);
        }
        if (valuesForm[0]?.work_type_name_3) {
            setWorkTypeThree(valuesForm[0]?.work_type_name_3);
        }
        if (valuesForm[0]?.work_type_name_2) {
            setWorkTypeTwo(valuesForm[0]?.work_type_name_2);
        }
    }, [valuesForm[0]?.work_type_name_1, valuesForm[0]?.work_type_name_2, valuesForm[0]?.work_type_name_3,]);

    const data = listConstractors?.length
        ? values.map((item) => {
            if (listConstractors.findIndex((e) => e.id.toString() === item) >= 0) {
                return {
                    ...listConstractors[
                    listConstractors.findIndex((e) => e.id.toString() === item)
                    ],
                };
            }
        })
        : [];

    return (
        <StyledRenderFieldsForm>

            {(valuesForm?.[0]?.contractor_id) && (
                <FieldArray name={typeOfConstractors}>
                    {(arrayHelpers) =>
                        !!data.length &&
                        data?.map((e, i) => {
                            const constractorsErrors = (errors?.length && errors[i]) || {};
                            const constractorsTouched =
                                (touched?.listConstractors &&
                                    (touched?.listConstractors as any)?.length &&
                                    touched?.listConstractors[i]) ||
                                {};
                            return (
                                <div key={i} style={{ paddingTop: "1rem" }}>
                                    <p className="name">請負業者: {e?.name}</p>
                                    <div className="groupInput">
                                        <p className="name">人数</p>
                                        <Field
                                            name={`${typeOfConstractors}.${i}.number_of_people`}
                                            type="number"
                                            className={`${constractorsErrors?.number_of_people &&
                                                constractorsTouched?.number_of_people
                                                ? " is-invalid"
                                                : ""
                                                }`}
                                            min={1}
                                            value={valuesForm[i]?.number_of_people}
                                            onChange={(e) => {
                                                handleChangeNumberOfPeople &&
                                                    handleChangeNumberOfPeople(
                                                        e,
                                                        `${typeOfConstractors}.${i}.number_of_people`,
                                                        i,
                                                        valuesForm,
                                                        setFieldValue
                                                    );

                                            }
                                            }
                                        />
                                        <ErrorMessage
                                            name={`${typeOfConstractors}.${i}.number_of_people`}
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    {!workSchedule && (
                                        <div>
                                            {!!options?.type_1 && (
                                                <div className="groupInput detail-input">
                                                    {!!options?.type_1 && (
                                                        <div className="groupInput detail-input">
                                                            <p className="name">
                                                                {WorkTypeNameEnums.TYPE_1}
                                                            </p>
                                                            {typeOfConstractors ===
                                                                "listConstractors" && (
                                                                    <AppCustomeSelect
                                                                        name={`${typeOfConstractors}.${i}.work_type_1`}
                                                                        onChange={(e) => {
                                                                            arrayHelpers.replace(i, {
                                                                                ...arrayHelpers?.form
                                                                                    ?.values
                                                                                    .listConstractors[i],
                                                                                    work_type_name_1: e,
                                                                                    work_type_1: e?.map((item) => item.value),
                                                                                });
                                                                        }}
                                                                        groupUserOptions={options?.type_1.map(
                                                                            (item) => {
                                                                                return {
                                                                                    label: item.name,
                                                                                    value: item.id.toString(),
                                                                                };
                                                                            }
                                                                        )}
                                                                        options={
                                                                            options?.type_1?.length
                                                                                ? options?.type_1?.map(
                                                                                    (item) => {
                                                                                        return {
                                                                                            label: item.name,
                                                                                            value: item.id.toString(),
                                                                                        };
                                                                                    }
                                                                                )
                                                                                : []
                                                                        }

                                                                        value={workTypeOne}

                                                                        placeholder={
                                                                            WorkTypeNameEnums.TYPE_1
                                                                        }
                                                                        isMulti
                                                                    />
                                                                )}
                                                            {typeOfConstractors ===
                                                                "dayListConstractors" && (
                                                                    <AppCustomeSelect
                                                                        name={`${typeOfConstractors}.${i}.work_type_1`}
                                                                        onChange={(e) => {
                                                                            arrayHelpers.replace(i, {
                                                                                ...arrayHelpers?.form
                                                                                    ?.values
                                                                                    .dayListConstractors[i],
                                                                                    work_type_name_1: e,
                                                                                work_type_1: e?.map((items) => items.value),
                                                                            });
                                                                        }}
                                                                        groupUserOptions={options?.type_1.map(
                                                                            (item) => {
                                                                                return {
                                                                                    label: item.name,
                                                                                    value: item.id.toString(),
                                                                                };
                                                                            }
                                                                        )}
                                                                        options={
                                                                            options?.type_1?.length
                                                                                ? options?.type_1.map(
                                                                                    (item) => {
                                                                                        return {
                                                                                            label: item.name,
                                                                                            value: item.id.toString(),
                                                                                        };
                                                                                    }
                                                                                )
                                                                                : []
                                                                        }
                                                                        value={workTypeOne}
                                                                        placeholder={
                                                                            WorkTypeNameEnums.TYPE_1
                                                                        }
                                                                        isMulti
                                                                    />
                                                                )}
                                                            {typeOfConstractors ===
                                                                "nightListConstractors" && (
                                                                    <AppCustomeSelect
                                                                        name={`${typeOfConstractors}.${i}.work_type_1`}
                                                                        onChange={(e) => {
                                                                            arrayHelpers.replace(i, {
                                                                                ...arrayHelpers?.form
                                                                                    ?.values
                                                                                    .nightListConstractors[
                                                                                i
                                                                                ],
                                                                                work_type_name_1: e,
                                                                                work_type_1: e?.map((items) => items.value),
                                                                            });
                                                                        }}
                                                                        placeholder={
                                                                            WorkTypeNameEnums.TYPE_1
                                                                        }
                                                                        groupUserOptions={options?.type_1.map(
                                                                            (item) => {
                                                                                return {
                                                                                    label: item.name,
                                                                                    value: item.id.toString(),
                                                                                };
                                                                            }
                                                                        )}
                                                                        value={workTypeOne}
                                                                        options={
                                                                            options?.type_1?.length &&
                                                                            options?.type_1.map((item) => {
                                                                                return {
                                                                                    label: item.name,
                                                                                    value: item.id.toString(),
                                                                                };
                                                                            })
                                                                        }
                                                                        isMulti
                                                                    />
                                                                )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {!!options?.type_2 && (
                                                <div className="groupInput detail-input">
                                                    <p className="name">{WorkTypeNameEnums.TYPE_2}</p>
                                                    {typeOfConstractors === "listConstractors" && (
                                                        <AppCustomeSelect
                                                            name={`${typeOfConstractors}.${i}.work_type_2`}
                                                            onChange={(e) => {
                                                                arrayHelpers.replace(i, {
                                                                    ...arrayHelpers?.form?.values
                                                                        .listConstractors[i],
                                                                        work_type_name_2: e,

                                                                    work_type_2: e?.map((items) => items.value),
                                                                });
                                                            }}
                                                            placeholder={WorkTypeNameEnums.TYPE_2}
                                                            groupUserOptions={options?.type_2.map(
                                                                (item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                }
                                                            )}
                                                            options={
                                                                options?.type_2?.length &&
                                                                options?.type_2.map((item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                })
                                                            }
                                                            value={workTypeTwo}
                                                            isMulti
                                                        />
                                                    )}
                                                    {typeOfConstractors === "dayListConstractors" && (
                                                        <AppCustomeSelect
                                                            name={`${typeOfConstractors}.${i}.work_type_2`}
                                                            placeholder={WorkTypeNameEnums.TYPE_2}
                                                            onChange={(e) => {
                                                                arrayHelpers.replace(i, {
                                                                    ...arrayHelpers?.form?.values
                                                                        .dayListConstractors[i],
                                                                        work_type_name_2: e,
                                                                    work_type_2: e?.map((items) => items.value),
                                                                });
                                                            }}
                                                            groupUserOptions={options?.type_2.map(
                                                                (item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                }
                                                            )}
                                                            options={
                                                                options?.type_2?.length &&
                                                                options?.type_2.map((item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                })
                                                            }
                                                            value={workTypeTwo}
                                                            isMulti
                                                        />
                                                    )}
                                                    {typeOfConstractors === "nightListConstractors" && (
                                                        <AppCustomeSelect
                                                            name={`${typeOfConstractors}.${i}.work_type_2`}
                                                            placeholder={WorkTypeNameEnums.TYPE_2}
                                                            onChange={(e) => {
                                                                arrayHelpers.replace(i, {
                                                                    ...arrayHelpers?.form?.values
                                                                        .nightListConstractors[i],
                                                                        work_type_name_2: e,
                                                                    work_type_2: e?.map((items) => items.value),
                                                                });
                                                            }}
                                                            groupUserOptions={options?.type_2.map(
                                                                (item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                }
                                                            )}
                                                            options={
                                                                options?.type_2?.length &&
                                                                options?.type_2.map((item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                })
                                                            }
                                                            value={workTypeTwo}
                                                            isMulti
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {!!options?.type_3 && (
                                                <div className="groupInput detail-input">
                                                    <p className="name">{WorkTypeNameEnums.TYPE_3}</p>
                                                    {typeOfConstractors === "listConstractors" && (
                                                        <AppCustomeSelect
                                                            name={`${typeOfConstractors}.${i}.work_type_3`}
                                                            placeholder={WorkTypeNameEnums.TYPE_3}
                                                            onChange={(e) => {
                                                                arrayHelpers.replace(i, {
                                                                    ...arrayHelpers?.form?.values
                                                                        .listConstractors[i],
                                                                        work_type_name_3: e,
                                                                    work_type_3: e?.map((items) => items.value),
                                                                });
                                                            }}
                                                            groupUserOptions={options?.type_3.map(
                                                                (item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                }
                                                            )}
                                                            options={
                                                                options?.type_3?.length &&
                                                                options?.type_3.map((item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                })
                                                            }
                                                            value={workTypeThree}
                                                            isMulti
                                                        />
                                                    )}
                                                    {typeOfConstractors === "dayListConstractors" && (
                                                        <AppCustomeSelect
                                                            name={`${typeOfConstractors}.${i}.work_type_3`}
                                                            placeholder={WorkTypeNameEnums.TYPE_3}
                                                            onChange={(e) => {
                                                                arrayHelpers.replace(i, {
                                                                    ...arrayHelpers?.form?.values
                                                                        .dayListConstractors[i],
                                                                        work_type_name_3: e,
                                                                    work_type_3: e?.map((items) => items.value),
                                                                });
                                                            }}
                                                            groupUserOptions={options?.type_3.map(
                                                                (item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                }
                                                            )}
                                                            options={
                                                                options?.type_3?.length &&
                                                                options?.type_3.map((item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                })
                                                            }
                                                            value={workTypeThree}
                                                            isMulti
                                                        />
                                                    )}
                                                    {typeOfConstractors === "nightListConstractors" && (
                                                        <AppCustomeSelect
                                                            name={`${typeOfConstractors}.${i}.work_type_3`}
                                                            placeholder={WorkTypeNameEnums.TYPE_3}
                                                            onChange={(e) => {
                                                                arrayHelpers.replace(i, {
                                                                    ...arrayHelpers?.form?.values
                                                                        .nightListConstractors[i],
                                                                        work_type_name_3: e,
                                                                    work_type_3: e?.map((items) => items.value),
                                                                });
                                                            }}
                                                            groupUserOptions={options?.type_3.map(
                                                                (item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                }
                                                            )}
                                                            options={
                                                                options?.type_3?.length &&
                                                                options?.type_3.map((item) => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item.id.toString(),
                                                                    };
                                                                })
                                                            }
                                                            value={workTypeThree}
                                                            isMulti
                                                        />
                                                    )}
                                                    {/* //     name={`${typeOfConstractors}.${i}.work_type_3`}
                                                                        //     component={MyComponent}
                                                                        //     placeholder="choose a type of work"
                                                                        // ></Field> */}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <Divider style={{ paddingTop: "1rem" }} />
                                </div>
                            );
                        })
                    }
                </FieldArray>
            )}

        </StyledRenderFieldsForm>
    );
};

const StyledRenderFieldsForm = styled.div`
    .name {
        font-size: 1.4rem;
    }
    .groupInput {
        display: flex;
        flex-direction: column;
        input,
        select {
            width: 50%;
        }
        label {
            padding: 1rem 0;
            font-size: 1.4rem !important;
        }
        input,
        select {
            height: 4rem;
            min-height: 40px;
            font-size: 16px;
            border-radius: 1rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_400};
            padding-left: 10px;
            color: ${(p) => p.theme.colors.black};
        }
        input {
            border: 1px solid #d4d4d4;
        }
        input:focus {
            outline: none !important;
        }
    }
    .detail-input {
        input {
            width: 100%;
        }
    }
    .invalid-feedback {
        color: ${(p) => p.theme.colors.error};
    }
`;
