import { Box } from "@mui/system";
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { AppTextArea } from "src/Components/Common";
import { TimeSheetNightAndDayFormSchemaType } from "../../validation";
import { useTimeSheetContext } from "../../context";
import { Divider } from "@mui/material";

export interface LiveletEmployeeItemFormWidgetProps {
    nestIndex: number;
    // workType: WorkTypeResponse;
}

export function LiveLetEmployeeItemFormWidget({ nestIndex }: LiveletEmployeeItemFormWidgetProps) {
    const form = useFormContext<TimeSheetNightAndDayFormSchemaType>();

    const { employees } = useTimeSheetContext();
    const { control } = form;

    const { fields } = useFieldArray({
        control,
        name: `list_constructions.${nestIndex}.livelet_employee`,
    });

    const watchEmployee = useWatch({
        name: `list_constructions.${nestIndex}.livelet_employee`,
    });
    return (
        <Box sx={{ mt: "20px" }}>
            {fields.map((field, k) => {
                const contractorsSelect = watchEmployee[k].employee_name;

                const constructorMatch = employees.find(
                    (employee) => employee.value === contractorsSelect
                );

                return (
                    <Box sx={{ mb: "20px" }} key={field.id}>
                        <Box sx={{ width: "50%", my: "15px" }}>
                            <p style={{ marginBottom: "10px" }} className="name">
                                スタッフへの注意事項: {constructorMatch?.label || contractorsSelect}
                            </p>
                            <div className="input">
                                <label className="label">メモ</label>
                                <Controller
                                    name={`list_constructions.${nestIndex}.livelet_employee.${k}.employee_memo`}
                                    control={control}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error },
                                    }) => (
                                        <>
                                            <AppTextArea value={value} handleChange={onChange} />
                                            {error && (
                                                <p
                                                    style={{
                                                        color: "#FF597B",
                                                        fontSize: "13px",
                                                        margin: "8px",
                                                    }}
                                                >
                                                    {error?.message}
                                                </p>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                        </Box>
                        <Divider />
                    </Box>
                );
            })}
        </Box>
    );
}
