import React from "react";
import { AppTopBar, ReturnScheduleView } from "@components";

export const ReturnSchedule = () => {
    return (
        <div>
            <AppTopBar />
            <ReturnScheduleView />
        </div>
    );
};
