import React from "react";

import { DailylogView } from "@components";

export const DailyLog = () => {
    return (
        <div>
            <DailylogView />
        </div>
    );
};
