import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";

import { useAppSelector, selectApp, resetSnackBar } from "@redux";
import { StyledAppSnackbar } from ".";
import warning_alert from "@assets/Icons/warning_alert.svg";
import error_alert from "@assets/Icons/error_alert.svg";
import info_alert from "@assets/Icons/info_alert.svg";
import success_alert from "@assets/Icons/success_alert.svg";
import { useHistory, useLocation } from "react-router-dom";
import { appRouteEnums } from "@enums";

export const AppSnackbar = () => {
    const app = useAppSelector(selectApp);
    const { show, anchorOrigin, messageType = "warning", message } = app.snackbar;
    // hooks
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const getStringPath = (path: string) => {
        return path.replace("/", "").split("/").slice(0, 2).join(" ");
    };
    const handleClose = () => {
        dispatch(resetSnackBar());

        getStringPath(location.pathname) ===
            getStringPath(appRouteEnums.PATH_INSTRUMENTS_CALENDAR) ||
            (getStringPath(location.pathname) ===
                getStringPath(appRouteEnums.PATH_VEHICLES_CALENDAR) &&
                history.push(appRouteEnums.PATH_HOME));
    };
    if (show) {
        const keyOfMessage = new Date().getTime();
        return (
            <StyledAppSnackbar messageType={messageType}>
                <Snackbar
                    key={keyOfMessage}
                    anchorOrigin={anchorOrigin ?? { vertical: "top", horizontal: "center" }}
                    open={show}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        elevation={2}
                        variant="filled"
                        onClose={handleClose}
                        severity={messageType}
                        iconMapping={{
                            warning: <img src={warning_alert} />,
                            error: <img src={error_alert} />,
                            info: <img src={info_alert} />,
                            success: <img src={success_alert} />,
                        }}
                    >
                        <div className="message">
                            <p className="message__title">
                                {messageType === "warning" && "警報"}
                                {messageType === "error" && "エラー"}
                                {messageType === "success" && "成功"}
                            </p>
                            <p className="message__subtitle">{message}</p>
                        </div>
                    </Alert>
                </Snackbar>
            </StyledAppSnackbar>
        );
    }
    return null;
};
