import * as React from "react";
import { useHistory, useParams } from "react-router-dom";

import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { StyledTimeSheetDetail } from ".";
import { AppTopBar, AppIconButton, TimeSheetUserInfo, CircularLoader, OTApprovalProcess } from "..";
import { appRouteEnums } from "@enums";
import { Box } from "@mui/system";
import { timesheetApi } from "@api";
import { updateSnackBar } from "@redux";
import { useDispatch } from "react-redux";
import { OT_STATUS_OK, OT_STATUS_PENDING, OT_STATUS_REJECT } from "@configs";
import checked_status_icon from "@assets/Icons/OT/checked_status_icon.svg";
import unchecked_status_icon from "@assets/Icons/OT/unchecked_status_icon.svg";

export const TimeSheetDetailPublicDay = () => {
    // components state
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>();
    const [reloadPage, setReloadPage] = React.useState<boolean>(false);
    // hooks
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: any }>();
    React.useEffect(() => {
        fetchApiDetailTimeSheet();
    }, [id, reloadPage]);
    // fetch api
    const fetchApiDetailTimeSheet = async () => {
        setLoading(true);
        try {
            const response = await timesheetApi.viewTimesheetDetail(id);
            setData(response.data);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.message,
                })
            );
        }
    };
    // fetch api approval status
    const fetchApprovalStatusTimesheet = async (values: any, resetForm: any) => {
        setLoading(true);
        try {
            await timesheetApi.confirmApprovalStatus({
                timesheet_id: id,
                status: values.radioValue,
                comment: values.inputValue || "",
            });
            setLoading(false);
            setReloadPage(!reloadPage);
            resetForm();
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response.data.data,
                })
            );
        }
    };
    // events
    const handleSubmitConfirmApprovalStatus = (values: any, resetForm: any) => {
        fetchApprovalStatusTimesheet(values, resetForm);
    };
    return (
        <StyledTimeSheetDetail>
            <AppTopBar />
            <CircularLoader loading={loading} />
            <div className="detail">
                <div className="detail__top">
                    <p
                        className="detail__top-icon"
                        onClick={() => history.push(appRouteEnums.PATH_TIMESHEET)}
                    >
                        <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                    </p>
                    <p className="detail__top-title">申請項目</p>
                    {data?.status && (
                        <div className="detail__top-status">
                            {data?.status === OT_STATUS_OK && (
                                <div className="detail__top-checked">
                                    <span className="text">OK</span>
                                    <img className="icon" src={checked_status_icon} alt="icon" />
                                </div>
                            )}
                            {data?.status === OT_STATUS_PENDING && (
                                <div className="detail__top-pending">
                                    <span className="text">未決</span>
                                </div>
                            )}
                            {data?.status === OT_STATUS_REJECT && (
                                <div className="detail__top-unChecked">
                                    <span className="text">NG</span>
                                    <img className="icon" src={unchecked_status_icon} alt="icon" />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <div className="detail__content">
                        {/* {!!data?.checkComfirm?.length && ( */}
                        <div className="boxWrapper">
                            <OTApprovalProcess
                                showForm={data?.checkConfirm}
                                data={data}
                                handleOnSubmit={handleSubmitConfirmApprovalStatus}
                            />
                        </div>
                        {/* )} */}

                        <div className="boxWrapper">
                            <TimeSheetUserInfo
                                type={data?.type}
                                last_name={data?.user?.last_name}
                                first_name={data?.user?.first_name}
                                date={data?.date}
                                created_at={data?.created_at}
                                isDetail
                            />
                            <div className="row">
                                <label className="label">グループ</label>
                                <p className="main">{data?.group?.name}</p>
                            </div>
                            {/* <div className="rowContent">
                                <label className="label">グループ</label>
                                <p className="main">{data?.group?.name}</p>
                            </div> */}
                        </div>
                    </div>
                </Box>
            </div>
        </StyledTimeSheetDetail>
    );
};
