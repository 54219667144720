import styled from "styled-components";

import small_bg_dot from "@assets/Images/small_bg_dot.svg";

export const StyledTimeSheetDetail = styled.div`
    .detail {
        min-height: 100vh;
        background-image: url(${small_bg_dot});
        background-repeat: no-repeat;
        background-color: #f6f6f6;
        text-align: left;
        padding: 1rem 1.5rem;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 2rem 8rem;
        }
        @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
            padding: 2rem 11rem;
        }
        &__top {
            display: flex;
            flex-direction: column;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                flex-direction: row;
            }
            &-title {
                font-size: 3.2rem;
                font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            }
            &-status {
                @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                    padding-top: 1rem;
                    padding-left: 2rem;
                }
            }
            &-checked {
                padding: 0.4rem 1rem;
                background-color: #d6fddc;
                display: flex;
                width: 6rem;
                .text {
                    padding-right: 0.5rem;
                    color: #0aba3b;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-unChecked {
                padding: 0.4rem 1rem;
                background-color: #fdd6d6;
                display: flex;
                width: 6rem;
                .text {
                    padding-right: 0.5rem;
                    color: #ba0a0a;
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .icon {
                    display: block;
                    padding-top: 0.1rem;
                }
            }
            &-pending {
                padding: 0.4rem 1rem;
                background-color: #e9e9e9;
                width: 6rem;
            }
        }
        
        &__content {
            width: 100%;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                width: 60rem;
            }
            .boxWrapper {
                padding: 2rem 1.5rem;
                border-radius: 1.6rem;
                background-color: ${(p) => p.theme.colors.white};
                margin: 1.5rem 0;
            }
            .label {
                display: block;
                padding: 1rem 0;
                color: #656565;
            }
            .date {
                display: grid;
                grid-column-gap: 1rem;
                grid-template-columns: auto auto;
            }
            .main {
                font-size: 1.6rem;
                color: #000000;
            }
            .rowContent {
                margin: 1.5rem 0;
            }
            .row{
                display:flex;
                justify-content:space-between;
                .main{
                    font-size:1.8rem;
                }
            }
            .time {
                display: grid;
                grid-column-gap: 1rem;
                grid-template-columns: auto auto;
                .startTime,
                .endTime {
                    display: flex;
                }
                &__icon {
                    display: flex;
                    padding-right: 1rem;
                }
            }
            .MuiFormGroup-root {
                display: flex;
                flex-direction: row;
                .MuiTypography-root {
                    font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
                }
                .Mui-checked {
                    color: #43cdec;
                }
                .MuiSvgIcon-root {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }
`;
