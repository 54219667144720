import styled from "styled-components";

import small_bg_dot from "@assets/Images/small_bg_dot.svg";

export const StyledReturnScheduleView = styled.div`
    background-image: url(${small_bg_dot});
    background-color: #f6f6f6;
    min-height: 100vh;
    @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
        padding: 2rem 8rem;
    }
    @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
        padding: 2rem 11rem;
    }
    .returnSchedule {
        padding: 1.6rem;
        text-align: left;
        @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
            padding: 0;
        }
        &__content {
            padding-top: 2.5rem;
        }
        &__icon {
            width: 5rem;
            div {
                width: 100%;
                button {
                    width: 100%;
                    display: flex;
                    justify-content: end;
                }
            }
            .MuiIconButton-root {
                padding-left: 0;
            }
        }
    }
`;
export const StyledReturnScheduleItem = styled.div`
    .wrapperItem {
        padding: 1.7rem;
        background: ${(p) => p.theme.colors.white};
        border-radius: 1.2rem;
        transition: 250ms;
        cursor: pointer;
        &:hover {
            background-color: #343942;
            .item__content {
                .text {
                    color: #f4f4f4;
                }
                .amount {
                    border-color: ${(p) => p.theme.colors.white};
                }
                .code2 {
                    color: ${(p) => p.theme.colors.secondary};
                }
            }
            .item__btn {
                .MuiIconButton-root {
                    background-color: ${(p) => p.theme.colors.secondary} !important;
                    border-color: ${(p) => p.theme.colors.darkBlack};
                }
            }
        }
    }
    .active {
        background-color: #343942;
        .item__content {
            .text {
                color: #f4f4f4;
            }
            .amount {
                border-color: ${(p) => p.theme.colors.white};
            }
            .code2 {
                color: ${(p) => p.theme.colors.secondary};
            }
        }
        .item__btn {
            .MuiIconButton-root {
                background-color: ${(p) => p.theme.colors.secondary} !important;
                border-color: ${(p) => p.theme.colors.darkBlack};
            }
        }
    }
    .item {
        &__icon {
            width: 100%;
            height: 5rem;
            max-height: 5rem;
            border-radius: 1.2rem;
            overflow: hidden;
            border: 1px solid black;
            .image {
                width: 100%;
                height: 100%;
            }
        }
        &__content {
            padding-left: 2rem;
            .text {
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                font-size: 1.6rem;
                display: block;
                display: -webkit-box;
                max-width: 80%;
                height: 22px;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
            .subText {
                padding-top: 0.5rem;
                color: ${(p) => p.theme.colors.gray2};
                font-size: 1.2rem;
                display: block;
                display: -webkit-box;
                max-width: 80%;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                span {
                }
            }
            .amount {
                padding: 0.1rem 0.5rem;
                font-size: 1rem;
                border-radius: 100%;
                border: 1px solid black;
            }
        }
    }
`;
