import React from "react";

import { OverTimeView } from "@components";

export const OverTime = () => {
    return (
        <div>
            <OverTimeView />
        </div>
    );
};
