import { Modal } from "@mui/material";

import { StyledModal } from ".";

interface IAppModal {
    children?: any;
    open?: boolean;
    handleClose?: () => void;
    className?: string;
}

export const AppModal = (props: IAppModal) => {
    const { children, open = false, handleClose, className } = props;
    return (
        <Modal keepMounted open={open} onClose={handleClose} className={className}>
            <StyledModal>
                <div className="modal__content">{children}</div>
            </StyledModal>
        </Modal>
    );
};
