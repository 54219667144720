import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import queryString from "query-string";

import { StyledNewTimeSheetForm, TimeSheetUserInfo } from ".";
import { AppTopBar, AppIconButton, AppButton, CircularLoader, AppModal, AppAlert } from "..";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { appRouteEnums } from "@enums";
import { selectAuth, updateSnackBar, useAppSelector } from "@redux";
import { convertStrToDate, converUrlToGetId } from "@utils";
import { timesheetApi } from "@api";
import hot_yellow_frame from "@assets/Icons/hot_yellow_frame.svg";

export const NewTimeSheetPublicHoliday = () => {
    // redux state
    const auth = useAppSelector(selectAuth);
    const { userInfo } = auth;
    const idSelectType = converUrlToGetId(window.location.href);
    // component states
    const [message, setMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<boolean>(false);
    const [optionGroups, setOptionGroup] = React.useState<any>();
    const parsed = queryString.parse(location.search);
    const [date, setDate] = React.useState<any>(parsed?.date ? parsed.date : new Date());
    //  const [date, setDate] = React.useState(new Date());
    const [initialValuesPackage, setInitialValuesPackage] = React.useState<any>({
        group: "",
        numberOfConstractors: "",
        constractors: [],
    });
    // hooks
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: any }>();
    // Fill data if it's edit form
    React.useEffect(() => {
        if (id) {
            fetchViewDetail(id);
        } else if (parsed.copy) {
            fetchViewDetail(parsed.copy);
        }
        return () => {
            // do something with cleanup
        };
    }, [id]);
    React.useEffect(() => {
        if (!id) {
            fetchListGroupsByUser();
        }
        return () => {
            // clean up
        };
    }, []);
    // fetch api detail
    const fetchViewDetail = async (id) => {
        setLoading(true);
        try {
            const responseGroups = await timesheetApi.listGroupsByUser(userInfo?.id);
            setOptionGroup(responseGroups.data.data);
            const response = await timesheetApi.viewTimesheetDetail(id);
            setInitialValuesPackage({
                group: response.data.group?.id || "",
            });
            setLoading(false);
            setDate(convertStrToDate(parsed.date ? parsed.date : response?.data?.date));
        } catch (err: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: err.response?.data?.message,
                })
            );
            history.push(appRouteEnums.PATH_TIMESHEET_NEW);
        }
    };
    // fetch form api
    const fetchTimeSheetForm = async (params: any) => {
        setLoading(true);
        try {
            const result = await timesheetApi.storeTimeSheetForm(params);
            setMessage(result.data.message);
            setSuccess(true);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: error.response?.data?.message,
                })
            );
        }
    };
    // api submit form edit
    const fetchTimeSheetFormEdit = async (params: any, id: any) => {
        setLoading(true);
        try {
            const result = await timesheetApi.updateTimesheetDetail(params, id);
            setMessage(result.data.message);
            setSuccess(true);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: error.response?.data?.message,
                })
            );
        }
    };
    // api delete timesheet
    const fetchDeleteTimeSheet = async () => {
        setLoading(true);
        try {
            const result = await timesheetApi.deleteTimesheet(id);
            setMessage(result.data.message);
            setSuccess(true);
            dispatch(
                updateSnackBar({
                    messageType: "success",
                    message: result.data.message,
                })
            );
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            dispatch(
                updateSnackBar({
                    messageType: "error",
                    message: error.response?.data?.message,
                })
            );
        }
    };
    // fetch api group by user
    const fetchListGroupsByUser = async () => {
        if (!id) {
            try {
                const result = await timesheetApi.listGroupsByUser(userInfo?.id);
                setOptionGroup(result.data.data);
            } catch (err) {}
        }
    };
    // events

    const handleCloseModal = () => {
        setSuccess(false);
        if (id) {
            history.push(appRouteEnums.PATH_TIMESHEET);
        }
    };
    const handleChangeDate = (date: any) => {
        setDate(date);
    };
    const handleDeleteTimesheet = () => {
        fetchDeleteTimeSheet();
    };
    return (
        <StyledNewTimeSheetForm>
            <AppTopBar />
            <CircularLoader loading={loading} />
            <AppModal open={success} handleClose={handleCloseModal}>
                <AppAlert
                    icon={<img src={hot_yellow_frame} alt="icon" />}
                    text={message}
                    handleClose={handleCloseModal}
                />
            </AppModal>
            <div className="detail">
                <div className="detail__top">
                    <p
                        className="detail__top-icon"
                        onClick={() => history.push(appRouteEnums.PATH_TIMESHEET)}
                    >
                        <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                    </p>
                    <p className="detail__top-title">申請項目</p>
                </div>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Formik
                        initialValues={initialValuesPackage}
                        enableReinitialize
                        onSubmit={() => {
                            if (id) {
                                fetchTimeSheetFormEdit(
                                    {
                                        date: moment(date).format("YYYY-MM-DD"),
                                        id: id,
                                        type: idSelectType,
                                        data: [],
                                    },
                                    id
                                );
                            }
                            fetchTimeSheetForm({
                                date: moment(date).format("YYYY-MM-DD"),
                                type: idSelectType,
                                data: [],
                            });
                        }}
                    >
                        {({ handleSubmit }) => {
                            return (
                                <div className="detail__content">
                                    <div className="boxWrapper">
                                        <TimeSheetUserInfo
                                            type={idSelectType}
                                            last_name={userInfo?.last_name}
                                            first_name={userInfo?.first_name}
                                            date={date}
                                            handleChange={handleChangeDate}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <p className="label">グループ</p>
                                            {!!optionGroups?.length && (
                                                <p className="main">{optionGroups[0]?.name}</p>
                                            )}
                                        </Box>
                                    </div>
                                    <div className="btn">
                                        <AppButton
                                            onClick={handleSubmit}
                                            text={id ? "申請" : "更新"}
                                        />
                                    </div>
                                    {id && (
                                        <div className="delete">
                                            <AppButton
                                                text="削除する"
                                                onClick={handleDeleteTimesheet}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                    </Formik>
                </Box>
            </div>
        </StyledNewTimeSheetForm>
    );
};
