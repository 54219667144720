import { COLOR_ICON_LIGHT_BLUE } from "@configs";
import { IIconProps } from ".";

export const BloodIcon = (props: IIconProps) => {
    const { color = COLOR_ICON_LIGHT_BLUE } = props;
    return (
        <>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.61 2.21C12.25 1.93 11.75 1.93 11.39 2.21C9.49001 3.66 3.88 8.39 3.91 13.9C3.91 18.36 7.54001 22 12.01 22C16.48 22 20.11 18.37 20.11 13.91C20.12 8.48 14.5 3.67 12.61 2.21Z"
                    stroke={color}
                    strokeWidth="2"
                    strokeMiterlimit="10"
                />
            </svg>
        </>
    );
};
