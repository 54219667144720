import { Divider, Drawer } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React from "react";
import { Document, Page } from "react-pdf";

import { DownLoadIcon, EyeIcon } from "@assets";
import arrow_toggle from "@assets/Icons/arrow/arrow_toggle.svg";
import file_icon from "@assets/Icons/file_icon.svg";
import {
    DOCUMENT_EXCEL_FAILED_TO_LOAD,
    DOCUMENT_FAILED_TO_LOAD,
    MESSAGE_LOADING_PDF,
    WIDTH_DRAWER_MOBILE,
    WIDTH_DRAWER_PC,
} from "@configs";
import {
    converNumberToArrayOfInterger,
    isLinkExel,
    linkDocument,
    useWindowDimensions,
} from "@utils";
import { StyledToogleWorkScheduleContent } from ".";
import { AppIconButton, AppModal, CircularLoader, StyledIframe, StyledPDF } from "..";

type Anchor = "top" | "left" | "bottom" | "right";

interface IProps {
    openToogle?: boolean;
    anchor?: any;
    handleCloseToggle?: () => void;
    data?: any;
}
export const WorkScheduleToggleModal = (props: IProps) => {
    const { openToogle, anchor, handleCloseToggle, data } = props;
    //  HOOKS
    const width = useWindowDimensions().width;
    // component state
    const [openPDF, setOpenPDF] = React.useState<boolean>(false);
    const [linkPdf, setLinkPdf] = React.useState<any>("");
    // events
    const handleClosePDF = () => {
        setOpenPDF(false);
        setLinkPdf("");
    };
    const handleOpenPDF = (value: any) => {
        setLinkPdf(value);
        setOpenPDF(true);
    };
    const [numPages, setNumPages] = React.useState(null);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const List = (anchor: Anchor) => {
        // redux states
        return (
            <Box
                sx={{
                    width:
                        anchor === "top" || anchor === "bottom"
                            ? "auto"
                            : width > 768
                            ? WIDTH_DRAWER_PC
                            : WIDTH_DRAWER_MOBILE,
                }}
                role="presentation"
            >
                <StyledToogleWorkScheduleContent>
                    <div className="toggle__top">
                        <p className="toggle__top-collapse">
                            <AppIconButton
                                onClick={handleCloseToggle}
                                icon={<img src={arrow_toggle} />}
                            />
                        </p>
                    </div>
                    <Divider />
                    <div className="toggle__info">
                        <div className="">
                            <p className="subText">担当</p>
                            <p className="mainText">{data?.employee?.name}</p>
                        </div>
                        <div className="">
                            <p className="subText">工事名(略名)</p>
                            <p className="mainText">{data?.name}</p>
                        </div>
                        <div className="">
                            <p className="subText">工期</p>
                            <p
                                className="mainText"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingRight: "1rem",
                                }}
                            >
                                <span>{data?.start_date}</span>
                                <span>{data?.end_date}</span>
                            </p>
                        </div>
                    </div>
                    <Divider />
                    <div className="toggle__content">
                        {!!data?.document &&
                            data?.document?.map((item) => (
                                <FileItem
                                    item={item}
                                    handleOpenPDF={handleOpenPDF}
                                    handleClosePDF={handleClosePDF}
                                />
                            ))}
                    </div>
                </StyledToogleWorkScheduleContent>
            </Box>
        );
    };
    return (
        <div>
            <AppModal open={openPDF} handleClose={handleClosePDF}>
                <StyledPDF>
                    {linkPdf ? (
                        isLinkExel(linkPdf) ? (
                            <p className="failePDF">{DOCUMENT_EXCEL_FAILED_TO_LOAD}</p>
                        ) : width >= 768 ? (
                            <StyledIframe
                                heightCalculationMethod="lowestElement"
                                inPageLinks
                                src={linkDocument(linkPdf)}
                                log
                                scrolling={true}
                            />
                        ) : (
                            <Document
                                file={linkDocument(linkPdf)}
                                options={{ workerSrc: "/pdf.worker.js" }}
                                onLoadSuccess={onDocumentLoadSuccess}
                                error={DOCUMENT_FAILED_TO_LOAD}
                                loading={MESSAGE_LOADING_PDF}
                            >
                                {numPages &&
                                    converNumberToArrayOfInterger(numPages).map((page, index) => (
                                        <Page pageNumber={index} />
                                    ))}
                            </Document>
                        )
                    ) : (
                        <p className="failePDF">{DOCUMENT_FAILED_TO_LOAD}</p>
                    )}
                </StyledPDF>
            </AppModal>
            <React.Fragment key={anchor}>
                <Drawer anchor={anchor} open={openToogle} onClose={handleCloseToggle}>
                    {List(anchor)}
                </Drawer>
            </React.Fragment>
        </div>
    );
};

interface IFileItem {
    item?: any;
    handleOpenPDF: (value: any) => void;
    handleClosePDF: () => void;
}
export const FileItem = (props: IFileItem) => {
    const { item, handleOpenPDF, handleClosePDF } = props;
    // component state
    const [downloadProcess, setDownloadProcess] = React.useState<boolean>(false);
    // download file
    const handleDownloadPdf = (url: any, fileName: string) => {
        const urlSplit = url.split(".");
        const typeFile = urlSplit[urlSplit.length - 1];
        handleClosePDF();
        setDownloadProcess(true);
        axios({
            url: linkDocument(url),
            method: "GET",
            responseType: "blob",
        })
            .then((response) => {
                setDownloadProcess(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${fileName}.${typeFile}`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(() => {
                setDownloadProcess(false);
            });
    };
    return (
        <div className="file">
            <div className="file__icon">
                <img src={file_icon} />
            </div>
            <div className="file__name">{item?.title}</div>
            <div className="file__btn">
                <AppIconButton onClick={() => handleOpenPDF(item?.path)} icon={<EyeIcon />} />
            </div>
            <div className="file__btn">
                {downloadProcess ? (
                    <CircularLoader loading={downloadProcess} type="fullContent" size={15} />
                ) : (
                    <AppIconButton
                        onClick={() => handleDownloadPdf(item?.path, item?.title)}
                        disabled={downloadProcess}
                        icon={<DownLoadIcon />}
                    />
                )}
            </div>
        </div>
    );
};
