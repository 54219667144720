import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { categoriesApi } from "@api";

interface IState {
    categories: any;
    loadingCategories: boolean;
    inventory: any;
    loadingInventory: boolean;

    dataResult?: any;
    processing: boolean;
    activeCategory: any;
    idConstuctionInventorySearch?: string;
}

const initialState: IState = {
    categories: [],
    loadingCategories: false,
    inventory: [],
    loadingInventory: false,
    processing: false,
    activeCategory: false,
};

// Async Thunks for fetching data
export const fetchInventoryCategories = createAsyncThunk(
    "inventory/fetchInventoryCategories",
    async (_, { rejectWithValue }) => {
        try {
            const response = await categoriesApi.getCategories({ type: 1 });
            return response.data;
        }
        catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const fetchInventory = createAsyncThunk(
    "inventory/fetchInventory",
    async (_, { rejectWithValue }) => {
        try {
            const response = await categoriesApi.getListProducts({});
            return response.data;
        }
        catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    }
);

const inventorySlice = createSlice({
    name: "inventory",
    initialState: initialState,
    reducers: {
        setCategory: (state, action: { payload: any }) => {
            state.dataResult = action.payload;
            // state.activeCategory = action.payload.category;
        },
        setActiveCategory: (state, action: { payload: any }) => {
            state.activeCategory = action.payload.category;
        },
        setIdConstuctionInventorySearch: (state, action: { payload: string }) => {
            state.idConstuctionInventorySearch = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInventoryCategories.pending, (state) => {
                state.loadingCategories = true;
            })
            .addCase(fetchInventoryCategories.fulfilled, (state, action) => {
                state.loadingCategories = false;
                state.categories = action.payload;
            })
            .addCase(fetchInventoryCategories.rejected, (state) => {
                state.loadingCategories = false;
            });
    },
});

export const selectInventoryCategoriesLoading = (state: RootState) => state.inventory.loadingCategories;
export const selectInventoryCategories = (state: RootState) => state.inventory.categories;

export const selectInventoryLoading = (state: RootState) => state.inventory.loadingInventory;
export const selectInventory = (state: RootState) => state.inventory.inventory;

export const { setCategory, setActiveCategory, setIdConstuctionInventorySearch } = inventorySlice.actions;

export default inventorySlice.reducer;
