import { TimeSheetFormContextProvider } from "../../context";
import { TimeSheetHalfDay } from "./time-sheet-half-day";

export function TimeSheetHalfDayWrapper() {
    return (
        <TimeSheetFormContextProvider>
            <TimeSheetHalfDay />
        </TimeSheetFormContextProvider>
    );
}
