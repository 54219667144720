import axiosClient from "./axiosClient";

interface IInventoryDetailParams {
    id: number;
}
export const inventoryApi = {
    viewInventoryDetail: (params: IInventoryDetailParams) => {
        const url = `/api/products/${params.id}`;
        return axiosClient.get(url);
    },
    borrowingInventoryProduct: (params: any) => {
        const url = `/api/borrowing-product`;
        return axiosClient.post(url, params); 
    },
    returnInventoryProduct: (params: any) => {
        const url = `/api/returned-product`;
        return axiosClient.post(url, params);
    },
    
};
