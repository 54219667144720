import { OverTimeIcon } from "@assets";
import { IconButton } from "@mui/material";
import React from "react";

import red_dot from "@assets/Icons/Notification_Icons/red_dot.svg";
import trash_icon from "@assets/Images/trash_icon.png";

import { StyledTechnicalDocumentsItem } from ".";

interface IProps {
    data?: any;
    handleOnSelectTechnicalDocuments: (values: any) => void;
    handleDeleteTechnicalDocuments: () => void;
}
export const TechnicalDocumentsItem = (props: IProps) => {
    const { data, handleOnSelectTechnicalDocuments, handleDeleteTechnicalDocuments } = props;
    return (
        <StyledTechnicalDocumentsItem>
            <div className="noti__item">
                <div
                    className="noti__item-content"
                    onClick={() =>
                        handleOnSelectTechnicalDocuments({
                            type: data?.type,
                            id: data?.id,
                            status: data?.status,
                            otID: data?.type?.otID,
                            tsID: data?.type?.tsID,
                            timesheetType: data?.type?.timesheet_type,
                        })
                    }
                >
                    <div className="noti__item-icon">
                        {/* {data?.type?.type === NotificationTypeEnums.TYPE_REQUEST_OT && ( */}
                        <IconButton className="icon__overtime" size="medium" disableRipple>
                            <OverTimeIcon />
                        </IconButton>
                        {/* )} */}
                    </div>
                    <div className="noti__item-title">{data.title}</div>
                    <div className="noti__item-description">{data.content}</div>
                </div>
                <img
                    onClick={handleDeleteTechnicalDocuments}
                    src={trash_icon}
                    className="noti__item-deleteIcon"
                />
                <div className="noti__item-status">
                    {!data.read ? (
                        <span>
                            <img src={red_dot} alt="icon" />
                        </span>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </StyledTechnicalDocumentsItem>
    );
};
