import moment from "moment";
import React from "react";
import { logItemType } from "src/types";

interface IProps {
  logItem: logItemType;
}
export const ItemComponent = (props: IProps) => {
  const { logItem } = props;
  
  if(!logItem) {
    return (<span>Loading</span>);
  };

  const startDate =
    logItem.start_reservation && moment(logItem.start_reservation).format("YYYY-MM-DD");
  const endDate = logItem.end_reservation && moment(logItem.end_reservation).format("YYYY-MM-DD");
  const oldStartDate =
    logItem.pre_start_reservation && moment(logItem.pre_start_reservation).format("YYYY-MM-DD");
  const oldEndDate =
    logItem.pre_end_reservation && moment(logItem.pre_end_reservation).format("YYYY-MM-DD");
  const date = logItem.date && moment(logItem.date).format("YYYY-MM-DD");
  const displayDate = logItem.date && moment(logItem.date).format("YYYY/MM/DD HH:mm");


  const isCrossedout = logItem.note === "取消";

  return (
    <div className={`item ${isCrossedout ? 'crossed-out' : ''}`}>
      <div className="item__top">
        <div>
          <p className="item__title" style={{ whiteSpace: "pre-line" }}>
            {logItem.note}: {logItem.name}
          </p>

          {(logItem.note !== "取消" && logItem.returning_inventory !== null) && (
            <>
              <p className="item__amount">+ {logItem.returning_inventory}</p>
              <p className="item__amount">合計 {logItem.quantity}</p>
            </>
          )}

          {(logItem.note !== "取消" && logItem.taking_inventory !== null) && (
            <>
              <p className="item__amount">- {logItem.taking_inventory}</p>
              <p className="item__amount">合計 {logItem.quantity}</p>
            </>
          )}
          <p className="item__amount">
            {logItem.last_name} {logItem.first_name}
          </p>
          <p className="item__amount">現場名: {logItem.construction_name}</p>
          <p className="item__amount">{displayDate}</p>
        </div>

        <span className="item__code">
          <div>
            {(oldStartDate && oldStartDate !== startDate && logItem.note !== "取消") && (
              <div style={{ textDecoration: "line-through" }}>{oldStartDate}</div>
            )}
            {startDate || date}
            <p style={{ fontSize: 18 }}>{endDate && "≀"}</p>
          </div>
          <div>
            {(oldEndDate && oldEndDate !== endDate && logItem.note !== "取消") && (
              <div style={{ textDecoration: "line-through" }}>{oldEndDate}</div>
            )}
            {endDate}
            {/* <p>{endDate && " 返却"}</p> */}
          </div>
        </span>
      </div>
    </div>
  );
};
