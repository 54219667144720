import { Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useDispatch } from "react-redux";

import { getMenus, resetMenu, selectMenu, useAppSelector } from "@redux";
import { StyledModalMenu } from ".";
import { linkImage } from "@utils";

export const ModalMenu = () => {
    const menus = useAppSelector(selectMenu);
    const { dataMenu } = menus;
    // hooks
    const dispatch = useDispatch();
    // event choose item in menu
    const handleOnClick = (link: string) => {
        window.location.href = link;
        dispatch(resetMenu());
    };
    React.useEffect(() => {
        dispatch(getMenus({ dispatch }));
    }, [dispatch]);
    return (
        <StyledModalMenu>
            <Grid container spacing={3}>
                {dataMenu ? (
                    dataMenu.map((menu: any) => (
                        <Grid item xs={4} key={menu.id} sm={4} md={2} lg={2}>
                            <div className="menu__item" onClick={() => handleOnClick(menu.link)}>
                                <Box style={{ display: "flex", justifyContent: "center" }}>
                                    <div className="menu__wrapperImage">
                                        <img
                                            className="menu__image"
                                            src={linkImage(menu?.icon?.path)}
                                        />
                                    </div>
                                </Box>
                                <p className="menu__title">
                                    {/* {ellipsisText(menu?.name, NUMBER_TEXT_MENU)} */}
                                    {menu?.name}
                                </p>
                            </div>
                        </Grid>
                    ))
                ) : (
                    <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                    </Box>
                )}
            </Grid>
        </StyledModalMenu>
    );
};
