import React from "react";
import { CircularProgress } from "@mui/material";

import { StyledCircularLoader } from ".";
import { AppModal } from "..";

type Props = {
    loading?: boolean;
    type?: "fullLayout" | "fullContent";
    text?: string;
    size?: number;
};

export const CircularLoader = (props: Props) => {
    const { type = "fullLayout", size = 25, loading } = props;
    if (loading) {
        return (
            <StyledCircularLoader>
                <div className="loading">
                    {type === "fullLayout" ? (
                        <AppModal open={loading}>
                            <CircularProgress value={size} />
                        </AppModal>
                    ) : (
                        <CircularProgress value={size} />
                    )}
                </div>
            </StyledCircularLoader>
    );
    }
    return null;
};
