import { COLOR_ICON_GRAY } from "@configs";
import { IIconProps } from ".";

export const LockIcon = (props: IIconProps) => {
    const { color = COLOR_ICON_GRAY } = props;
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5 8.33341V6.66675C5 3.90841 5.83333 1.66675 10 1.66675C14.1667 1.66675 15 3.90841 15 6.66675V8.33341"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.0001 15.4167C11.1507 15.4167 12.0834 14.4839 12.0834 13.3333C12.0834 12.1827 11.1507 11.25 10.0001 11.25C8.84949 11.25 7.91675 12.1827 7.91675 13.3333C7.91675 14.4839 8.84949 15.4167 10.0001 15.4167Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.1667 18.3333H5.83341C2.50008 18.3333 1.66675 17.4999 1.66675 14.1666V12.4999C1.66675 9.16659 2.50008 8.33325 5.83341 8.33325H14.1667C17.5001 8.33325 18.3334 9.16659 18.3334 12.4999V14.1666C18.3334 17.4999 17.5001 18.3333 14.1667 18.3333Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
