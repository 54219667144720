export const OverTimeIcon = () => {
    return (
        <>
            <svg
                width="30"
                height="29"
                viewBox="0 0 30 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.591 7.27777H17.4058C19.8132 7.27777 19.8132 6.07406 19.8132 4.87036C19.8132 2.46295 18.6095 2.46295 17.4058 2.46295H12.591C11.3873 2.46295 10.1836 2.46295 10.1836 4.87036C10.1836 7.27777 11.3873 7.27777 12.591 7.27777Z"
                    stroke="#0068C9"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.8142 4.89441C23.8225 5.11108 25.8327 6.59163 25.8327 12.0926V19.3148C25.8327 24.1296 24.629 26.537 18.6105 26.537H11.3882C5.36972 26.537 4.16602 24.1296 4.16602 19.3148V12.0926C4.16602 6.60367 6.1762 5.11108 10.1845 4.89441"
                    stroke="#0068C9"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
