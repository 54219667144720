import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";

interface IState {
    page?: number;
}

const initialState: IState = {
    page: 1,
};

const workScheduleSlice = createSlice({
    name: "workSchedule",
    initialState: initialState,
    reducers: {
        setPage: (state, action: { payload: any }) => {
            state.page = action.payload;
        },
    },
});

export const selectPage = (state: RootState) => state.workSchedule;

export const { setPage } = workScheduleSlice.actions;

export default workScheduleSlice.reducer;
