import React from "react";
import { useHistory } from "react-router-dom";

import { appRouteEnums, menuTitleEnums } from "@enums";
import { AppTopBar, TitleMenuComponent, AppDatePicker, AppIconButton } from "..";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import { StyleTimeSheet } from ".";
import AlcoholCheckerTable from "./AlcoholCheckerTable";

export const AlcoholCheck = () => {
    // hooks
    const history = useHistory();
    // component state
    const [date, setDate] = React.useState(new Date());
    // events
    const handleChange = (date: any) => {
        setDate(date);
    };

    return (
        <StyleTimeSheet>
            <AppTopBar />
            {/* <CircularLoader loading={loading} /> */}
            <div className="timesheet">
                <p
                    className="newOT__top-icon"
                    onClick={() => history.push(appRouteEnums.PATH_HOME)}
                >
                    <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                </p>
                <div className="timesheet__title">
                    <TitleMenuComponent text={menuTitleEnums.TITLE_ALCOHOL_CHECK} />
                </div>
                <div className="timesheet__datePicker">
                    <div className="label">作成日時</div>
                    <div className="wrapperDatePicker">
                        <AppDatePicker
                            date={date}
                            handleChange={handleChange}
                            format="yyyy/MM/dd"
                            views={["year", "month", "day"]}
                        />
                    </div>
                </div>
                <AlcoholCheckerTable date={date} />
            </div>
        </StyleTimeSheet>
    );
};
