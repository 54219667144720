import styled from "styled-components";

export const StyledSchedule = styled.div`
    position: relative;
`;
export const StyledAppCalendar = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    .rbc-row-content {
        height: 100%;
    }
    .rbc-month-view {
        border: 1px solid transparent !important;
    }
    .rbc-event-content {
        height: 100%;
    }
    .rbc-calendar {
        width: 400px;
    }
    .rbc-row-bg {
        height: 100%;
    }
    .rbc-row-content {
        max-height: 5rem;
    }
    .rbc-event {
        background: #f0f0f0 !important;
        border-radius: 24px 0px 0px 24px !important;
    }
    .rbc-off-range-bg {
        background: transparent;
    }
    .MuiButton-root {
        width: 100%;
    }
    .label-date {
        display: none;
    }
    .back-next-buttons {
        display: flex;
        justify-content: space-between;
        .btn-current {
            display: flex;
            align-items: center;
        }
    }
`;
