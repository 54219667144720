import React from "react";
import { Grid } from "@mui/material";

import { NotFoundItemView, CircularLoader } from "..";
import { useAppSelector, selectInstrument } from "@redux";
import { StyledInstrumentsListItemComponent, InstrumentItemComponent } from ".";
interface IProps {
    loading: boolean;
    products: any;
    categoryId?: any;
}
export const InstrumentsListItemComponent = (props: IProps) => {
    const { loading, products, categoryId } = props;
    // redux store
    const instrument = useAppSelector(selectInstrument);
    const { dataResult } = instrument;
    return (
        <StyledInstrumentsListItemComponent>
            {dataResult?.category && (
                <div className="Instruments__title">
                    {dataResult?.category} ・ {dataResult?.subCategory}{" "}
                </div>
            )}
            {loading ? (
                <div className="wrapperLoading">
                    <CircularLoader loading={loading} type="fullContent" />
                </div>
            ) : products?.length ? (
                <div className="Instruments__content">
                    <Grid container spacing={3}>
                        {products?.map((item) => (
                            <Grid key={item.id} item xs={12} sm={12} md={6}>
                                <InstrumentItemComponent categoryId={categoryId} item={item} />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ) : (
                <div className="error">
                    <NotFoundItemView />
                </div>
            )}
        </StyledInstrumentsListItemComponent>
    );
};
