import styled from "styled-components";

export const StyledAlert = styled.div`
    padding: 0 2rem;
    .alert {
        &__content {
            position: relative;
            background-color: #343942;
            border-radius: 1.5rem;
            padding: 3.5rem 4.5rem;
            z-index: 1;
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                padding: 3.5rem 10rem;
            }
        }
        &__cancelIcon {
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
        }
        &__cancelIconBg {
            position: absolute;
            right: 0;
            top: 0;
        }
        &__groupIcon {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 0;
            @media (min-width: ${(p) => p.theme.breakPoints.breakTablet}) {
                left: 20%;
            }
            @media (min-width: ${(p) => p.theme.breakPoints.breakMedium}) {
                left: 0;
                width: 95%;
            }
        }
        &__text {
            font-size: 2.6rem;
            text-align: center;
            font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
            color: ${(p) => p.theme.colors.secondary};
        }
        &__icon {
            padding-top: 2.2rem;
            display: flex;
            justify-content: center;
        }
    }
`;
export const StyledAlertConfirm = styled.div`
    .alertConfirm__wrapper {
        min-width: 30rem;
    }
    .alert__info {
        position: relative;
        overflow: hidden;
        z-index: 1;
        padding: 0 2rem;
        .alert__bg {
            position: absolute;
            top: 0;
            left: 1.5rem;
            z-index: -1;
            width: 90%;
            img {
                width: 100%;
            }
        }
    }
    .info {
        &__content {
            width: 100%;
            padding: 0 1.5rem 1rem 1.5rem;
            display: flex;
            justify-content: space-between;
        }
        &__icon {
            border: 1px solid black;
            border-radius: 1rem;
            height: 8rem;
            width: 9rem;
            background-color: white;
        }

        &__text {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            text-align: right;
            &-main {
                font-size: 1.8rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                color: ${(p) => p.theme.colors.darkBlack};
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                max-width: 18rem;
                margin-bottom: 1rem;
            }
            &-sub {
                font-size: 1.2rem;
                color: #8e8e8e;
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                max-width: 18rem;
            }
            &-product {
                max-width: 10rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                font-size: 1.6rem;
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
            }
            &-amount {
                display: grid;
                /* justify-content: flex-end; */
                font-size: 1.6rem;
                grid-template-columns: 1fr auto auto;
                grid-column-end: auto;
                gap: 1rem;
                /* column-gap: 1rem; */
                padding-top: 0.5rem;
            }
            .takenAmount {
                color: #81e1ad;
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                max-width: 7rem;
                height: 20px;
            }
            .addedAmount {
                color: #ff2e00;
                display: block;
                display: -webkit-box;
                line-height: 1.2;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                max-width: 7rem;
                height: 20px;
            }
        }
    }
    .alert__confirm {
        padding: 2rem;
        background-color: #343942;
        border-radius: 1.6rem;
        &-title {
            text-align: center;
            font-size: 2.6rem;
            font-family: ${(p) => p.theme.fontFamily.NotoSansJP_700};
            color: ${(p) => p.theme.colors.secondary};
        }
        &-line {
            margin-top: 2rem;
        }
        &-btn {
            display: grid;
            padding-top: 2rem;
            gap: 10px;
            grid-template-columns: auto auto;
            .button {
                font-size: 1.6rem;
                width: 100%;
                text-align: center;
                padding: 1.5rem 0;
                color: #d4d4d4;
                border: 1px solid #d4d4d4;
                border-radius: 1rem;
                font-family: ${(p) => p.theme.fontFamily.NotoSansJP_500};
                cursor: pointer;
                &:hover {
                    background-color: ${(p) => p.theme.colors.primary};
                    color: ${(p) => p.theme.colors.darkBlack};
                }
            }
        }
    }
`;
