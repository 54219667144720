import React from "react";

import not_found_item_icon from "@assets/Icons/not_found_item.svg";

import { StyledNotFoundItem } from ".";

export const NotFoundItemView = () => {
    return (
        <StyledNotFoundItem>
            <div className="wrapContent">
                <div className="wrapIcon">
                    <img className="icon" src={not_found_item_icon} alt="icon" />
                </div>
                <p className="message">カテゴリを選択して製品を検索するか、名前で検索できます</p>
            </div>
        </StyledNotFoundItem>
    );
};
