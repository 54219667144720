import React from "react";
import clsx from "clsx";
import moment from "moment";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";

import tick_icon from "@assets/Icons/tick_icon.svg";
import { timesheetApi, workScheduleApi } from "@api";
import { PLACEHOLDER_CONSTRACTOR } from "@configs";
import { menuTitleEnums } from "@enums";
import { checkExistUserSelected, linkImage } from "@utils";
import { TableWrapViewWorkSchedule } from "./TableWrapViewWorkSchedule";
import { ViewWrapGraphCalendar } from "./ViewWrapGraphCalendar";
import { TitleMenuComponent, AppCustomeSelect, AppDatePicker, CircularLoader } from "..";
import { selectAuth, useAppSelector } from "@redux";

const defaultGroup = {
    id: 0,
    name: "全て",
};

// interface IProps {
//     handleCollapse: () => void;
// }
export const WorkScheduleWrap = () => {
    const auth = useAppSelector(selectAuth);
    const id = auth?.userInfo?.id;

    // const { handleCollapse } = props;
    const [toggle, setToggle] = React.useState<number>(0);
    const [date, setDate] = React.useState(new Date());
    const [selectedGroup, setSelectedGroup] = React.useState<any>([]);
    const [loading, setLoading] = React.useState(false);
    const [dataWorkSchedule, setDataWorkSchedule] = React.useState<any>([]);
    const [optionEmployee, setOptionEmployee] = React.useState<any[]>([]);
    const [groups, setGroups] = React.useState<any>([]);
    const [selectSubContractor, setSelectSubContractor] = React.useState<any>("");
    const [users, setUsers] = React.useState<any>([]);
    const [employeeInput, setEmployeeInput] = React.useState<any>("");

    React.useEffect(() => {
        fetchListWorkSchedules();
        return () => {
            // do something with cleanup
        };
    }, [date, selectedGroup, selectSubContractor, employeeInput]);

    React.useEffect(() => {
        fetchListGroups();
        fetchListUsers();
        return () => {
            // do something with cleanup
        };
    }, []);

    const fetchListGroups = async () => {
        try {
            const responseGroups = await timesheetApi.listGroupsTimesheet();
            setGroups(responseGroups.data);
        } catch (error) {
        } finally {
            //
        }
    };

    const fetchListUsers = async () => {
        try {
            const responseListUsers = await timesheetApi.getListContractors();
            setUsers(responseListUsers?.data?.data?.contractors);
        } catch (error) {
        } finally {
            //
        }
    };
    const fetchListWorkSchedules = async () => {
        setLoading(true);

        try {
            setSelectedGroup(selectedGroup ? selectedGroup : 0);

            const response = await workScheduleApi.getOverViewWorkSchedule(
                {
                    month: date && moment(date).format("YYYY-MM"),
                    userId: selectSubContractor?.value,
                    employeeId: employeeInput?.value,
                },
                selectedGroup
            );

            if (response?.data?.success) {
                setDataWorkSchedule(response?.data?.data);
                const responseEmployees = (!response?.data?.data || !Array.isArray(response.data.data)) ? [] : response?.data.data.map((item) => ({ id: item?.employee?.id, first_name: item?.employee?.first_name, last_name: item?.employee?.last_name, avatar: item?.avatar }));
                const filteredSortedEmployees = responseEmployees
                    //.filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id)))
                    .sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0));
                setOptionEmployee(filteredSortedEmployees);
                //setOptionEmployee(response?.data.data.map((item) => ({ id: item?.employee?.id, first_name: item?.employee?.first_name, last_name: item?.employee?.last_name, avatar: item?.avatar })));
                setLoading(false);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const handleSelectGroup = (id) => {
        setSelectSubContractor("");
        if (id === 0) {
            setSelectedGroup([]);
            return;
        }
        setSelectedGroup(checkExistUserSelected(id, selectedGroup) ? selectedGroup.filter((e) => e !== id) : [...selectedGroup, id]);
    };

    const handleChangeMonth = (date: any) => {
        setDate(date);
        setSelectedGroup([]);
        setSelectSubContractor("");
    };

    const handleOnChange = (e) => {
        setEmployeeInput(e);
    };

    const handleOnChangeSubContracter = (id) => {
        setSelectSubContractor(id);
    };
    return (
        <div>
            <CircularLoader loading={loading} />

            <div className="workSchedule__title">
                <TitleMenuComponent text={menuTitleEnums.TITLE_WORK_SCHEDULE} />
            </div>
            <div className="workSchedule__toggle">
                <span className={clsx(toggle === 0 ? "activeToggle" : "", "left")} onClick={() => setToggle(0)}>
                    テーブルビュー
                </span>
                <span className={clsx(toggle === 1 ? "activeToggle" : "", "right")} onClick={() => setToggle(1)}>
                    グラフビュー
                </span>
            </div>

            <div className="workSchedule__form mb-5">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div className="workSchedule__select">
                            <div className="wrapperSelect">
                                {!!users?.length && (
                                    <AppCustomeSelect
                                        placeholder={PLACEHOLDER_CONSTRACTOR}
                                        onChange={handleOnChangeSubContracter}
                                        groupUserOptions={
                                            users?.length &&
                                            users.map((user) => {
                                                return {
                                                    label: `${user.name}`,
                                                    value: user?.id?.toString(),
                                                    avatar: linkImage(user?.avatar?.path),
                                                };
                                            })
                                        }
                                        options={
                                            users?.length &&
                                            users.map((user) => {
                                                return {
                                                    label: `${user.name}`,
                                                    value: user?.id?.toString(),
                                                    avatar: linkImage(user?.avatar?.path),
                                                };
                                            })
                                        }
                                        isClearable
                                        value={selectSubContractor}
                                    />
                                )}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="workSchedule__filter">
                            <div onClick={() => handleSelectGroup(defaultGroup.id)} className={clsx(!selectedGroup.length ? "active" : "", "workSchedule__filter-item")}>
                                <span className="title">{defaultGroup.name}</span>
                                <Box sx={{ display: "flex", alignItems: "center" }}>{!selectedGroup.length && <img src={tick_icon} alt="icon" />}</Box>
                            </div>
                            {groups &&
                                groups.map((item) => (
                                    <div
                                        key={item.id}
                                        onClick={() => {
                                            handleSelectGroup(item.id);
                                        }}
                                        className={clsx(checkExistUserSelected(item.id, selectedGroup) ? "active" : "", "workSchedule__filter-item")}
                                    >
                                        <span className="title">{item.name}</span>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            {checkExistUserSelected(item.id, selectedGroup) && <img src={tick_icon} alt="icon" />}
                                        </Box>
                                    </div>
                                ))}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={7} md={6}>
                            <label className="label">従業員</label>
                            <div className="input select">
                                <AppCustomeSelect
                                    placeholder="人を選択する"
                                    onChange={handleOnChange}
                                    groupUserOptions={
                                        optionEmployee?.length &&
                                        [...optionEmployee].filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id))).sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                            return {
                                                label: `${user.last_name}${user.first_name}`,
                                                value: user?.id?.toString(),
                                                avatar: linkImage(user?.avatar?.path),
                                            };
                                        })
                                    }
                                    options={
                                        optionEmployee?.length &&
                                        [...optionEmployee].filter((user: any, index: number, self: any[]) => (user.id === null || user.id === undefined || index === self.findIndex((u: any) => u.id === user.id))).sort((a: any, b: any) => (a.id === id ? -1 : b.id === id ? 1 : 0)).map((user: any) => {
                                            return {
                                                label: `${user.last_name} ${user.first_name}`,
                                                value: user?.id?.toString(),
                                                avatar: linkImage(user?.avatar?.path),
                                            };
                                        })
                                    }
                                    isClearable
                                    value={employeeInput}
                                    isShowAvatar
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="wrapperInput">
                            <div className="workSchedule__datePicker">
                                <div className="label">日付</div>
                                <div className="wrapperDatePicker">
                                    <AppDatePicker date={date} handleChange={handleChangeMonth} format="yyyy/MM" views={["year", "month"]} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="workSchedule__content">
                <div className="visible__mobile">
                    {toggle === 0 && <TableWrapViewWorkSchedule hasCollapse={true} data={dataWorkSchedule} />}
                    {toggle === 1 && <ViewWrapGraphCalendar date={date} data={dataWorkSchedule} />}
                </div>
                <div className="visible__desktop" style={{ marginTop: "40px" }}>
                    {/* <span className="btnCollaspe">
                        <AppIconButton icon={<ArrowLeftIcon />} onClick={handleCollapse} />
                    </span> */}
                    <div className="wrapperDesktopContent">
                        <TableWrapViewWorkSchedule hasCollapse={true} data={dataWorkSchedule} />

                        <div className="wrapperDesktopContent__graphView">
                            <ViewWrapGraphCalendar date={date} data={dataWorkSchedule} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
