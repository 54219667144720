import React from "react";
import styled from "styled-components";

import yellow_lines_icon from "@assets/Images/yellow_lines.png";

interface IProps {
    text: string;
}
export const TitleMenuComponent = (props: IProps) => {
    const { text } = props;
    return (
        <StyledTitleMenuComponent>
            <span className="text">{text}</span>
            <div className="line">
                <img className="line__icon" src={yellow_lines_icon} />
            </div>
        </StyledTitleMenuComponent>
    );
};

const StyledTitleMenuComponent = styled.div`
    /* width: 100%; */
    position: relative;
    z-index: 0;
    display: inline-block;
    margin-top: 20px;
    .text {
        font-size: 32px;
        font-family: ${(p) => p.theme.fontFamily.MochiyPopOne_Regular};
        color: ${(p) => p.theme.colors.black};
    }
    .line {
        position: absolute;
        width: 100%;
        z-index: -1;
        bottom: 0;

        &__icon {
            width: 100%;
            height: 6px;
        }
    }
`;
