import { TimeSheetFormContextProvider } from "../../context";
import { TimeSheetNightShift } from "./time-sheet-night-shift";

export function TimeSheetNightShiftWrapper() {
    return (
        <TimeSheetFormContextProvider>
            <TimeSheetNightShift />
        </TimeSheetFormContextProvider>
    );
}
