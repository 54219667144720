import React from "react";
import { RadioGroup, Divider, FormControlLabel, Radio } from "@mui/material";

import { useAppSelector, selectNoti, updateNoti, updateNumberNoti, resetNoti, selectAuth } from "@redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import { StyledNotificationComponent, NotificationItem } from ".";
import { AppTopBar, CircularLoader, AppIconButton } from "..";
import active_arrow_down from "@assets/Icons/active_arrow_down.svg";
import { appRouteEnums, ApproveTypeEnums, FilterNotificationEnums, NotificationLinkEnums, NotificationTypeEnums } from "@enums";
import { notiApi } from "@api";
import { MESSAGE_DATA_NOT_FOUND } from "@configs";
import mobile_arrow_left from "@assets/Icons/arrow/mobile_arrow_left.svg";
import styled from "styled-components";

export const NotificationView = () => {
    // redux state
    const notifications = useAppSelector(selectNoti);
    const user = useAppSelector(selectAuth);
    const { userInfo } = user;
    // component state
    const [radioValue, setRadioValue] = React.useState<any>(3);
    const [loadMore, setLoadMore] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState<number>(1);
    const [reload, setReload] = React.useState<any>();
    const [selectedNotifications, setSelectedNotifications] = React.useState<any>([]);

    // hooks
    const history = useHistory();
    const dispatch = useDispatch();
    //what: events
    const handleOnSelectNoti = async (values) => {
        if (values.type?.type === NotificationTypeEnums.TYPE_INSTRUMENT || values.type?.type === NotificationTypeEnums.TYPE_VEHICLE) {
            if (values.status === 1) {
                history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
            } else {
                const res = await notiApi.updateStatusNotification({ id: values.id });
                if (res.status === 200) {
                    dispatch(updateNumberNoti(notifications.numberNoti === 0 ? notifications.numberNoti : notifications.numberNoti - 1));
                    history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
                }
            }
            // } else if (values.type?.type === NotificationTypeEnums.TYPE_REQUEST_OT) {
            //     if (values.status === 1) {
            //         history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otID}`);
            //     } else {
            //         const res = await notiApi.updateStatusNotification({ id: values.id });
            //         if (res.status === 200) {
            //             dispatch(
            //                 updateNumberNoti(
            //                     notifications.numberNoti === 0
            //                         ? notifications.numberNoti
            //                         : notifications.numberNoti - 1
            //                 )
            //             );
            //             history.push(`/${NotificationLinkEnums.REQUEST_OT}`);
            //         }
            //     }
        } else if (values.type?.type === NotificationTypeEnums.TYPE_REQUEST_OT) {
            if (values.status === 1) {
                history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otID}?noti=${values.id}&status=${values.status}`);
            } else {
                history.push(`${appRouteEnums.PATH_OVERTIME}/${values.otID}?noti=${values.id}&status=${values.status}`);
            }
        } else if (values.type?.type === NotificationTypeEnums.TYPE_REQUEST_TIMESHEET) {
            if (values.status === 1) {
                if (values.type?.creator === userInfo?.id) {
                    if (values.type?.status === ApproveTypeEnums.REJECTED) {
                        history.push(`${appRouteEnums.PATH_TIMESHEET}/edit/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
                    } else {
                        history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
                    }
                } else {
                    history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
                }
            } else {
                // const res = await notiApi.updateStatusNotification({ id: values.id });
                // if (res.status === 200) {
                // dispatch(
                //     updateNumberNoti(
                //         notifications.numberNoti === 0
                //             ? notifications.numberNoti
                //             : notifications.numberNoti - 1
                //     )
                // );

                if (values.type?.creator === userInfo?.id) {
                    if (values.type?.status === ApproveTypeEnums.REJECTED) {
                        history.push(`${appRouteEnums.PATH_TIMESHEET}/edit/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
                    } else {
                        history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
                    }
                } else {
                    history.push(`${appRouteEnums.PATH_TIMESHEET}/detail/${values.timesheetType}/${values.tsID}?noti=${values.id}&status=${values.status}`);
                }
                // }
            }
        } else if (values.type?.type === "return_vehicle") {
            if (values.status === 1) {
                history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
            } else {
                const res = await notiApi.updateStatusNotification(values?.id);
                if (res.status === 200) {
                    dispatch(updateNumberNoti(notifications.numberNoti === 0 ? notifications.numberNoti : notifications.numberNoti - 1));
                    history.push(`/${NotificationLinkEnums.RETURN_VEHICLE}`);
                }
            }
        } else if (values.type?.type === "change_temporary_to_offical") {
            if (values.status === 1) {
                history.push(`${appRouteEnums.PATH_OVERTIME}/edit/${values?.type?.otID}`);
            } else {
                const res = await notiApi.updateStatusNotification(values?.id);
                if (res.status === 200) {
                    dispatch(updateNumberNoti(notifications.numberNoti === 0 ? notifications.numberNoti : notifications.numberNoti - 1));
                    history.push(`${appRouteEnums.PATH_OVERTIME}/${values?.type?.otID}`);
                }
            }
        } else if (values.type.length === 0) {
            const res = await notiApi.updateStatusNotification(values?.id);
            if (res.status === 200) {
                setReload(values?.id);
                dispatch(updateNumberNoti(notifications.numberNoti === 0 ? notifications.numberNoti : notifications.numberNoti - 1));
            }
        }
    };
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
        dispatch(resetNoti());
        setPage(1);
    };

    const handleDeleteNoti = async (id: any) => {
        try {
            await notiApi.deleteNotification({
                id: id,
            });
            dispatch(resetNoti());
            setReload(!reload);
        } catch (err) {}
    };
    const handleToggleSelectNoti = (id: string) => {
        setSelectedNotifications((prevSelected) => {
            if (prevSelected.includes(id)) {
                // If the ID is already in the selected list, remove it
                return prevSelected.filter((selectedId) => selectedId !== id);
            } else {
                // If the ID is not in the selected list, add it
                return [...prevSelected, id];
            }
        });
    };
    const handleDeleteMultipleNoti = async () => {
        try {
            await notiApi.deleteMultipleNotification({
                ids: selectedNotifications,
            });

            setReload(!reload);
            setIsSelected(false);
            dispatch(resetNoti());
            setSelectedNotifications([]);
        } catch (err) {}
    };
    const fetchListNoti = async () => {
        try {
            setLoading(true);
            const res = await notiApi.viewListNotification({
                page: page,
                status: parseInt(radioValue) === 3 ? "" : radioValue,
            });
            if (page === 1) {
                dispatch(resetNoti());
            }
            dispatch(updateNoti(res.data.data.data));
            dispatch(updateNumberNoti(res.data.data.amount));
            setLoading(false);
            if (!res.data.data.paging.next_page_url) {
                setLoadMore(false);
            } else {
                setLoadMore(true);
            }
        } catch (err) {
            setLoading(false);
        }
    };
    const handleLoadMore = () => {
        setPage(page + 1);
    };
    React.useEffect(() => {
        fetchListNoti();
        return () => {
            //    do something cleanup
        };
    }, [page, radioValue, reload]);
    React.useEffect(() => {
        return () => {
            dispatch(resetNoti());
        };
    }, []);
    const [isSelected, setIsSelected] = React.useState(false);

    React.useEffect(() => {
        if (isSelected) {
            setSelectedNotifications(notifications.listNoti.map((noti) => noti.id));
        } else {
            setSelectedNotifications([]);
        }
    }, [notifications.listNoti, isSelected]);

    const handleSelectAll = (event) => {
        setIsSelected(event.target.checked);
    };

    return (
        <StyledNotificationComponent>
            <AppTopBar />
            <Divider />
            <CircularLoader loading={loading} />
            <div className="wrapperForm">
                <p className="top-icon" onClick={() => history.push(appRouteEnums.PATH_HOME)}>
                    <AppIconButton icon={<img src={mobile_arrow_left} alt="icon" />} />
                </p>
                <div className="form">
                    <div className="form__checkbox">
                        <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={radioValue} onChange={handleRadioChange}>
                            <FormControlLabel value={3} className={clsx(radioValue === 3 ? "active" : "")} control={<Radio />} label={FilterNotificationEnums.ENUMS_FILTER_ALL} />
                            <FormControlLabel value={0} control={<Radio />} label={FilterNotificationEnums.ENUMS_FILTER_UNREAD} className={clsx(radioValue === 0 ? "active" : "")} />
                            <FormControlLabel value={1} className={clsx(radioValue === 1 ? "active" : "")} control={<Radio />} label={FilterNotificationEnums.ENUMS_FILTER_READ} />
                        </RadioGroup>
                    </div>
                </div>
            </div>
            <div>
                {selectedNotifications.length > 0 && <DeleteMultiButton onClick={handleDeleteMultipleNoti}>選択した通知を削除</DeleteMultiButton>}
                <div
                    style={{
                        margin: "0px 0px 10px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <input
                        style={{
                            paddingTop: "5px",
                            width: "15px",
                            height: "15px",
                            cursor: "pointer",
                        }}
                        type="checkbox"
                        checked={isSelected}
                        onChange={handleSelectAll}
                    />
                    <div style={{ marginLeft: "10px" }}>全てを選択</div>
                </div>
            </div>
            <div className="noti">
                <div className="noti__wrapper">
                    <div className="noti__content">
                        {notifications.listNoti?.length
                            ? notifications.listNoti.map((noti, index) => (
                                  <div key={index}>
                                      <NotificationItem
                                          handleOnSelectNoti={handleOnSelectNoti}
                                          data={noti}
                                          handleDeleteNoti={() => handleDeleteNoti(noti.id)}
                                          isSelected={selectedNotifications.includes(noti.id)}
                                          handleToggleSelect={() => handleToggleSelectNoti(noti.id)}
                                      />
                                  </div>
                              ))
                            : !loading && <p className="messageNotFound">{MESSAGE_DATA_NOT_FOUND}</p>}
                    </div>
                </div>
            </div>
            {/* <div className="wrapperLoading">
                <CircularLoader loading={loading} size={15} type="fullContent" />
            </div> */}
            {!loading && loadMore && (
                <p className="viewMore">
                    <span className="viewMore__text" onClick={() => handleLoadMore()}>
                        続きを見る
                    </span>
                    <img className="viewMore__icon" src={active_arrow_down} alt="icon" onClick={() => handleLoadMore()} />
                </p>
            )}
        </StyledNotificationComponent>
    );
};

const DeleteMultiButton = styled.button`
    margin: 10px 0px;
    padding: 10px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
`;
