import { AppTopBar } from "@components";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import React from "react";
import { StyledAppCalendar } from "./styles";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import menu1 from "@assets/Images/menu1.png";
import { AppIconButton } from "@components";
import { ArrowLeftIcon, ArrowRightIcon } from "@assets";
import "moment/locale/ja";

const localizer = momentLocalizer(moment);

export const Schedule = () => {
    const eventStyleGetter = (event) => {
        const backgroundColor = "#" + event.hexColor;
        const style = {
            backgroundColor: backgroundColor,
            borderRadius: "0px",
            opacity: 0.8,
            color: "black",
            border: "0px",
            display: "block",
        };
        return {
            style: style,
        };
    };
    const [infoSlot, setInfoSlot] = React.useState<any>();
    const onSelectSlot = (slotInfo) => {
        setInfoSlot(slotInfo);
        alert(infoSlot);
    };
    const events = [
        {
            startDate: moment().add(2, "days").format("YYYY-MM-DD"),
            endDate: moment().add(4, "days").format("YYYY-MM-DD"),
            avatar: menu1,
            allDay: false,
        },
        {
            startDate: moment().add(8, "days").format("YYYY-MM-DD"),
            endDate: moment().add(12, "days").format("YYYY-MM-DD"),
            avatar: menu1,
            allDay: false,
        },
        {
            startDate: moment().add(13, "days").format("YYYY-MM-DD"),
            endDate: moment().add(15, "days").format("YYYY-MM-DD"),
            avatar: menu1,
            allDay: false,
        },
        {
            startDate: moment().add(16, "days").format("YYYY-MM-DD"),
            endDate: moment().add(25, "days").format("YYYY-MM-DD"),
            avatar: menu1,
            allDay: false,
        },
    ];
    return (
        <div>
            <AppTopBar />
            <StyledAppCalendar>
                <Calendar
                    localizer={localizer}
                    style={{ height: 500 }}
                    events={events}
                    startAccessor="startDate"
                    endAccessor="endDate"
                    components={{
                        event: Event,
                        toolbar: CustomToolbar,
                    }}
                    selectable
                    onSelectSlot={onSelectSlot}
                    eventPropGetter={eventStyleGetter}
                    views={["month"]}
                />
            </StyledAppCalendar>
        </div>
    );
};
const CustomToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
        toolbar.onNavigate("prev");
    };

    const goToNext = () => {
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
        toolbar.onNavigate("next");
    };

    const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        toolbar.onNavigate("current");
    };

    const label = () => {
        const date = moment(toolbar.date);
        return (
            <span>
                <b>{date.format("MMMM")}</b>
                <span> {date.format("YYYY")}</span>
            </span>
        );
    };

    return (
        <div className="toolbar-container">
            <label className="label-date">{label()}</label>

            <div className="back-next-buttons">
                <div className="btn-back">
                    <AppIconButton onClick={goToBack} icon={<ArrowLeftIcon />} />
                </div>
                <label className="btn-current" onClick={goToCurrent}>
                    {toolbar.label}
                </label>
                <div className="btn-next">
                    <AppIconButton onClick={goToNext} icon={<ArrowRightIcon />} />
                </div>
            </div>
        </div>
    );
};
function Event(event: any) {
    const [showTooltip, setShowTooltip] = React.useState(false);
    const closeTooltip = () => {
        setShowTooltip(false);
    };

    const openTooltip = () => {
        setShowTooltip(true);
    };
    const ref = React.useRef(null);
    return (
        <div ref={ref}>
            <span onMouseOver={openTooltip}>{event.title}</span>
            <Tooltip
                open={showTooltip}
                onClose={closeTooltip}
                onOpen={openTooltip}
                title={
                    <>
                        <img src={event.event.avatar} />
                    </>
                }
                arrow
            >
                <Button></Button>
            </Tooltip>
        </div>
    );
}
