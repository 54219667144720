export const theme = {
    breakPoints: {
        breakSmall: "321px",
        breakMobile: "375px",
        breakMobilePhone: "414px",
        breakMobileMedium: "575px",
        breakTablet: "767px",
        breakOnlyMobile: "768px",
        breakIpad8:"900px",
        breakMedium: "980px",
        breakIpadPro: "1024px",
        breakLarge: "1200px",
        breakBig: "1367px",
        breakSuperBig: "1824px",
    },
    widths: {
        wide_650: "650px",
        wide_700: "700px",
        wide_900: "900px",
        wide_1110: "1110px",
    },
    colors: {
        primary: "#43CDEC",
        secondary: "#FF9D43",
        error: "#FF4343",
        darkBlack: "#292D32",
        black: "#292D32",
        gray: "#F4F4F4",
        gray1: "#656565",
        gray2: "#8E8E8E",
        gray3: "#D5D5D5",
        gray4: "#DEDEDE",
        gray5: "#F0F0F0",
        gray6: "#F4F4F4",
        white: "#FFFFFF",
        borderCheckBox: "#D4D4D4",
        borderButtonIcon: "#F0F0F0",
    },
    borderRadius: {
        base: "8px",
        inputBase: "16px",
    },
    fontFamily: {
        MochiyPopOne_Regular: "MochiyPopOne-Regular",
        NotoSansJP_300: "NotoSansJP-300",
        NotoSansJP_400: "NotoSansJP-400",
        NotoSansJP_500: "NotoSansJP-500",
        NotoSansJP_700: "NotoSansJP-700",
    },
};
