export const InstrumentIcon = () => {
    return (
        <>
            <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.4358 7.38065L8.31909 9.88438L6.44132 6.8029C5.75521 5.67142 6.11631 4.17882 7.2478 3.49271C8.37928 2.8066 9.87187 3.16769 10.558 4.29917L12.4358 7.38065Z"
                    stroke="#D00096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.7827 11.5816L10.979 13.8927C8.76414 15.2409 8.09006 17.9612 9.16135 20.1279L11.6289 25.1594C12.4234 26.7844 14.3493 27.3501 15.8901 26.3991L23.6299 21.6927C25.1827 20.7538 25.5558 18.7918 24.4845 17.3353L21.1503 12.8335C19.7058 10.8835 16.9975 10.2334 14.7827 11.5816Z"
                    stroke="#D00096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.5025 6.69209L7.33398 10.4484L9.83817 14.5607L16.0067 10.8044L13.5025 6.69209Z"
                    stroke="#D00096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.7793 20.7899L19.7654 24.052"
                    stroke="#D00096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.6973 22.6677L16.6834 25.9298"
                    stroke="#D00096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.8613 18.9121L22.8475 22.1742"
                    stroke="#D00096"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};
