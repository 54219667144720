import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const loginSchema = Yup.object().shape({
    email: Yup.string().required("フィールドメールが必要です").email("メールフィールドが無効です").max(255, "フィールドEメールは255文字以内"),
    password: Yup.string().required("フィールドパスワードが必要です").min(8, "8文字以上が必要です"),
});
export const forgotPaswordSchema = Yup.object().shape({
    email: Yup.string().required("フィールドEメールは必須です").email("メールフィールドが無効です").max(255, "フィールドEメールは255文字以内"),
});
export const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required("古いパスワードは必須フィールドです").min(8, "8文字以上が必要です"),
    new_password_confirmation: Yup.string()
        .required("確認パスワードが必要です")
        .oneOf([Yup.ref("password"), null], "パスワードが一致しないことを確認する"),
});
export const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required("古いパスワードは必須フィールドです").min(8, "8文字以上が必要です"),
    new_password: Yup.string().required("フィールドパスワードが必要です").min(8, "8文字以上が必要です"),
    new_password_confirmation: Yup.string()
        .required("確認パスワードが必要です")
        .oneOf([Yup.ref("new_password"), null], "パスワードとパスワードの確認が一致しません"),
});

export const inventorySchema = Yup.object().shape({
    selectType: Yup.string().required("この項目は必須です"),
    quantityProduct: Yup.number().min(1, "最小数量は 1 です"),
    siteConstruction: Yup.string().required("この項目は必須です"),
    // quantityProduct: Yup.number().when("selectType", {
    //     is: "1",
    //     then: Yup.number().max(
    //         Yup.ref("selectQuantity"),
    //         "Must be less than or equal toquantity in inventory!"
    //     ),
    // }),
});

export const vehicleSchema = Yup.object().shape({
    condition: Yup.string().required("この項目は必須です"),
});
export const vehicleCalendaSchema = Yup.object().shape({
    selectValue: Yup.string().required("この項目は必須です"),
});
export const vehicleSearchForm = Yup.object().shape({
    searchValue: Yup.string().required("この項目は必須です"),
});

export const OTFormVacationSchema = Yup.object().shape({
    title: Yup.string().required("この項目は必須です"),
    type_of_overtime: Yup.string().required("この項目は必須です"),
    phoneNumber: Yup.string().required("この項目は必須です"),
    reasonType: Yup.string().required("この項目は必須です"),
    reasonContent: Yup.string().when("reasonType", {
        is: "0",
        then: Yup.string().required("この項目は必須です"),
    }),
    start_time: Yup.string().required("この項目は必須です"),
    stop_time: Yup.string().required("この項目は必須です"),
});
export const OTFormOvertimeSchema = Yup.object().shape({
    title: Yup.string().required("この項目は必須です"),
    type_of_overtime: Yup.string().required("この項目は必須です"),
    break_time: Yup.string().required("この項目は必須です"),
    work_time: Yup.string().required("この項目は必須です"),
    construction: Yup.string().required("この項目は必須です"),
    work_detail: Yup.string().required("この項目は必須です"),
    subContractor: Yup.array().min(1, "この項目は必須です"),
    start_time: Yup.string().required("この項目は必須です"),
    stop_time: Yup.string().required("この項目は必須です"),
});
export const OTFormNightToDaySchema = Yup.object().shape({
    title: Yup.string().required("この項目は必須です"),
    type_of_overtime: Yup.string().required("この項目は必須です"),
    break_time: Yup.string().required("この項目は必須です"),
    work_time: Yup.string().required("この項目は必須です"),
    construction: Yup.string().required("この項目は必須です"),
    work_detail: Yup.string().required("この項目は必須です"),
    subContractor: Yup.array().min(1, "この項目は必須です"),
    start_time: Yup.string().required("この項目は必須です"),
    stop_time: Yup.string().required("この項目は必須です"),

    start_at_extra: Yup.string().required("この項目は必須です"),
    stop_at_extra: Yup.string().required("この項目は必須です"),
    break_time_extra: Yup.string().required("この項目は必須です"),
    work_time_extra: Yup.string().required("この項目は必須です"),
    construction_id_extra: Yup.string().required("この項目は必須です"),
    work_detail_extra: Yup.string().required("この項目は必須です"),
    contractor_id_extra: Yup.array().min(1, "この項目は必須です"),
});
export const OTFormBereavementSchema = Yup.object().shape({
    title: Yup.string().required("この項目は必須です"),
    type_of_overtime: Yup.string().required("この項目は必須です"),
    phoneNumber: Yup.string().required("この項目は必須です"),
    family_relationship: Yup.string().required("この項目は必須です"),
    family_living: Yup.string().required("この項目は必須です"),
});
export const OTFormHalfDaySchema = Yup.object().shape({
    title: Yup.string().required("この項目は必須です"),
    type_of_overtime: Yup.string().required("この項目は必須です"),
    break_time: Yup.string().required("この項目は必須です"),
    work_time: Yup.string().required("この項目は必須です"),
    construction: Yup.string().required("この項目は必須です"),
    work_detail: Yup.string().required("この項目は必須です"),
    subContractor: Yup.array().min(1, "この項目は必須です"),
    reasonType: Yup.string().required("この項目は必須です"),
    reasonContent: Yup.string().when("reasonType", {
        is: "0",
        then: Yup.string().required("この項目は必須です"),
    }),
    phoneNumber: Yup.string().required("この項目は必須です"),
    start_time: Yup.string().required("この項目は必須です"),
    stop_time: Yup.string().required("この項目は必須です"),
});
export const OTFormHolidayWorkSchema = Yup.object().shape({
    title: Yup.string().required("この項目は必須です"),
    type_of_overtime: Yup.string().required("この項目は必須です"),
    break_time: Yup.string().required("この項目は必須です"),
    work_time: Yup.string().required("この項目は必須です"),
    construction: Yup.string().required("この項目は必須です"),
    work_detail: Yup.string().required("この項目は必須です"),
    subContractor: Yup.array().min(1, "この項目は必須です"),
    start_time: Yup.string().required("この項目は必須です"),
    stop_time: Yup.string().required("この項目は必須です"),
    detail: Yup.string().when("compensatory_leave", {
        is: "1",
        then: Yup.string().required("この項目は必須です"),
    }),
});
export const OTFormDefault = Yup.object().shape({
    title: Yup.string().required("この項目は必須です"),
});
export const selectTimeSheetTypeSchema = Yup.object().shape({
    selectValue: Yup.string().required("この項目は必須です"),
});
// timesheet form
export const timeSheetHalfDayPaidSchema = Yup.object().shape({
    absenceTypes: Yup.string().required("この項目は必須です"),
    breakTime: Yup.number().required("この項目は必須です"),
    absenceContents: Yup.string().when("absenceTypes", {
        is: "0",
        then: Yup.string().required("この項目は必須です"),
    }),
    siteConstruction: Yup.string().required("この項目は必須です"),
    // siteConstruction: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    weatherCondition: Yup.string().required("この項目は必須です"),
    constructors: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    driver: Yup.string().required("この項目は必須です"),
    employee: Yup.string().required("この項目は必須です"),
    deviceCheck: Yup.string().required("この項目は必須です"),
    vehicle: Yup.string().required("この項目は必須です"),
    listConstractors: Yup.array().of(
        Yup.object().shape({
            number_of_people: Yup.number().required("この項目は必須です").min(1, "1文字以上が必要です"),
            // work_type_1: Yup.string().required("この項目は必須です"),
            // work_type_2: Yup.string().required("この項目は必須です"),
            // work_type_3: Yup.string().required("この項目は必須です"),
        })
    ),
});
export const timeSheetNightShift = Yup.object().shape({
    siteConstruction: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    weatherCondition: Yup.string().required("この項目は必須です"),
    constructors: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    breakTime: Yup.number().required("この項目は必須です"),
    listConstractors: Yup.array().of(
        Yup.object().shape({
            number_of_people: Yup.number().required("この項目は必須です").min(1, "1文字以上が必要です"),
            // work_type_1: Yup.string().required("この項目は必須です"),
        })
    ),
    driver: Yup.string().required("この項目は必須です"),
    employee: Yup.string().required("この項目は必須です"),
    deviceCheck: Yup.string().required("この項目は必須です"),
    vehicle: Yup.string().required("この項目は必須です"),
});
export const timeSheetHoliday = Yup.object().shape({
    absenceTypes: Yup.string().required("この項目は必須です"),
    absenceContents: Yup.string().when("absenceTypes", {
        is: "0",
        then: Yup.string().required("この項目は必須です"),
    }),
});

export const timeSheetTardiness = Yup.object().shape({
    absenceTypes: Yup.string().required("この項目は必須です"),
    absenceContents: Yup.string().when("absenceTypes", {
        is: "0",
        then: Yup.string().required("この項目は必須です"),
    }),
    phoneNumber: Yup.string().matches(phoneRegExp, "電話番号が無効です").required("この項目は必須です"),
});
export const timeSheetDayAndNightShift = Yup.object().shape({
    dayItemConstruction: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    dayBreakTime: Yup.string().required("この項目は必須です"),
    dayWeatherCondition: Yup.string().required("この項目は必須です"),
    dayConstructors: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    dayDriver: Yup.string().required("この項目は必須です"),
    dayEmployee: Yup.string().required("この項目は必須です"),
    dayDeviceCheck: Yup.string().required("この項目は必須です"),
    dayVehicle: Yup.string().required("この項目は必須です"),
    dayListConstractors: Yup.array().of(
        Yup.object().shape({
            number_of_people: Yup.number().required("この項目は必須です").min(1, "1文字以上が必要です"),
            work_type_1: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
            work_type_2: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
            work_type_3: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
        })
    ),

    nightItemConstruction: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    nightBreakTime: Yup.string().required("この項目は必須です"),
    nightWeatherCondition: Yup.string().required("この項目は必須です"),
    nightConstructors: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    nightDriver: Yup.string().required("この項目は必須です"),
    nightEmployee: Yup.string().required("この項目は必須です"),
    nightDeviceCheck: Yup.string().required("この項目は必須です"),
    nightVehicle: Yup.string().required("この項目は必須です"),
    nightListConstractors: Yup.array().of(
        Yup.object().shape({
            number_of_people: Yup.number().required("この項目は必須です").min(1, "1文字以上が必要です"),
            work_type_1: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
            work_type_2: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
            work_type_3: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
        })
    ),
});
export const timeSheetPublicHoliday = Yup.object().shape({
    group: Yup.string().required("この項目は必須です"),
});
export const workScheduleUpdate = Yup.object().shape({
    // constructors: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    // detail: Yup.string().required("この項目は必須です"),
    listConstractors: Yup.array().of(
        Yup.object().shape({
            number_of_people: Yup.string()
                .test("check number", "1文字以上が必要です", (values) => (!values ? true : /^[1-9]+[0-9]*$/.test(values)))
                .nullable(),
            // .min(1, "1文字以上が必要です")
            // .transform((value) => (isNaN(value) ? "" : value)),
        })
    ),
});
export const workScheduleAddForm = Yup.object().shape({
    constructors: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    workType1: Yup.object({
        work_type_id: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    }),
    // contractions: Yup.string().required("この項目は必須です"),
    // contractions: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
    // work_types: Yup.array().of(
    //     Yup.object().shape({
    //         type: Yup.number(),
    //         work_type: Yup.number(),
    //         work_type_date: Yup.array(),
    //     })
    // ),

    // workType1: Yup.object({
    //     workType1Id: Yup.number().required("この項目は必須です"),
    //     workType1Date: Yup.array(
    //         Yup.object({
    //             startDate: Yup.date().required("この項目は必須です"),
    //             endDate: Yup.date(),
    //             workTypeId: Yup.number(),
    //         })
    //     ),
    //     workType2: Yup.array(
    //         Yup.object({
    //             workType2Id: Yup.number(),
    //             workType2Date: Yup.array(
    //                 Yup.object({
    //                     startDate: Yup.date(),
    //                     endDate: Yup.date(),
    //                 })
    //             ),
    //             workType3: Yup.array(
    //                 Yup.object({
    //                     workType3Id: Yup.number(),
    //                     workType3Date: Yup.array(
    //                         Yup.object({
    //                             startDate: Yup.date(),
    //                             endDate: Yup.date(),
    //                         })
    //                     ),
    //                 })
    //             ),
    //         })
    //     ),
    // }),

    // workType1: Yup.object({
    //     workType1Id: Yup.number().required("この項目は必須です"),
    //     workType1Date: Yup.array(
    //         Yup.object({
    //             startDate: Yup.date().required("この項目は必須です"),
    //             endDate: Yup.date(),
    //             workTypeId: Yup.number(),
    //         })
    //     ),
    //     workType2: Yup.array(
    //         Yup.object({
    //             workType2Id: Yup.number(),
    //             workType2Date: Yup.array(
    //                 Yup.object({
    //                     startDate: Yup.date(),
    //                     endDate: Yup.date(),
    //                 })
    //             ),
    //             workType3: Yup.array(
    //                 Yup.object({
    //                     workType3Id: Yup.number(),
    //                     workType3Date: Yup.array(
    //                         Yup.object({
    //                             startDate: Yup.date(),
    //                             endDate: Yup.date(),
    //                         })
    //                     ),
    //                 })
    //             ),
    //         })
    //     ),
    // }),

    // workType1: Yup.object({
    //     workType1Id: Yup.number().required("この項目は必須です"),
    //     workType1Date: Yup.array(
    //         Yup.object({
    //             startDate: Yup.date().required("この項目は必須です"),
    //             endDate: Yup.date(),
    //             workTypeId: Yup.number(),
    //         })
    //     ),
    //     workType2: Yup.array().of(
    //         Yup.object().shape({
    //             workType2Id: Yup.number(),
    //             workType2Date: Yup.array(
    //                 Yup.object().shape({
    //                     startDate: Yup.date().when("workType1Date", ([workType1Date], schema) => {
    //                         console.log("workType1Date", workType1Date);
    //                         console.log("schema", schema);
    //                         if (!workType1Date || !workType1Date.length) {
    //                             return schema; // Return schema directly when workType1Date is empty
    //                         }
    //                         return {
    //                             is: (workType1Date) => workType1Date.length > 0,
    //                             then: (schema) => "Manh",
    //                             otherwise: (schema) => "Chau",
    //                         };
    //                         // return schema.test({
    //                         //     test: function (value) {
    //                         //         for (const { startDate, endDate } of workType1Date) {
    //                         //             if (value >= startDate && value <= endDate) {
    //                         //                 return true;
    //                         //             }
    //                         //         }
    //                         //         return false;
    //                         //     },
    //                         //     message:
    //                         //         "Start date must be within the range of any workType1Date",
    //                         // });
    //                     }),
    //                     endDate: Yup.date().when("workType1.workType1Date", (workType1Date, schema) => {
    //                         if (!workType1Date || !workType1Date.length) {
    //                             return schema; // Return schema directly when workType1Date is empty
    //                         }
    //                         return schema.test({
    //                             test: function (value) {
    //                                 for (const { startDate, endDate } of workType1Date) {
    //                                     if (value >= startDate && value <= endDate) {
    //                                         return true;
    //                                     }
    //                                 }
    //                                 return false;
    //                             },
    //                             message: "End date must be within the range of any workType1Date",
    //                         });
    //                     }),
    //                 })
    //             ),
    //             workType3: Yup.array().of(
    //                 Yup.object().shape({
    //                     workType3Id: Yup.number(),
    //                     workType3Date: Yup.array().of(
    //                         Yup.object().shape({
    //                             startDate: Yup.date(),
    //                             endDate: Yup.date(),
    //                         })
    //                     ),
    //                 })
    //             ),
    //         })
    //     ),
    // }),

    //     workType1Id: Yup.number().required("この項目は必須です"),
    //     workType1Date: Yup.array(
    //         Yup.object({
    //             startDate: Yup.date().required("この項目は必須です"),
    //             endDate: Yup.date(),
    //         })
    //     ),
    //     workType2: Yup.array(
    //         Yup.object({
    //             workType2Id: Yup.number(),
    //             workType2Date: Yup.array(
    //                 Yup.object({
    //                     startDate: Yup.date().when("$workType1Date.0.startDate", {
    //                         is: (startDate: any) => !!startDate,
    //                         then: Yup.date()
    //                             .min(Yup.ref("$workType1Date.0.startDate"), "日付が範囲外です")
    //                             .max(
    //                                 Yup.ref("$workType1Date.${workType1Date.length - 1}.endDate"),
    //                                 "日付が範囲外です"
    //                             ),
    //                     }),
    //                 })
    //             ),
    //             workType3: Yup.array(
    //                 Yup.object({
    //                     workType3Id: Yup.number(),
    //                     workType3Date: Yup.array(
    //                         Yup.object({
    //                             startDate: Yup.date().when("$workType1Date.0.startDate", {
    //                                 is: (startDate: any) => !!startDate,
    //                                 then: Yup.date()
    //                                     .min(
    //                                         Yup.ref("$workType1Date.0.startDate"),
    //                                         "日付が範囲外です"
    //                                     )
    //                                     .max(
    //                                         Yup.ref(
    //                                             "$workType1Date.${workType1Date.length - 1}.endDate"
    //                                         ),
    //                                         "日付が範囲外です"
    //                                     ),
    //                             }),
    //                         })
    //                     ),
    //                 })
    //             ),
    //         })
    //     ),
    // }),

    listConstractors: Yup.array().of(
        Yup.object().shape({
            number_of_people: Yup.number().required("この項目は必須です").moreThan(0, "数量は 0 より大きくなければなりません"),
        })
    ),
});

export const workScheduleTypeForm = Yup.object().shape({
    workType: Yup.array().of(
        Yup.object().shape({
            type: Yup.number().required("この項目は必須です"),
            work_type_id: Yup.array().required("この項目は必須です").min(1, "1文字以上が必要です"),
        })
    ),
    listConstractors: Yup.array().of(
        Yup.object().shape({
            number_of_people: Yup.number().required("この項目は必須です").moreThan(0, "数量は 0 より大きくなければなりません"),
        })
    ),
    constructors: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
});

export const editWorkScheduleForm = Yup.object().shape({
    numberOfPeople: Yup.number().required("この項目は必須です").moreThan(0, "数量は 0 より大きくなければなりません").typeError("数量は数字でなければなりません"),
});

export const workScheduleClassificationForm = Yup.object().shape({
    workType: Yup.array().of(
        Yup.object().shape({
            type: Yup.number().required("この項目は必須です"),
            work_type_id: Yup.array().required("この項目は必須です").min(1, "1文字以上が必要です"),
        })
    ),
    listConstractors: Yup.array().of(
        Yup.object().shape({
            number_of_people: Yup.number().required("この項目は必須です").moreThan(0, "数量は 0 より大きくなければなりません"),
        })
    ),
    constructors: Yup.array().required("この項目は必須です").min(1, "この項目は必須です"),
});
export const overtimeApprovalStatus = Yup.object().shape({
    inputValue: Yup.string().when("radioValue", {
        is: "2",
        then: Yup.string().required("この項目は必須です"),
    }),
});
export const dailyLogFilterSchema = Yup.object().shape({
    minPeople: Yup.number().min(1, "最小値は 1 です"),
    maxPeople: Yup.number().moreThan(Yup.ref("minPeople"), "最小より大きい"),
});

// siteConstruction: Yup.string().required("この項目は必須です"),;
export const borrowingInstrumentSchema = Yup.object().shape({
    siteConstruction: Yup.string().required("この項目は必須です"),
});
