import { Grid } from "@mui/material";
import { formatDate } from "@utils";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import { AppCustomeSelect, AppInput, AppTimePicker } from "../Common";
import { TextTile } from "./styles";

interface IProps {
    values: any;
    handleChange: any;
    touched: any;
    errors: any;
    handleBlur: any;
    optionSiteConstructions: any;
    optionConstractors: any;
    setFieldValue: any;
    onChangeConstractors: any;
    onChangeConstractorsExtra: any;
    onChangeConstruction: any;
    onChangeConstructionExtra: any;
}

const OTNewNightToDay = (props: IProps) => {
    const {
        values,
        handleChange,
        touched,
        errors,
        optionSiteConstructions,
        optionConstractors,
        setFieldValue,
        onChangeConstractors,
        onChangeConstractorsExtra,
        onChangeConstruction,
        onChangeConstructionExtra,
    } = props;

    const [valueConstructionExtra, setValueConstructionExtra] = React.useState<any>(null);
    const [valueConstruction, setValueConstruction] = React.useState<any>(null);

    const listConstructionOption =
        optionSiteConstructions?.constructions?.length &&
        optionSiteConstructions?.constructions?.map((item) => {
            return {
                label: item.name,
                value: item.id.toString(),
            };
        });
    const constructionSelected = listConstructionOption?.find(
        (item) => item.value === values?.construction.toString()
    );

    const constructionExtraSelected = listConstructionOption?.find(
        (item) => item.value === values?.construction_id_extra.toString()
    );

    return (
        <>
            <div className="newOT__time">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={7} container>
                        <TextTile className="label">夜勤</TextTile>
                    </Grid>
                    <Grid item xs={12} md={7} container>
                        <WrapTime>
                            <div className="start-time">
                                <label className="label">開始時間</label>
                                <AppTimePicker
                                    value={dayjs(values.start_time, "HH:mm:ss").toDate()}
                                    handleChange={(newValue) => {
                                        const formatValue = formatDate({
                                            date: newValue,
                                            format: "HH:mm:ss",
                                        });
                                        setFieldValue("start_time", formatValue);
                                    }}
                                    errorMessage={
                                        touched.start_time && errors.start_time
                                            ? errors.start_time
                                            : undefined
                                    }
                                />
                            </div>
                            <div className="end-time">
                                <label className="label">終了時間</label>
                                <AppTimePicker
                                    value={dayjs(values.stop_time, "HH:mm:ss").toDate()}
                                    handleChange={(newValue) => {
                                        const formatValue = formatDate({
                                            date: newValue,
                                            format: "HH:mm:ss",
                                        });
                                        setFieldValue("stop_time", formatValue);
                                    }}
                                    errorMessage={
                                        touched.stop_time && errors.stop_time
                                            ? errors.stop_time
                                            : undefined
                                    }
                                />
                            </div>
                        </WrapTime>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="input">
                            <AppInput
                                isNumber
                                value={String(values.work_time)}
                                handleChange={handleChange("work_time")}
                                label="稼働時間 (hour)"
                                error={errors.work_time}
                                touched={touched.work_time}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="input">
                            <AppInput
                                isNumber
                                value={String(values.break_time)}
                                handleChange={handleChange("break_time")}
                                label="休憩 (hour)"
                                error={errors.break_time}
                                touched={touched.break_time}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="user__department">
                            <p className="label">工事名</p>
                            <AppCustomeSelect
                                options={listConstructionOption}
                                placeholder="select 請負業者"
                                name="construction"
                                value={valueConstruction || constructionSelected}
                                // noError={errors.constructors}
                                onChange={(e) => {
                                    onChangeConstruction(e, setFieldValue);
                                    setValueConstruction(e);
                                }}
                                error={errors.construction}
                                touched={touched.construction}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="input">
                            <AppInput
                                label="業務内容"
                                value={values.work_detail}
                                name="work_detail"
                                error={errors.work_detail}
                                touched={touched.work_detail}
                                handleChange={handleChange("work_detail")}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="input">
                            <label className="label">業者名</label>
                            <div className="select__contractor">
                                <div>
                                    <AppCustomeSelect
                                        groupOptions={optionConstractors}
                                        options={
                                            optionConstractors?.contractors?.length &&
                                            optionConstractors?.contractors.map((item) => {
                                                return {
                                                    label: item.name,
                                                    value: item.id.toString(),
                                                };
                                            })
                                        }
                                        placeholder="select 請負業者"
                                        name="subContractor"
                                        value={values.subContractor}
                                        // noError={errors.constructors}
                                        onChange={(e) => {
                                            onChangeConstractors(e, values, setFieldValue);
                                            setFieldValue("subContractor", e);
                                        }}
                                        isMulti
                                    />
                                </div>
                            </div>
                            {errors.subContractor && touched.subContractor && (
                                <p
                                    style={{
                                        color: "red",
                                        fontSize: "13px",
                                        marginTop: "5px",
                                    }}
                                >
                                    {errors?.subContractor}
                                </p>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="input">
                            <AppInput
                                label="備考"
                                value={values.memo}
                                name="memo"
                                // error={errors.memo}
                                error={touched.memo ? errors.memo : undefined}
                                touched={touched.memo}
                                handleChange={handleChange("memo")}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="newOT__time">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={7} container>
                        <TextTile className="label">日勤</TextTile>
                    </Grid>
                    <Grid item xs={12} md={7} container>
                        <WrapTime>
                            <div className="start-time">
                                <label className="label">開始時間</label>
                                <AppTimePicker
                                    value={dayjs(values.start_at_extra, "HH:mm:ss").toDate()}
                                    handleChange={(newValue) => {
                                        const formatValue = formatDate({
                                            date: newValue,
                                            format: "HH:mm:ss",
                                        });
                                        setFieldValue("start_at_extra", formatValue);
                                    }}
                                    errorMessage={
                                        touched.start_at_extra && errors.start_at_extra
                                            ? errors.start_at_extra
                                            : undefined
                                    }
                                />
                            </div>
                            <div className="end-time">
                                <label className="label">終了時間</label>
                                <AppTimePicker
                                    value={dayjs(values.stop_at_extra, "HH:mm:ss").toDate()}
                                    handleChange={(newValue) => {
                                        const formatValue = formatDate({
                                            date: newValue,
                                            format: "HH:mm:ss",
                                        });
                                        setFieldValue("stop_at_extra", formatValue);
                                    }}
                                    errorMessage={
                                        touched.stop_at_extra && errors.stop_at_extra
                                            ? errors.stop_at_extra
                                            : undefined
                                    }
                                />
                            </div>
                        </WrapTime>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="input">
                            <AppInput
                                isNumber
                                value={String(values.work_time_extra)}
                                handleChange={handleChange("work_time_extra")}
                                label="稼働時間 (hour)"
                                error={errors.work_time_extra}
                                touched={touched.work_time_extra}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="input">
                            <AppInput
                                isNumber
                                value={String(values.break_time_extra)}
                                handleChange={handleChange("break_time_extra")}
                                label="休憩 (hour)"
                                error={errors.break_time_extra}
                                touched={touched.break_time_extra}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="user__department">
                            <p className="label">工事名</p>
                            <AppCustomeSelect
                                options={listConstructionOption}
                                placeholder="select 請負業者"
                                name="construction_id_extra"
                                value={valueConstructionExtra || constructionExtraSelected}
                                // noError={errors.constructors}
                                onChange={(e) => {
                                    onChangeConstructionExtra(e, setFieldValue);
                                    // setFieldValue("construction", e);
                                    setValueConstructionExtra(e);
                                }}
                                error={errors.construction_id_extra}
                                touched={touched.construction_id_extra}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="input">
                            <AppInput
                                label="業務内容"
                                value={values.work_detail_extra}
                                name="work_detail_extra"
                                error={errors.work_detail_extra}
                                touched={touched.work_detail_extra}
                                handleChange={handleChange("work_detail_extra")}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="input">
                            <label className="label">業者名</label>
                            <div className="select__contractor">
                                <div>
                                    <AppCustomeSelect
                                        groupOptions={optionConstractors}
                                        options={
                                            optionConstractors?.contractors?.length &&
                                            optionConstractors?.contractors.map((item) => {
                                                return {
                                                    label: item.name,
                                                    value: item.id.toString(),
                                                };
                                            })
                                        }
                                        placeholder="select 請負業者"
                                        name="contractor_id_extra"
                                        value={values.contractor_id_extra}
                                        // noError={errors.constructors}
                                        onChange={(e) => {
                                            onChangeConstractorsExtra(e, values, setFieldValue);
                                            setFieldValue("contractor_id_extra", e);
                                        }}
                                        isMulti
                                    />
                                </div>
                            </div>
                            {errors.contractor_id_extra && touched.contractor_id_extra && (
                                <p
                                    style={{
                                        color: "red",
                                        fontSize: "13px",
                                        marginTop: "5px",
                                    }}
                                >
                                    {errors?.contractor_id_extra}
                                </p>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div className="input">
                            <AppInput
                                label="備考"
                                value={values.memo_extra}
                                name="memo_extra"
                                // error={errors.memo_extra}
                                error={touched.memo_extra ? errors.memo_extra : undefined}
                                touched={touched.memo_extra}
                                handleChange={handleChange("memo_extra")}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default OTNewNightToDay;

const WrapTime = styled.div`
    display: flex;
    .end-time {
        margin-left: 20px;
    }
`;
