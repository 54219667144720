import React from "react";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";

import { ArrowLeftIcon } from "@assets";
import { StyledChangePassword } from ".";
import { Formik } from "formik";
import { AppButton, AppInput, AppModal } from "@components";
import { forgotPaswordSchema } from "@utils";
import { authRouteEnums } from "@enums";
import yellow_lines_icon from "@assets/Images/yellow_lines.png";
import { AppAlertConfirm, CircularLoader } from "..";
import { forgotPassword, selectAuth, useAppSelector } from "@redux";

export const ForgotPasswordView = () => {
    const [show, setShow] = React.useState<boolean>(false);
    const auth = useAppSelector(selectAuth);
    const { isLoading } = auth;
    // hooks
    const history = useHistory();
    const dispatch = useDispatch();
    // init value of form
    const initialValuesPackage = {
        email: "",
    };
    // events
    const handleAccept = () => {
        setShow(false);
    };
    const handleReject = () => {
        setShow(false);
    };
    return (
        <StyledChangePassword>
            <CircularLoader loading={isLoading} />
            <AppModal open={show}>
                <AppAlertConfirm
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                    name="色見本②"
                    code="R4.2.25～R4.3.2"
                />
            </AppModal>
            <div className="forgotPassword__wrapper">
                <div className="backToPrevious">
                    <span
                        className="backToPrevious__btn"
                        onClick={() => history.push(authRouteEnums.PATH_SIGNIN)}
                    >
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <ArrowLeftIcon />
                        </IconButton>
                    </span>
                    <span className="backToPrevious__text">ログイン</span>
                </div>
                <div className="fillForm">
                    <div className="fillForm__title forgotPassword__title">
                        <span className="text">パスワードを忘れの場合</span>

                        <div className="line">
                            <img className="line__icon" src={yellow_lines_icon} />
                        </div>
                    </div>
                    <div className="fillForm__content">
                        <Formik
                            initialValues={initialValuesPackage}
                            onSubmit={(values) => {
                                dispatch(
                                    forgotPassword({
                                        dispatch,
                                        values,
                                    })
                                );
                            }}
                            validationSchema={forgotPaswordSchema}
                        >
                            {({
                                handleSubmit,
                                values,
                                handleChange,
                                touched,
                                errors,
                                handleBlur,
                            }) => {
                                return (
                                    <div className="fillForm__wrapper">
                                        <div className="fillForm__input">
                                            <AppInput
                                                value={values.email}
                                                name="email"
                                                handleChange={handleChange("email")}
                                                handleBlur={handleBlur("email")}
                                                label="Eメール"
                                                error={errors.email}
                                                touched={touched.email}
                                            />
                                        </div>
                                        <div className="fillForm__btn">
                                            <AppButton text="変える" onClick={handleSubmit} />
                                        </div>
                                    </div>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </StyledChangePassword>
    );
};
