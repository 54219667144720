import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import * as React from "react";

import { TextField } from "@mui/material";
import { ja } from "date-fns/locale";
import { StyleAppTimeRangePicker } from ".";
interface IProps {
    placeholder?: string;
    handleChange: (any) => void;
    value?: any;
    minTime?: any;
    errorMessage?: string;
}
export const AppTimePicker = (props: IProps) => {
    const { handleChange, value, errorMessage } = props;

    const [valueTime, setValueTime] = React.useState<any>(value);

    React.useEffect(() => {
        setValueTime(value);
    });
    const onKeyDown = (e) => {
        e.preventDefault();
    };
    // const renderInput = (params) => {
    //     return (
    //         <TextField
    //             onKeyDown={onKeyDown}
    //             {...params}
    //             error={errorMessage ? true : false}
    //             InputProps={{
    //                 endAdornment: (
    //                     <IconButton>
    //                         <ClockIcon />
    //                     </IconButton>
    //                 ),
    //             }}
    //         />
    //     );
    // };
    return (
        <StyleAppTimeRangePicker>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
                <TimePicker
                    value={valueTime}
                    onChange={(value) => {
                        setValueTime(value), handleChange(value);
                    }}
                    // minTime={minTime}
                    renderInput={(params) => {
                        return (
                            <TextField
                                onKeyDown={onKeyDown}
                                {...params}
                                error={errorMessage ? true : false}
                            />
                        );
                    }}
                />
                {errorMessage && <span className="error">{errorMessage}</span>}
            </LocalizationProvider>
        </StyleAppTimeRangePicker>
    );
};
